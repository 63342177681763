import React, { useState, useEffect } from "react";
import {
  RadioGroup,
  styled,
  Radio,
  FormControl,
  FormControlLabel,
} from "@mui/material";
import { Nav } from "../components/Nav";
import imgSrc from "../image/bg.jpg";
import { useNavigate } from "react-router-dom";
import noplace from "../image/simulated place.jpg";
import noperson from "../image/no-image.jpg";
import axios from "axios";

export const Favorite = () => {
  let navigate = useNavigate();
  const [favDepartment, setFavDepartment] = useState([]);
  const [favPersonal, setFavPersonal] = useState([]);
  const baseURL = process.env.REACT_APP_URL;
  const user = JSON.parse(localStorage.getItem("user"));
  const [type, setType] = useState("department");
  const [data, setData] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    console.log(user);
    let isLogIn = JSON.parse(localStorage.getItem("user"));
    if (isLogIn == null) {
      navigate(`/`);
    } else {
      // axios
      //   .get(baseURL + "/personal/user/" + user.user_id, {
      //     headers: {
      //       Authorization: `bearer ${user.token}`,
      //     },
      //     //withCredentials: true,
      //   })
      //   .then((res1) => {
      axios
        .get(baseURL + "/favorite/" + user.user_id, {
          headers: {
            Authorization: `bearer ${user.token}`,
          },
          //withCredentials: true,
        })
        .then((res) => {
          //console.log(res.data)
          if (res.data.department_id) {
            let arr = [];
            res.data.department_id.map((o) => {
              axios
                .get(baseURL + "/department/" + o._id, {
                  headers: {
                    Authorization: `bearer ${user.token}`,
                  },
                  //withCredentials: true,
                })
                .then((res) => {
                  //console.log(res.data)
                  arr.push(res.data);
                  setFavDepartment(arr);
                });
            });
          }
          if (res.data.personal_id) {
            let arr = [];
            res.data.personal_id.map((o) => {
              axios
                .get(baseURL + "/personal/" + o._id, {
                  headers: {
                    Authorization: `bearer ${user.token}`,
                  },
                  //withCredentials: true,
                })
                .then((res) => {
                  //console.log(res.data)
                  arr.push(res.data);
                  setFavPersonal(arr);
                });
            });
          }
          // });
          //console.log(favDepartment)
          //console.log(favPersonal)
        });

      setIsLoading(false);
    }
  }, []);

  const truncateText = (text, length) => {
    if (text.length > length) {
      text = text.substr(0, length) + "...";
    }
    return text;
  };

  return (
    <>
      <BackGround>
        <Nav isLoggedIn={true} isStaff={false} />
        <Search>
          <h3>รายการโปรด</h3>
          <FormControl
            sx={{
              flexDirection: "row",
              "@media(max-width: 900px)": { flexDirection: "column" },
            }}
          >
            <RadioGroup
              defaultValue={type}
              onChange={(e) => {
                setType(e.target.value);
              }}
              row
              sx={{
                flexWrap: "wrap",
                "&.MuiFormGroup-row": {
                  alignSelf: "center",
                },
                "@media (max-width: 1250px)": {
                  justifyContent: "center",
                },
              }}
            >
              <FormControlLabel
                control={<Radio sx={{ ".css-hyxlzm": { color: "#e3e3e3" } }} />}
                value="department"
                label="หน่วยงาน"
              />
              <FormControlLabel
                control={<Radio sx={{ ".css-hyxlzm": { color: "#e3e3e3" } }} />}
                value="personal"
                size="large"
                label="บุคคลากร"
              />
            </RadioGroup>
          </FormControl>
        </Search>
        <Section>
          {isLoading == false &&
            type == "department" &&
            favDepartment.map((place) => (
              <Card onClick={() => navigate(`/${type}/detail/${place._id}`)}>
                <img
                  src={place.primary_picture ? place.primary_picture : noplace}
                  alt=""
                />
                <p className="name">{truncateText(place.name_th, 30)}</p>
                <p>เบอร์โทรศัพท์ : {place.primary_telephone}</p>
                <p>อีเมล์ : {place.primary_email}</p>
              </Card>
            ))}
          {isLoading == false &&
            type == "personal" &&
            favPersonal.map((place) => (
              <Card onClick={() => navigate(`/${type}/detail/${place._id}`)}>
                <img
                  src={place.profile_picture ? place.profile_picture : noperson}
                  alt=""
                />
                <p className="name">
                  {place.first_name_th} {place.last_name_th}
                </p>
                <p>เบอร์โทรศัพท์ : {place.primary_telephone}</p>
                <p>อีเมล์ : {place.primary_email}</p>
              </Card>
            ))}
        </Section>
      </BackGround>
    </>
  );
};

const Search = styled("div")`
  padding: 2rem 5rem;
  color: var(--white);
  display: flex;
  align-items: center;
  h3 {
    width: 250px;
  }
  @media (max-width: 1250px) {
    padding: 2rem 2rem;
  }
  @media (max-width: 800px) {
    flex-direction: column;
    h3 {
      text-align: center;
      width: 100%;
    }
  }
  @media (max-width: 600px) {
    padding: 2rem 1rem;
  }
`;

const Section = styled("div")`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 1rem;
  justify-items: center;
  row-gap: 3rem;
  column-gap: 1rem;
  @media (max-width: 1500px) {
    grid-template-columns: repeat(3, 1fr);
    padding: 3rem 1rem;
  }
  @media (max-width: 1120px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 750px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media (max-width: 380px) {
    grid-template-columns: repeat(1, 1fr);
    padding: 1rem 0rem;
  }
`;
const Card = styled("div")`
  min-height: 300px;
  width: 350px;
  border: 1px solid var(--white);
  border-radius: 5px;
  background-color: rgba(20, 20, 20, 0.8);
  color: var(--white);
  text-align: center;
  cursor: pointer;
  img {
    object-fit: fill;
    width: 100%;
    height: 350px;
  }
  p {
    font-size: 1rem;
  }
  p,
  h3 {
    margin-bottom: 1rem;
    padding: 0 0.5rem;
  }
  &:hover {
    background-color: var(--white);
    color: var(--black);
  }
  @media (max-width: 600px) {
    width: 335px;
    img {
      height: 335px;
    }
  }
  @media (max-width: 380px) {
    width: 280px;
    img {
      height: 280px;
    }
  }
`;

const BackGround = styled("div")`
  min-height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${imgSrc});
  background-repeat: no-repeat;
  background-size: cover;
`;
