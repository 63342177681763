import React, { useEffect } from "react";
import { Nav } from "../components/Nav";
import { styled, Button, TextField } from "@mui/material";
import imgSrc from "../image/bg.jpg";
import RadioGroup from "@mui/material/RadioGroup";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import { useState } from "react";
import axios from "axios";
import FileBase64 from "react-file-base64";
import { DepartmentCSV } from "../components/DepartmentCSV";
import Papa from "papaparse";
import noplace from "../image/simulated place.jpg";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { Navigate, useNavigate } from "react-router-dom";

const ConfirmDialog = (props) => {
  const { onClose, dialogValue, open, updateData } = props;
  const navigate = useNavigate();

  return (
    <Dialog open={open} fullWidth={true} maxWidth={"xs"}>
      <DialogTitle sx={{ textAlign: "center", fontSize: 24 }}>
        {dialogValue}
      </DialogTitle>
      <DialogContent>
        <DialogContentText></DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          onClick={() => {
            onClose();
            window.location.reload();
          }}
          sx={{ color: "#e3e3e3", fontSize: "1rem" }}
          variant="contained"
          color="b"
        >
          ยืนยัน
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const AddDepartment = () => {
  const [isPlace, setPlace] = useState(true);
  const [isUpload, setUpload] = useState(false);
  const [open, setOpen] = useState(false);
  const [dialogValue, setDialogValue] = useState("");
  const [csv, setCsv] = useState([]);
  const [existedCsv, setExistedCsv] = useState([]);
  const [newCsv, setNewCsv] = useState([]);
  const [responseData, setResponseData] = useState(null);
  let navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));

  const handleClose = () => {
    setOpen(false);
  };

  const [image, setImage] = useState(null);

  const handleFileUpload = (e) => {
    const files = e.target.files;
    let jsonData;
    if (files) {
      Papa.parse(files[0], {
        header: true,
        skipEmptyLines: true,
        complete: function(results) {
          jsonData = results.data;
          setCsv(jsonData);
        },
      });
    }
  };

  const csvCheck = () => {
    axios
      .post(baseURL + "/department/check", csv, {
        headers: {
          Authorization: `bearer ${user.token}`,
        },
        //withCredentials: true,
      })
      .then((res) => {
        //console.log(res);
        setResponseData(res.data.message);
        for (let i = 0; i < res.data.existed.length; i++) {
          existedCsv.push(res.data.existed[i]);
          setExistedCsv(existedCsv);
        }
        for (let i = 0; i < res.data.new.length; i++) {
          newCsv.push(res.data.new[i]);
          setNewCsv(res.data.new);
        }
        //console.log(existedCsv)
        //console.log(newCsv)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const csvUpload = () => {
    axios
      .post(
        baseURL + "/department/upload",
        {
          new: newCsv,
          existed: existedCsv,
        },
        {
          headers: {
            Authorization: `bearer ${user.token}`,
          },
          //withCredentials: true,
        }
      )
      .then((res) => {
        if (res.status == "200") {
          setOpen(true);
          setDialogValue("อัปโหลดสำเร็จ");
          setExistedCsv([]);
        } else {
          setOpen(true);
          setDialogValue("อัปโหลดไม่สำเร็จ โปรดลองอีกครั้ง");
          setExistedCsv([]);
        }
      })
      .catch((err) => console.log(err));
  };

  const uploadNew = () => {
    console.log(newCsv);
    axios
      .post(
        baseURL + "/department/upload",
        { new: newCsv, existed: [] },
        {
          headers: {
            Authorization: `bearer ${user.token}`,
          },
          //withCredentials: true,
        }
      )
      .then((res) => {
        if (res.status == "200") {
          setOpen(true);
          setDialogValue("อัปโหลดสำเร็จ");
          setExistedCsv([]);
        } else {
          setOpen(true);
          setDialogValue("อัปโหลดไม่สำเร็จ โปรดลองอีกครั้ง");
          setExistedCsv([]);
        }
      })
      .catch((err) => console.log(err));
  };

  const startState = {
    name_th: "",
    name_en: "",
    primary_telephone: "",
    secondary_telephone: "",
    primary_email: "",
    secondary_email: "",
    address: "",
    subdistrict: "",
    district: "",
    town: "",
    province: "",
    zipcode: "",
    url: "",
    fax: "",
    internal_contact_number: "",
    open_time: "",
    full_description: "",
    minister: "",
    primary_picture: "",
  };

  const [state, setState] = useState(startState);
  const [nameErr, setNameErr] = useState(false);
  const [telErr, setTelErr] = useState(false);

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const baseURL = process.env.REACT_APP_URL;

  const addData = (e) => {
    if (state.name_th == "" || state.primary_telephone == "") {
      if (state.name_th == "") {
        setNameErr(true);
      }
      if (state.primary_telephone == "") {
        setTelErr(true);
      }
      return;
    }
    e.preventDefault();
    axios
      .post(baseURL + "/department/new", state, {
        headers: {
          Authorization: `bearer ${user.token}`,
        },
        //withCredentials: true,
      })
      .then((res) => {
        if (res.status == "201") {
          setOpen(true);
          setDialogValue("อัปโหลดสำเร็จ");
        } else {
          setOpen(true);
          setDialogValue("อัปโหลดไม่สำเร็จ โปรดลองอีกครั้ง");
        }
      });
  };

  useEffect(() => {
    let isLogIn = JSON.parse(localStorage.getItem("user"));
    if (isLogIn == null) {
      navigate(`/`);
    } else if (isLogIn.role !== "admin") {
      navigate("/home");
    }
  }, []);

  return (
    <div>
      <BackGround>
        <Nav isStaff={true} isLoggedIn={true} />
        <Section>
          <h3>วิธีการเพิ่มข้อมูล</h3>
          <FormControl
            sx={{
              marginTop: 2,
              flexDirection: "row",
              ".MuiFormControlLabel-root": {
                margin: 0,
              },
            }}
          >
            <RadioGroup
              defaultValue="normal"
              row
              sx={{
                "&.MuiFormGroup-row": {
                  alignSelf: "center",
                  paddingBottom: ".5rem",
                },
              }}
            >
              <FormControlLabel
                onClick={() => setUpload(false)}
                sx={{ ".css-iiio54-MuiTypography-root": { fontSize: 24 } }}
                control={<Radio sx={{ ".css-hyxlzm": { color: "#e3e3e3" } }} />}
                value="normal"
                label="เพิ่มปกติ"
              />
              <FormControlLabel
                onClick={() => setUpload(true)}
                sx={{ ".css-iiio54-MuiTypography-root": { fontSize: 24 } }}
                control={<Radio sx={{ ".css-hyxlzm": { color: "#e3e3e3" } }} />}
                value="upload"
                label="อัปโหลด"
              />
            </RadioGroup>
          </FormControl>
          <AddSection>
            {isUpload ? (
              <>
                <UploadSection>
                  <DepartmentCSV />
                  <div>
                    <input
                      type="file"
                      accept=".csv"
                      onChange={handleFileUpload}
                    />
                    <Button
                      variant="contained"
                      color="w"
                      onClick={() => csvCheck()}
                    >
                      ตรวจสอบ
                    </Button>
                  </div>
                  {responseData ? (
                    <>
                      <p style={{ margin: "1rem" }}>
                        มีข้อมูลใหม่ {newCsv.length} ข้อมูล และมีข้อมูลเดิมอยู่{" "}
                        {existedCsv.length} ข้อมูล
                      </p>
                      <p className="ps">
                        *หากอัปโหลดทั้งหมด ข้อมูลที่ซ้ำจะอัปเดตข้อมูลเดิม
                      </p>
                      <ButtonWrapper>
                        {newCsv.length > 0 ? (
                          <Button
                            variant="contained"
                            color="b"
                            onClick={() => uploadNew()}
                          >
                            อัปโหลดเฉพาะข้อมูลใหม่
                          </Button>
                        ) : (
                          <></>
                        )}
                        <Button
                          variant="contained"
                          color="b"
                          onClick={() => {
                            csvUpload();
                          }}
                        >
                          อัปโหลดทั้งหมด
                        </Button>
                      </ButtonWrapper>
                      <ConfirmDialog
                        open={open}
                        dialogValue={dialogValue}
                        onClose={handleClose}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </UploadSection>
              </>
            ) : (
              <form onSubmit={addData}>
                <DataWrapper>
                  <PhotoUserWrapper>
                    <h3>รูปภาพ</h3>
                    {image == null ? (
                      <img src={noplace}></img>
                    ) : (
                      <img src={image}></img>
                    )}
                    <FileBase64
                      onChange={handleChange}
                      multiple={false}
                      onDone={({ base64 }) => {
                        setState({ ...state, primary_picture: base64 });
                        setImage(base64);
                      }}
                    />
                    <p style={{ fontSize: 14, marginTop: 8 }}>
                      ขนาดไฟล์ไม่เกิน 1 Mb
                    </p>
                  </PhotoUserWrapper>
                  <Column>
                    <h3>ข้อมูลหน่วยงาน</h3>
                    <RequiredCssTextField
                      label="ชื่อหน่วยงาน"
                      variant="filled"
                      onChange={handleChange}
                      color="w"
                      name="name_th"
                      value={state.name_th}
                      type="text"
                      error={nameErr}
                      helperText={nameErr ? "กรุณากรอกข้อมูล" : null}
                    />
                    <RequiredCssTextField
                      name="primary_telephone"
                      value={state.primary_telephone}
                      label="เบอร์โทรศัพท์ 1"
                      variant="filled"
                      onChange={handleChange}
                      color="w"
                      type="text"
                      error={telErr}
                      helperText={telErr ? "กรุณากรอกข้อมูล" : null}
                    />
                    <CssTextField
                      name="secondary_telephone"
                      value={state.secondary_telephone}
                      label="เบอร์โทรศัพท์ 2"
                      variant="filled"
                      onChange={handleChange}
                      color="w"
                      type="text"
                    />
                    <CssTextField
                      name="url"
                      value={state.url}
                      label="เว็บไซต์"
                      variant="filled"
                      onChange={handleChange}
                      color="w"
                      type="text"
                    />
                    <CssTextField
                      name="primary_email"
                      value={state.primary_email}
                      label="อีเมล 1"
                      variant="filled"
                      onChange={handleChange}
                      color="w"
                      type="text"
                    />
                    <CssTextField
                      name="secondary_email"
                      value={state.secondary_email}
                      label="อีเมล 2"
                      variant="filled"
                      onChange={handleChange}
                      color="w"
                      type="text"
                    />
                    <CssTextField
                      name="minister"
                      value={state.minister}
                      label="สังกัด"
                      variant="filled"
                      onChange={handleChange}
                      color="w"
                      type="text"
                    />
                    <CssTextField
                      name="internal_contact_number"
                      value={state.internal_contact_number}
                      label="เบอร์ติดต่อภายใน"
                      variant="filled"
                      onChange={handleChange}
                      color="w"
                      type="text"
                    />
                    <CssTextField
                      name="address"
                      value={state.address}
                      label="ที่อยู่"
                      variant="filled"
                      onChange={handleChange}
                      color="w"
                      type="text"
                    />
                    <CssTextField
                      name="subdistrict"
                      value={state.subdistrict}
                      label="ตำบล"
                      variant="filled"
                      onChange={handleChange}
                      color="w"
                      type="text"
                    />
                    <CssTextField
                      name="district"
                      value={state.district}
                      label="อำเภอ"
                      variant="filled"
                      onChange={handleChange}
                      color="w"
                      type="text"
                    />
                    <CssTextField
                      name="province"
                      value={state.province}
                      label="จังหวัด"
                      variant="filled"
                      onChange={handleChange}
                      color="w"
                      type="text"
                    />
                    <CssTextField
                      name="zipcode"
                      value={state.zipcode}
                      label="รหัสไปรษณีย์"
                      variant="filled"
                      onChange={handleChange}
                      color="w"
                      type="text"
                    />
                    <CssTextField
                      name="fax"
                      value={state.fax}
                      label="แฟกซ์"
                      variant="filled"
                      onChange={handleChange}
                      color="w"
                      type="text"
                    />
                    <CssTextField
                      name="open_time"
                      value={state.open_time}
                      label="วันเวลา เปิด-ปิด"
                      variant="filled"
                      onChange={handleChange}
                      color="w"
                      type="text"
                    />
                    <CssTextField
                      name="full_description"
                      value={state.full_description}
                      className="desc"
                      label="คำอธิบาย"
                      variant="filled"
                      onChange={handleChange}
                      color="w"
                      type="text"
                    />
                    <Button
                      variant="contained"
                      color="b"
                      type="submit"
                      sx={{
                        gridColumn: 2,
                        width: 270,
                        "@media (max-width: 620px)": {
                          width: "280px",
                          gridColumn: 1,
                        },
                        "@media (max-width: 375px)": {
                          width: "280px",
                        },
                      }}
                    >
                      ยืนยัน
                    </Button>
                  </Column>
                  <ConfirmDialog
                    open={open}
                    dialogValue={dialogValue}
                    onClose={handleClose}
                  />
                </DataWrapper>
              </form>
            )}
          </AddSection>
        </Section>
      </BackGround>
    </div>
  );
};

const Column = styled("div")({
  gridColumn: "2/4",
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  rowGap: "1.5rem",
  columnGap: "1rem",
  alignItems: "center",
  justifyItems: "center",
  h3: {
    gridColumn: "1/3",
    textAlign: "center",
  },
  "@media (max-width: 1000px)": {
    gridColumn: "1",
  },
  "@media (max-width: 620px)": {
    gridTemplateColumns: "repeat(1,1fr)",
    h3: {
      gridColumn: "1",
    },
  },
});

const ButtonWrapper = styled("div")({
  display: "flex",
  columnGap: "1rem",
  marginTop: "1rem",
});

const UploadSection = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  a: {
    color: "var(--white)",
  },
  form: {
    marginTop: "2rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    rowGap: "2rem",
    Button: {
      width: "10rem",
      fontSize: "1rem",
    },
  },
});

const PhotoUserWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  marginBottom: "2rem",
  img: {
    height: "300px",
    width: "300px",
    objectFit: "cover",
    marginBottom: "1.5rem",
    marginTop: "1.5rem",
  },
  ".pre-person-img": {
    height: "200px",
    width: "200px",
    marginBottom: "1.5rem",
    borderRadius: "50%",
    backgroundColor: "lightGray",
  },
  ".user": {
    marginBottom: "1rem",
  },
  "@media (max-width: 600px)": {
    h2: {
      fontSize: "1.5rem",
    },
  },
  "@media (max-width: 375px)": {
    img: {
      width: "280px",
      height: "280px",
    },
    input: {
      width: "280px",
    },
  },
});

const DataWrapper = styled("div")({
  display: "grid",
  gridTemplateColumns: "repeat(3,1fr)",
  columnGap: "1rem",
  justifyContent: "center",
  ".edit": {
    fontSize: 15,
  },
  "@media (max-width: 1000px)": {
    gridTemplateColumns: "repeat(1,1fr)",
    ".department": {
      gridColumn: "2",
    },
  },
  "@media (max-width: 768px)": {
    gridTemplateColumns: "repeat(1,1fr)",
    ".department": {
      gridColumn: "1",
    },
  },
});

const Input = styled("input")({
  display: "none",
});
const RequiredCssTextField = styled(TextField)({
  ".MuiFilledInput-root": {
    backgroundColor: "#d5d5d5",
    transitionDuration: "0ms",
    borderTopRightRadius: 5,
  },
  ".MuiFilledInput-root.Mui-focused": {
    backgroundColor: "#d5d5d5",
  },
  ".MuiInputLabel-root": {
    color: "#203b9b",
  },
  "label.Mui-focused": {
    color: "#203b9b",
  },
  ".MuiFilledInput-underline:after": {
    borderBottom: "3px solid #203b9b",
  },
  ".MuiFilledInput-underline:before": {
    borderBottomColor: "#202020",
  },
  ".MuiFilledInput-root:hover": {
    backgroundColor: "#e3e3e3",
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
  },
  "p.MuiFormHelperText-root": {
    background: "transparent",
    fontSize: "15px",
    color: "#e3e3e3",
  },
  width: 300,
  "@media (max-width: 1370px)": {
    width: 270,
  },
  "@media (max-width: 375px)": {
    width: "270px",
  },
});
const CssTextField = styled(TextField)({
  ".MuiFilledInput-root": {
    backgroundColor: "#d5d5d5",
    transitionDuration: "0ms",
    borderTopRightRadius: 5,
  },
  ".MuiFilledInput-root.Mui-focused": {
    backgroundColor: "#d5d5d5",
  },
  ".MuiInputLabel-root": {
    color: "  #404040",
  },
  "label.Mui-focused": {
    color: "#303030",
  },
  ".MuiFilledInput-underline:after": {
    borderBottom: "3px solid #203b9b",
  },
  ".MuiFilledInput-underline:before": {
    borderBottomColor: "#202020",
  },
  ".MuiFilledInput-root:hover": {
    backgroundColor: "#e3e3e3",
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
  },
  "p.MuiFormHelperText-root": {
    background: "transparent",
    fontSize: "15px",
    color: "#e3e3e3",
  },
  width: 300,
  "@media (max-width: 1370px)": {
    width: 270,
  },
  "@media (max-width: 375px)": {
    width: "270px",
  },
});

const AddSection = styled("div")({
  width: "100%",
});

const Section = styled("div")({
  display: "flex",
  padding: "2rem 3rem",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",

  "@media (max-width: 600px)": {
    padding: "2rem 1rem",
    h1: {
      fontSize: 32,
    },
    h3: {
      fontSize: 24,
    },
  },
});

const BackGround = styled("div")`
  min-height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${imgSrc});
  background-repeat: no-repeat;
  background-size: cover;
  color: var(--white);
`;
