import place from "./image/place.jpg";
import person from "./image/person.jpg";

export const examplePlaceData = [
  {
    id: 1,
    image: place,
    name: "กรมการปกครอง",
    email: "example@email.com",
    tel: "0-22201-0151-8",
    fax: "02-2262810",
    internalTel: "1567",
    address: "กรมการปกครอง ถนนอัษฎางค์ เขตวัดราชบพิธ แขวงพระนคร กทม. 10200",
    day: "จันทร์ - ศุกร์",
    time: "8:00 - 16:00",
    desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Unde in sequi sit vel consequatur assumenda earum harum praesentium iusto voluptatibus aspernatur eligendi alias nostrum reiciendis, illum numquam autem nulla voluptatem eum repellat temporibus. Ipsam culpa exercitationem magnam sunt fuga tenetur a perspiciatis possimus rerum laudantium repellendus, est quaerat sit qui doloremque sapiente natus pariatur reiciendis vero impedit aperiam magni minima.",
  },
  {
    id: 2,
    image: place,
    name: "กรมที่ดิน",
    email: "example@email.com",
    tel: "0-22201-0151-8",
    fax: "02-2262810",
    internalTel: "1567",
    address: "กรมการปกครอง ถนนอัษฎางค์ เขตวัดราชบพิธ แขวงพระนคร กทม. 10200",
    day: "จันทร์ - ศุกร์",
    time: "8:00 - 16:00",
    desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Unde in sequi sit vel consequatur assumenda earum harum praesentium iusto voluptatibus aspernatur eligendi alias nostrum reiciendis, illum numquam autem nulla voluptatem eum repellat temporibus. Ipsam culpa exercitationem magnam sunt fuga tenetur a perspiciatis possimus rerum laudantium repellendus, est quaerat sit qui doloremque sapiente natus pariatur reiciendis vero impedit aperiam magni minima.",
  },
  {
    id: 3,
    image: place,
    name: "กรมการขนส่ง",
    email: "example@email.com",
    tel: "0-22201-0151-8",
    fax: "02-2262810",
    internalTel: "1567",
    address: "กรมการปกครอง ถนนอัษฎางค์ เขตวัดราชบพิธ แขวงพระนคร กทม. 10200",
    day: "จันทร์ - ศุกร์",
    time: "8:00 - 16:00",
    desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Unde in sequi sit vel consequatur assumenda earum harum praesentium iusto voluptatibus aspernatur eligendi alias nostrum reiciendis, illum numquam autem nulla voluptatem eum repellat temporibus. Ipsam culpa exercitationem magnam sunt fuga tenetur a perspiciatis possimus rerum laudantium repellendus, est quaerat sit qui doloremque sapiente natus pariatur reiciendis vero impedit aperiam magni minima.",
  },
  {
    id: 4,
    image: place,
    name: "กรมสรรพากร",
    email: "example@email.com",
    tel: "0-22201-0151-8",
    fax: "02-2262810",
    internalTel: "1567",
    address: "กรมการปกครอง ถนนอัษฎางค์ เขตวัดราชบพิธ แขวงพระนคร กทม. 10200",
    day: "จันทร์ - ศุกร์",
    time: "8:00 - 16:00",
    desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Unde in sequi sit vel consequatur assumenda earum harum praesentium iusto voluptatibus aspernatur eligendi alias nostrum reiciendis, illum numquam autem nulla voluptatem eum repellat temporibus. Ipsam culpa exercitationem magnam sunt fuga tenetur a perspiciatis possimus rerum laudantium repellendus, est quaerat sit qui doloremque sapiente natus pariatur reiciendis vero impedit aperiam magni minima.",
  },
  {
    id: 5,
    image: place,
    name: "กรมอุตุนิยมวิทยา",
    email: "example@email.com",
    tel: "0-22201-0151-8",
    fax: "02-2262810",
    internalTel: "1567",
    address: "กรมการปกครอง ถนนอัษฎางค์ เขตวัดราชบพิธ แขวงพระนคร กทม. 10200",
    day: "จันทร์ - ศุกร์",
    time: "8:00 - 16:00",
    desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Unde in sequi sit vel consequatur assumenda earum harum praesentium iusto voluptatibus aspernatur eligendi alias nostrum reiciendis, illum numquam autem nulla voluptatem eum repellat temporibus. Ipsam culpa exercitationem magnam sunt fuga tenetur a perspiciatis possimus rerum laudantium repellendus, est quaerat sit qui doloremque sapiente natus pariatur reiciendis vero impedit aperiam magni minima.",
  },
  {
    id: 6,
    image: place,
    name: "กรมการค้าต่างประเทศ",
    email: "example@email.com",
    tel: "0-22201-0151-8",
    fax: "02-2262810",
    internalTel: "1567",
    address: "กรมการปกครอง ถนนอัษฎางค์ เขตวัดราชบพิธ แขวงพระนคร กทม. 10200",
    day: "จันทร์ - ศุกร์",
    time: "8:00 - 16:00",
    desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Unde in sequi sit vel consequatur assumenda earum harum praesentium iusto voluptatibus aspernatur eligendi alias nostrum reiciendis, illum numquam autem nulla voluptatem eum repellat temporibus. Ipsam culpa exercitationem magnam sunt fuga tenetur a perspiciatis possimus rerum laudantium repellendus, est quaerat sit qui doloremque sapiente natus pariatur reiciendis vero impedit aperiam magni minima.",
  },
];

export const exampleFavoriteData = [
  {
    id: 1,
    image: place,
    name: "กรมการปกครอง",
    email: "example@email.com",
    tel: "0-22201-0151-8",
    fax: "02-2262810",
    internalTel: "1567",
    address: "กรมการปกครอง ถนนอัษฎางค์ เขตวัดราชบพิธ แขวงพระนคร กทม. 10200",
    day: "จันทร์ - ศุกร์",
    time: "8:00 - 16:00",
    desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Unde in sequi sit vel consequatur assumenda earum harum praesentium iusto voluptatibus aspernatur eligendi alias nostrum reiciendis, illum numquam autem nulla voluptatem eum repellat temporibus. Ipsam culpa exercitationem magnam sunt fuga tenetur a perspiciatis possimus rerum laudantium repellendus, est quaerat sit qui doloremque sapiente natus pariatur reiciendis vero impedit aperiam magni minima.",
  },
  {
    id: 2,
    image: { place },
    name: "กรมที่ดิน",
    email: "example@email.com",
    tel: "0-22201-0151-8",
    fax: "02-2262810",
    internalTel: "1567",
    address: "กรมการปกครอง ถนนอัษฎางค์ เขตวัดราชบพิธ แขวงพระนคร กทม. 10200",
    day: "จันทร์ - ศุกร์",
    time: "8:00 - 16:00",
    desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Unde in sequi sit vel consequatur assumenda earum harum praesentium iusto voluptatibus aspernatur eligendi alias nostrum reiciendis, illum numquam autem nulla voluptatem eum repellat temporibus. Ipsam culpa exercitationem magnam sunt fuga tenetur a perspiciatis possimus rerum laudantium repellendus, est quaerat sit qui doloremque sapiente natus pariatur reiciendis vero impedit aperiam magni minima.",
  },
  {
    id: 3,
    image: { place },
    name: "กรมการขนส่ง",
    email: "example@email.com",
    tel: "0-22201-0151-8",
    fax: "02-2262810",
    internalTel: "1567",
    address: "กรมการปกครอง ถนนอัษฎางค์ เขตวัดราชบพิธ แขวงพระนคร กทม. 10200",
    day: "จันทร์ - ศุกร์",
    time: "8:00 - 16:00",
    desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Unde in sequi sit vel consequatur assumenda earum harum praesentium iusto voluptatibus aspernatur eligendi alias nostrum reiciendis, illum numquam autem nulla voluptatem eum repellat temporibus. Ipsam culpa exercitationem magnam sunt fuga tenetur a perspiciatis possimus rerum laudantium repellendus, est quaerat sit qui doloremque sapiente natus pariatur reiciendis vero impedit aperiam magni minima.",
  },
];

export const examplePersonData = [
  {
    id: 1,
    image: person,
    firstNameTh: "สมชาย",
    lastNameTh: "ทองดี",
    firstNameEn: "Somchai",
    lastNameEn: "Thongdee",
    gender: 0,
    agency: "กรมการปกครอง",
    positon: "ผู้บริหาร",
    level: "ระดับสูง",
    email_1: "example1@email.com",
    email_2: "example2@email.com",
    tel_1: "01-234-5678",
    tel_2: "09-876-5432",
    internalTel: "1567",
  },
];
