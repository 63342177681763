import React, { useState, useEffect } from "react";
import { Nav } from "../components/Nav";
import { styled, Button, TextField, Checkbox, FormGroup } from "@mui/material";
import imgSrc from "../image/bg.jpg";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import noImage from "../image/no-image.jpg";
import Select from "@mui/material/Select";
import axios from "axios";
import FileBase64 from "react-file-base64";
import Papa from "papaparse";
import { PersonalCSV } from "../components/PersonalCSV";
import { Link, useNavigate, useParams } from "react-router-dom";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

const ConfirmDialog = (props) => {
  const { onClose, dialogValue, open, updateData } = props;
  const navigate = useNavigate();

  return (
    <Dialog open={open} fullWidth={true} maxWidth={"xs"}>
      <DialogTitle sx={{ textAlign: "center", fontSize: 24 }}>
        {dialogValue}
      </DialogTitle>
      <DialogContent>
        <DialogContentText></DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          onClick={() => {
            window.location.reload();
            onClose();
          }}
          sx={{ color: "#e3e3e3", fontSize: "1rem", width: "70%" }}
          variant="contained"
          color="b"
        >
          ต่อไป
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const EditPersonalData = (props) => {
  const selfEdit = props.selfEdit;
  let navigate = useNavigate();
  const [g, setG] = useState("Male");
  const baseURL = process.env.REACT_APP_URL;
  const [image, setImage] = useState(null);
  let { id } = useParams();
  const [open, setOpen] = useState(false);
  const [dialogValue, setDialogValue] = useState("");
  const [consent, setConsent] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));

  const handleClose = () => {
    setOpen(false);
  };
  const [isFN, setIsFN] = useState(false);
  const [isLN, setIsLN] = useState(false);
  const [isDep, setIsDep] = useState(false);
  const startState = {
    prefix_th: "",
    prefix_en: "",
    first_name_th: "",
    last_name_th: "",
    first_name_en: "",
    last_name_en: "",
    gender: "",
    department: "",
    minister: "",
    position: "",
    level: "",
    internal_contact_number: "",
    primary_telephone: "",
    secondary_telephone: "",
    primary_email: "",
    secondary_email: "",
    citizen_id: "",
    address: "",
    status: "",
    district: "",
    province: "",
    NDA_status: false,
    complain_status: false,
    profile_picture: noImage,
    updated_at: null,
    updated_by: null,
  };
  const [userName, setUserName] = useState(null);
  const [person, setPerson] = useState(startState);

  const handlePerson = (e) => {
    setPerson({
      ...person,
      [e.target.name]: e.target.value,
    });
  };

  const updatePerson = (e) => {
    if (person.first_name_th == "") {
      setIsFN(true);
    }
    if (person.last_name_th == "") {
      setIsLN(true);
    }
    if (person.department == "") {
      setIsDep(true);
    }
    if (
      person.first_name_th == "" ||
      person.last_name_th == "" ||
      person.department == ""
    ) {
      return;
    }
    e.preventDefault();
    axios
      .patch(
        baseURL + "/personal/" + id,
        { ...person, updated_by: userName },
        {
          headers: {
            Authorization: `bearer ${user.token}`,
          },
          //withCredentials: true,
        }
      )
      .then((res) => {
        if ((res.status = "200")) {
          setOpen(true);
          setDialogValue("แก้ไขสำเร็จ");
        } else {
          setOpen(true);
          setDialogValue("แก้ไขไม่สำเร็จ โปรดลองอีกครั้ง");
        }
      });
  };

  const handleCheck = (e) => {
    setPerson({ ...person, [e.target.name]: e.target.checked });
    //console.log(e.target.checked);
  };

  useEffect(() => {
    let isLogIn = JSON.parse(localStorage.getItem("user"));
    if (isLogIn == null) {
      navigate(`/`);
    } else if (isLogIn.role !== "admin") {
      navigate("/home");
    } else {
      axios
        .get(baseURL + "/personal/user/" + user.user_id, {
          headers: {
            Authorization: `bearer ${user.token}`,
          },
          //withCredentials: true,
        })
        .then((res) => {
          //console.log(res.data.first_name_th + " " + res.data.last_name_th);
          setUserName(res.data.first_name_th + " " + res.data.last_name_th);
          axios
            .get(baseURL + "/personal/" + id, {
              headers: {
                Authorization: `bearer ${user.token}`,
              },
              //withCredentials: true,
            })
            .then((res) => {
              if (res.data.profile_picture) {
                setImage(res.data.profile_picture);
              } else {
                setImage(noImage);
              }
              //console.log(res.data)
              setPerson(res.data);
              setG(res.data.gender);
            });
        });
    }
  }, []);

  return (
    <BackGround>
      <Nav isStaff={true} is4 LoggedIn={true} />
      <Section>
        <AddSection>
          <form onSubmit={updatePerson}>
            <DataWrapper>
              <PhotoUserWrapper>
                <h3 className="user">รูปภาพ</h3>
                {image === null ? (
                  <img src={noImage}></img>
                ) : (
                  <img src={image}></img>
                )}
                <FileBase64
                  onChange={handlePerson}
                  multiple={false}
                  onDone={({ base64 }) => {
                    setPerson({ ...person, profile_picture: base64 });
                    setImage(base64);
                  }}
                />
              </PhotoUserWrapper>
              <Column>
                <h3>ข้อมูลพื้นฐาน</h3>
                <CssTextField
                  name="prefix_th"
                  value={person.prefix_th}
                  onChange={handlePerson}
                  label="คำนำหน้า"
                  variant="filled"
                  color="w"
                  type="text"
                />
                <RequiredCssTextField
                  name="first_name_th"
                  value={person.first_name_th}
                  onChange={handlePerson}
                  error={isFN}
                  helperText={isFN ? "กรุณากรอกข้อมูล" : null}
                  label="ชื่อ"
                  variant="filled"
                  color="w"
                  type="text"
                />
                <RequiredCssTextField
                  name="last_name_th"
                  value={person.last_name_th}
                  onChange={handlePerson}
                  label="นามสกุล"
                  variant="filled"
                  error={isLN}
                  helperText={isLN ? "กรุณากรอกข้อมูล" : null}
                  color="w"
                  type="text"
                />
                {/* <CssFormControl
                  sx={{ marginBottom: 0 }}
                  variant="filled"
                  fullWidth
                >
                  <InputLabel id="demo-simple-select-label">เพศ</InputLabel>
                  <CssSelect
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={g}
                    label="เพศ"
                    onChange={(e) => {
                      setG(e.target.value);
                      setPerson({ ...person, gender: e.target.value });
                      console.log(person);
                    }}
                  >
                    <MenuItem value="Male">ชาย</MenuItem>
                    <MenuItem value="Female">หญิง</MenuItem>
                  </CssSelect>
                </CssFormControl> */}
                <RequiredCssTextField
                  name="primary_telephone"
                  value={person.primary_telephone}
                  // onChange={handlePerson}
                  label="เบอร์โทรศัพท์ 1"
                  variant="filled"
                  color="w"
                  type="text"
                />
                <CssTextField
                  name="secondary_telephone"
                  value={person.secondary_telephone}
                  onChange={handlePerson}
                  label="เบอร์โทรศัพท์ 2"
                  variant="filled"
                  color="w"
                  type="text"
                />
                <CssTextField
                  name="primary_email"
                  value={person.primary_email}
                  onChange={handlePerson}
                  label="อีเมล 1"
                  variant="filled"
                  color="w"
                  type="email"
                />
                <CssTextField
                  name="secondary_email"
                  value={person.secondary_email}
                  onChange={handlePerson}
                  label="อีเมล 2"
                  variant="filled"
                  color="w"
                  type="email"
                />
                <div></div>
              </Column>
              <Column className="department">
                <h3 className="department">ข้อมูลหน่วยงาน</h3>

                <RequiredCssTextField
                  name="department"
                  value={person.department}
                  onChange={handlePerson}
                  className="department-data"
                  label="สำนัก/กอง/จังหวัด"
                  error={isDep}
                  helperText={isDep ? "กรุณากรอกข้อมูล" : null}
                  variant="filled"
                  color="w"
                  type="text"
                />
                <CssTextField
                  className="department-data"
                  name="minister"
                  value={person.minister}
                  onChange={handlePerson}
                  label="กลุ่มงาน/ฝ่าย/อำเภอ"
                  variant="filled"
                  color="w"
                  type="text"
                />
                <CssTextField
                  className="department-data"
                  name="position"
                  value={person.position}
                  onChange={handlePerson}
                  label="ตำแหน่ง"
                  variant="filled"
                  color="w"
                  type="text"
                />
                <CssTextField
                  className="department-data"
                  name="level"
                  value={person.level}
                  onChange={handlePerson}
                  label="ระดับ"
                  variant="filled"
                  color="w"
                  type="text"
                />
                <CssTextField
                  name="internal_contact_number"
                  value={person.internal_contact_number}
                  onChange={handlePerson}
                  className="department-data"
                  label="เบอร์ติดต่อภายใน"
                  variant="filled"
                  color="w"
                  type="text"
                />
                <FormControlLabel
                  sx={{
                    ".MuiFormControlLabel-label": { fontSize: 16 },
                  }}
                  control={
                    <Checkbox
                      sx={{
                        color: "#e3e3e3",
                      }}
                      color="w"
                      checked={person.NDA_status}
                      onChange={(e) => {
                        //console.log(person.NDA_status)
                        setConsent(!person.NDA_status);
                        setPerson({ ...person, NDA_status: e.target.checked });
                      }}
                    />
                  }
                  label="ยอมรับเงื่อนไขและข้อตกลง"
                />
                <FormControlLabel
                  sx={{
                    ".MuiFormControlLabel-label": {
                      fontSize: 16,
                      width: "100%",
                    },
                  }}
                  control={
                    <Checkbox
                      sx={{
                        color: "#e3e3e3",
                      }}
                      color="w"
                      checked={person.complain_status}
                      onChange={(e) => {
                        //console.log(person.complain_status)
                        setConsent(!person.complain_status);
                        setPerson({
                          ...person,
                          complain_status: e.target.checked,
                        });
                      }}
                    />
                  }
                  label="แนะนำติชม"
                />
                <div>
                  <p className="edit">
                    วันที่แก้ไขล่าสุด :{" "}
                    {new Date(person.updated_at).toLocaleString("th-TH", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      second: "numeric",
                      timeZone: "GMT",
                    })}
                  </p>
                  <p className="edit">ผู้แก้ไข : {person.updated_by} </p>
                </div>
                <Button
                  sx={{
                    width: 300,
                    "@media (max-width: 375px)": {
                      width: "280px",
                    },
                  }}
                  variant="contained"
                  color="b"
                  type="submit"
                >
                  ยืนยัน
                </Button>
              </Column>
            </DataWrapper>

            <ConfirmDialog
              open={open}
              dialogValue={dialogValue}
              onClose={handleClose}
            />
          </form>
        </AddSection>
      </Section>
    </BackGround>
  );
};

const Column = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "1.5rem",
  alignItems: "center",
  justifyContent: "flex-start",
});

const PhotoUserWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  marginBottom: "2rem",
  img: {
    height: "300px",
    width: "300px",
    objectFit: "cover",
    marginBottom: "1.5rem",
  },
  ".pre-person-img": {
    height: "200px",
    width: "200px",
    marginBottom: "1.5rem",
    borderRadius: "50%",
    backgroundColor: "lightGray",
  },
  ".user": {
    marginBottom: "1rem",
  },
  "@media (max-width: 600px)": {
    h2: {
      fontSize: "1.5rem",
    },
  },
  "@media (max-width: 375px)": {
    img: {
      width: "280px",
      height: "280px",
    },
    input: {
      width: "280px",
    },
  },
});

const CssFormControl = styled(FormControl)({
  "label.Mui-focused": {
    color: "#203e9e",
  },
  width: "100%",
  marginBottom: "1rem",
  ".MuiInputLabel-root": {
    color: "#203e9e",
  },
  ".MuiFilledInput-root": {
    backgroundColor: "#d5d5d5",
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    width: 300,
  },
  ".MuiFilledInput-root.Mui-focused": {
    backgroundColor: "#d5d5d5",
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  ".MuiFilledInput-underline:after": {
    borderBottom: "3px solid #203b9b",
  },
  "&.MuiFormControl-root": {
    display: "flex",
    alignItems: "center",
    width: "300px",
  },
  "@media (max-width: 375px)": {
    ".MuiFilledInput-root": {
      backgroundColor: "#d5d5d5",
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      width: 280,
    },
    ".MuiInputLabel-root": {
      marginLeft: "10px",
    },
  },
});

const CssSelect = styled(Select)({
  ".MuiSelect-select": {
    backgroundColor: "#d5d5d5",
    color: "#202020",
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  ".MuiSelect-filled": {
    backgroundColor: "#d5d5d5",
  },
});
// const CssSelect = styled(Select)({
//   ".MuiSelect-select": {
//     color: "#e3e3e3",
//     borderColor: "#e3e3e3",
//   },
//   ".MuifilledInput-notchedOutline": {
//     borderColor: "#e3e3e3",
//     "&:hover fieldset": {
//       borderColor: "#e3e3e3",
//     },
//   },
//   ".MuiSvgIcon-root": {
//     color: "#e3e3e3",
//   },
// });

const DataWrapper = styled("div")({
  display: "grid",
  gridTemplateColumns: "repeat(3,1fr)",
  columnGap: "1rem",
  justifyContent: "center",
  ".edit": {
    fontSize: 15,
  },
  "@media (max-width: 1000px)": {
    gridTemplateColumns: "repeat(2,1fr)",
    ".department": {
      gridColumn: "2",
    },
  },
  "@media (max-width: 768px)": {
    gridTemplateColumns: "repeat(1,1fr)",
    ".department": {
      gridColumn: "1",
    },
  },
});

const Input = styled("input")({
  display: "none",
});
const RequiredCssTextField = styled(TextField)({
  ".MuiFilledInput-root": {
    backgroundColor: "#d5d5d5",
    transitionDuration: "0ms",
    borderTopRightRadius: 5,
  },
  ".MuiFilledInput-root.Mui-focused": {
    backgroundColor: "#d5d5d5",
  },
  ".MuiInputLabel-root": {
    color: "#203b9b",
  },
  "label.Mui-focused": {
    color: "#203b9b",
  },
  ".MuiFilledInput-underline:after": {
    borderBottom: "3px solid #203b9b",
  },
  ".MuiFilledInput-underline:before": {
    borderBottomColor: "#202020",
  },
  ".MuiFilledInput-root:hover": {
    backgroundColor: "#e3e3e3",
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
  },
  "p.MuiFormHelperText-root": {
    background: "transparent",
    fontSize: "15px",
    color: "#e3e3e3",
  },
  width: 300,
  "@media (max-width: 1370px)": {
    width: 270,
  },
  "@media (max-width: 375px)": {
    width: "270px",
  },
});
const CssTextField = styled(TextField)({
  ".MuiFilledInput-root": {
    backgroundColor: "#d5d5d5",
    transitionDuration: "0ms",
    borderTopRightRadius: 5,
  },
  ".MuiFilledInput-root.Mui-focused": {
    backgroundColor: "#d5d5d5",
  },
  ".MuiInputLabel-root": {
    color: "  #404040",
  },
  "label.Mui-focused": {
    color: "#303030",
  },
  ".MuiFilledInput-underline:after": {
    borderBottom: "3px solid #203b9b",
  },
  ".MuiFilledInput-underline:before": {
    borderBottomColor: "#202020",
  },
  ".MuiFilledInput-root:hover": {
    backgroundColor: "#e3e3e3",
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
  },
  "p.MuiFormHelperText-root": {
    background: "transparent",
    fontSize: "15px",
    color: "#e3e3e3",
  },
  width: 300,
  "@media (max-width: 1370px)": {
    width: 270,
  },
  "@media (max-width: 375px)": {
    width: "270px",
  },
});
// const CssTextField = styled(TextField)({
//   ".MuiFilledInput-root":{
//     backgroundColor: '#d5d5d5',
//     transitionDuration: '0ms',
//     borderTopRightRadius: 5
//   },
//   ".MuiFilledInput-root.Mui-focused":{
//     backgroundColor: '#d5d5d5',
//   },
//   ".MuiInputLabel-root":{
//     color: '#203b9b'
//   },
//   "label.Mui-focused":{
//     color: '#203b9b',
//   },
//   ".MuiFilledInput-underline:after":{
//     borderBottom: '3px solid #203b9b'
//   },
//   ".MuiFilledInput-underline:before":{
//     borderBottomColor: '#202020',
//   },
//   '.MuiFilledInput-root:hover':{
//     backgroundColor: '#e3e3e3',
//     borderTopRightRadius: 5,
//     borderTopLeftRadius: 5
//   },
//   'p.MuiFormHelperText-root':{
//     background: "transparent",
//     fontSize: '15px'
//   },
// });

const AddSection = styled("div")({
  width: "100%",
  ".desc": {
    alignSelf: "end",
  },
});

const Section = styled("div")({
  display: "flex",
  padding: "2rem 3rem",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  "@media (max-width: 1440px)": {
    padding: "2rem 1rem",
  },
  "@media (max-width: 600px)": {
    padding: "2rem 1rem",
    h1: {
      fontSize: 32,
    },
    h3: {
      fontSize: 24,
    },
  },
});

const BackGround = styled("div")`
  min-height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${imgSrc});
  background-repeat: no-repeat;
  background-size: cover;
  color: var(--white);
`;
