import React, { useState, useEffect } from "react";
import { Nav } from "../components/Nav";
import { styled, Button, TextField } from "@mui/material";
import imgSrc from "../image/bg.jpg";
import RadioGroup from "@mui/material/RadioGroup";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import placeImg from "../image/place.jpg";
import personImg from "../image/person.jpg";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import axios from "axios";
import FileBase64 from "react-file-base64";
import Papa from "papaparse";
import { PersonalCSV } from "../components/PersonalCSV";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { Navigate, useNavigate } from "react-router-dom";
import noperson from "../image/no-image.jpg";
import { SettingsInputSvideoSharp } from "@mui/icons-material";

const ConfirmDialog = (props) => {
  const { onClose, dialogValue, open, updateData } = props;
  const navigate = useNavigate();

  return (
    <Dialog open={open} fullWidth={true} maxWidth={"xs"}>
      <DialogTitle sx={{ textAlign: "center", fontSize: 24 }}>
        {dialogValue}
      </DialogTitle>
      <DialogContent>
        <DialogContentText></DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          onClick={() => {
            onClose();
            window.location.reload();
          }}
          sx={{ color: "#e3e3e3", fontSize: "1rem" }}
          variant="contained"
          color="b"
        >
          ยืนยัน
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const AddPerson = () => {
  const [isUpload, setUpload] = useState(false);
  const [g, setG] = useState("Male");
  // const baseURL = 'process.env.REACT_APP_URL';
  const baseURL = process.env.REACT_APP_URL;
  const [image, setImage] = useState(null);
  const [csv, setCsv] = useState([]);
  const [existedCsv, setExistedCsv] = useState([]);
  const [newCsv, setNewCsv] = useState([]);
  const [responseData, setResponseData] = useState(null);
  const [open, setOpen] = useState(false);
  const [dialogValue, setDialogValue] = useState("");
  let navigate = useNavigate();
  const [isFN, setIsFN] = useState(false);
  const [isLN, setIsLN] = useState(false);
  const [isDep, setIsDep] = useState(false);
  const [isTel1, setIsTel1] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));

  const handleClose = () => {
    setOpen(false);
  };

  const handleFileUpload = (e) => {
    const files = e.target.files;
    let jsonData = [];
    if (files) {
      Papa.parse(files[0], {
        header: true,
        skipEmptyLines: true,
        complete: function(results) {
          jsonData = results.data;
          setCsv(jsonData);
        },
      });
    }
  };

  const csvCheck = () => {
    axios
      .post(baseURL + "/personal/check", csv, {
        headers: {
          Authorization: `bearer ${user.token}`,
        },
        //withCredentials: true,
      })
      .then((res) => {
        //console.log(res);
        setResponseData(res.data.message);
        for (let i = 0; i < res.data.existed.length; i++) {
          existedCsv.push(res.data.existed[i]);
          setExistedCsv(existedCsv);
        }
        for (let i = 0; i < res.data.new.length; i++) {
          newCsv.push(res.data.new[i]);
          setNewCsv(res.data.new);
        }
        //console.log(existedCsv);
        //console.log(newCsv);
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  const csvUpload = () => {
    axios
      .post(
        baseURL + "/personal/upload",
        { new: newCsv, existed: existedCsv },
        {
          headers: {
            Authorization: `bearer ${user.token}`,
          },
          //withCredentials: true,
        }
      )
      .then((res) => {
        if ((res.status = "200")) {
          setOpen(true);
          setDialogValue("อัปโหลดสำเร็จ");
          setExistedCsv([]);
        } else {
          setOpen(true);
          setDialogValue("อัปโหลดไม่สำเร็จ โปรดลองอีกครั้ง");
          setExistedCsv([]);
        }
      })
      .catch((err) => console.log(err));
  };

  const uploadNew = () => {
    axios
      .post(
        baseURL + "/personal/upload",
        { new: newCsv, existed: [] },
        {
          headers: {
            Authorization: `bearer ${user.token}`,
          },
          //withCredentials: true,
        }
      )
      .then((res) => {
        if ((res.status = "200")) {
          setOpen(true);
          setDialogValue("อัปโหลดสำเร็จ");
          setExistedCsv([]);
        } else {
          setOpen(true);
          setDialogValue("อัปโหลดไม่สำเร็จ โปรดลองอีกครั้ง");
          setExistedCsv([]);
        }
      })
      .catch((err) => console.log(err));
  };

  const startState = {
    prefix_th: "",
    first_name_th: "",
    last_name_th: "",
    prefix_en: "",
    first_name_en: "",
    last_name_en: "",
    // gender: g,
    department: "",
    position: "",
    minister: "",
    level: "",
    internal_contact_number: "",
    primary_telephone: "",
    secondary_telephone: "",
    primary_email: "",
    secondary_email: "",
    citizen_id: "",
    address: "",
    status: "",
    district: "",
    province: "",
    primary_picture: "",
  };

  const [person, setPerson] = useState(startState);

  const handlePerson = (e) => {
    setPerson({
      ...person,
      [e.target.name]: e.target.value,
    });
  };

  const postPerson = (e) => {
    if (person.first_name_th == "") {
      setIsFN(true);
    }
    if (person.last_name_th == "") {
      setIsLN(true);
    }
    if (person.department == "") {
      setIsDep(true);
    }
    if (
      person.first_name_th == "" ||
      person.last_name_th == "" ||
      person.department == "" ||
      person.primary_telephone == ""
    ) {
      if (person.first_name_th == "") {
        setIsFN(true);
      }
      if (person.last_name_th == "") {
        setIsLN(true);
      }
      if (person.department == "") {
        setIsDep(true);
      }
      if (person.primary_telephone == "") {
        setIsTel1(true);
      }
      return;
    } else {
      e.preventDefault();
      axios
        .post(baseURL + "/personal/new", person, {
          headers: {
            Authorization: `bearer ${user.token}`,
          },
          //withCredentials: true,
        })
        .then((res) => {
          if (res.status == "200") {
            setOpen(true);
            setDialogValue("เพิ่มข้อมูลสำเร็จ");
          } else {
            setOpen(true);
            setDialogValue("เพิ่มข้อมูลไม่สำเร็จ โปรดลองอีกครั้ง");
          }
        })
        .catch((err) => {
          if (err.response.status == 409) {
            setOpen(true);
            setDialogValue("เลขหมายนี้มีอยู่ในฐานระบบอยู่แล้ว");
          }
        });
    }
  };

  useEffect(() => {
    let isLogIn = JSON.parse(localStorage.getItem("user"));
    if (isLogIn == null) {
      navigate(`/`);
    } else if (isLogIn.role !== "admin") {
      navigate("/home");
    }
  }, []);

  return (
    <BackGround>
      <Nav isStaff={true} isLoggedIn={true} />
      <Section>
        <h3>วิธีการเพิ่มข้อมูล</h3>
        <FormControl
          sx={{
            marginTop: 2,
            flexDirection: "row",
            ".MuiFormControlLabel-root": {
              margin: 0,
            },
          }}
        >
          <RadioGroup
            defaultValue="normal"
            row
            sx={{
              "&.MuiFormGroup-row": {
                alignSelf: "center",
                paddingBottom: ".5rem",
              },
            }}
          >
            <FormControlLabel
              onClick={() => setUpload(false)}
              sx={{ ".css-iiio54-MuiTypography-root": { fontSize: 24 } }}
              control={<Radio sx={{ ".css-hyxlzm": { color: "#e3e3e3" } }} />}
              value="normal"
              label="เพิ่มปกติ"
            />
            <FormControlLabel
              onClick={() => setUpload(true)}
              sx={{ ".css-iiio54-MuiTypography-root": { fontSize: 24 } }}
              control={<Radio sx={{ ".css-hyxlzm": { color: "#e3e3e3" } }} />}
              value="upload"
              label="อัปโหลด"
            />
          </RadioGroup>
        </FormControl>
        <AddSection>
          {isUpload ? (
            <>
              <UploadSection>
                <PersonalCSV />
                <div>
                  <input
                    type="file"
                    accept=".csv"
                    onChange={handleFileUpload}
                  />
                  <Button
                    variant="contained"
                    color="w"
                    onClick={() => csvCheck()}
                  >
                    ตรวจสอบ
                  </Button>
                </div>
                {responseData ? (
                  <>
                    <p style={{ margin: "1rem" }}>
                      มีข้อมูลใหม่ {newCsv.length} คน และมีข้อมูลเดิมอยู่{" "}
                      {existedCsv.length} คน
                    </p>
                    <p className="ps">
                      *หากอัปโหลดทั้งหมด ข้อมูลที่ซ้ำจะถูกอัปเดตเป็นข้อมูลใหม่
                    </p>
                    <ButtonWrapper>
                      {newCsv.length > 0 ? (
                        <Button
                          variant="contained"
                          color="b"
                          onClick={() => uploadNew()}
                        >
                          อัปโหลดเฉพาะข้อมูลใหม่
                        </Button>
                      ) : (
                        <></>
                      )}
                      <Button
                        variant="contained"
                        color="b"
                        onClick={() => {
                          csvUpload();
                          csvCheck();
                        }}
                      >
                        อัปโหลดทั้งหมด
                      </Button>
                    </ButtonWrapper>
                  </>
                ) : (
                  <></>
                )}
              </UploadSection>
            </>
          ) : (
            // <form onSubmit={postPerson}>
            <DataWrapper>
              <PhotoUserWrapper>
                <h3 className="user">รูปภาพ</h3>
                {image === null ? (
                  <img src={noperson}></img>
                ) : (
                  <img src={image}></img>
                )}
                <FileBase64
                  onChange={handlePerson}
                  multiple={false}
                  onDone={({ base64 }) => {
                    setPerson({ ...person, profile_picture: base64 });
                    setImage(base64);
                  }}
                />
                <p style={{ fontSize: 14, marginTop: 8 }}>
                  ขนาดไฟล์ไม่เกิน 1 Mb
                </p>
              </PhotoUserWrapper>
              <Column>
                <h3>ข้อมูลส่วนตัว</h3>
                <CssTextField
                  name="prefix_th"
                  value={person.prefix_th}
                  onChange={handlePerson}
                  label="คำนำหน้า"
                  variant="filled"
                  color="w"
                  type="text"
                />
                <RequiredCssTextField
                  name="first_name_th"
                  value={person.first_name_th}
                  onChange={handlePerson}
                  label="ชื่อ"
                  variant="filled"
                  error={isFN}
                  helperText={isFN ? "กรุณากรอกข้อมูล" : null}
                  color="w"
                  type="text"
                />
                <RequiredCssTextField
                  name="last_name_th"
                  value={person.last_name_th}
                  onChange={handlePerson}
                  label="นามสกุล"
                  variant="filled"
                  color="w"
                  error={isLN}
                  helperText={isLN ? "กรุณากรอกข้อมูล" : null}
                  type="text"
                />
                <RequiredCssTextField
                  name="primary_telephone"
                  value={person.primary_telephone}
                  onChange={handlePerson}
                  label="เบอร์โทรศัพท์ 1"
                  error={isTel1}
                  helperText={isTel1 ? "กรุณากรอกข้อมูล" : null}
                  inputProps={{ maxLength: 10 }}
                  variant="filled"
                  color="w"
                  type="text"
                />
                <CssTextField
                  name="secondary_telephone"
                  value={person.secondary_telephone}
                  onChange={handlePerson}
                  label="เบอร์โทรศัพท์ 2"
                  variant="filled"
                  color="w"
                  type="text"
                />
                <CssTextField
                  name="primary_email"
                  value={person.primary_email}
                  onChange={handlePerson}
                  label="อีเมล 1"
                  variant="filled"
                  color="w"
                  type="text"
                />
                <CssTextField
                  name="secondary_email"
                  value={person.secondary_email}
                  onChange={handlePerson}
                  label="อีเมล 2"
                  variant="filled"
                  color="w"
                  type="text"
                />
                <div></div>
              </Column>
              <Column className="department">
                <h3 className="department">ข้อมูลหน่วยงาน</h3>
                <RequiredCssTextField
                  name="department"
                  value={person.department}
                  onChange={handlePerson}
                  className="department-data"
                  label="สำนัก/กอง/จังหวัด"
                  error={isDep}
                  helperText={isDep ? "กรุณากรอกข้อมูล" : null}
                  variant="filled"
                  color="w"
                  type="text"
                />
                <CssTextField
                  className="department-data"
                  name="minister"
                  value={person.minister}
                  onChange={handlePerson}
                  label="กลุ่มงาน/ฝ่าย/อำเภอ"
                  variant="filled"
                  color="w"
                  type="text"
                />
                <CssTextField
                  className="department-data"
                  name="position"
                  value={person.position}
                  onChange={handlePerson}
                  label="ตำแหน่ง"
                  variant="filled"
                  color="w"
                  type="text"
                />
                <CssTextField
                  className="department-data"
                  name="level"
                  value={person.level}
                  onChange={handlePerson}
                  label="ระดับ"
                  variant="filled"
                  color="w"
                  type="text"
                />
                <CssTextField
                  name="internal_contact_number"
                  value={person.internal_contact_number}
                  onChange={handlePerson}
                  className="department-data"
                  label="เบอร์ติดต่อภายใน"
                  variant="filled"
                  color="w"
                  type="text"
                />
                <Button
                  sx={{
                    width: 300,
                    "@media (max-width: 375px)": {
                      width: "280px",
                    },
                  }}
                  variant="contained"
                  color="b"
                  type="submit"
                  onClick={postPerson}
                >
                  ยืนยัน
                </Button>
              </Column>
              <ConfirmDialog
                open={open}
                dialogValue={dialogValue}
                onClose={handleClose}
              />
            </DataWrapper>
            // </form>
          )}
          <ConfirmDialog
            open={open}
            dialogValue={dialogValue}
            onClose={handleClose}
          />
        </AddSection>
      </Section>
    </BackGround>
  );
};
const Column = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "1.5rem",
  alignItems: "center",
  justifyContent: "flex-start",
});

const ButtonWrapper = styled("div")({
  display: "flex",
  columnGap: "1rem",
  marginTop: "1rem",
});

const UploadSection = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  a: {
    color: "var(--white)",
  },
  div: {
    marginTop: "2rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    rowGap: "2rem",
    Button: {
      width: "10rem",
      fontSize: "1rem",
    },
  },
});

const PhotoUserWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  marginBottom: "2rem",
  img: {
    height: "300px",
    width: "300px",
    objectFit: "cover",
    marginBottom: "1.5rem",
    marginTop: "1.5rem",
  },
  ".pre-person-img": {
    height: "200px",
    width: "200px",
    marginBottom: "1.5rem",
    borderRadius: "50%",
    backgroundColor: "lightGray",
  },
  ".user": {
    marginBottom: "1rem",
  },
  "@media (max-width: 600px)": {
    h2: {
      fontSize: "1.5rem",
    },
  },
  "@media (max-width: 375px)": {
    img: {
      width: "280px",
      height: "280px",
    },
    input: {
      width: "280px",
    },
  },
});

const DataWrapper = styled("div")({
  display: "grid",
  gridTemplateColumns: "repeat(3,1fr)",
  columnGap: "1rem",
  justifyContent: "center",
  "@media (max-width: 1000px)": {
    gridTemplateColumns: "repeat(2,1fr)",
    ".department": {
      gridColumn: "2",
    },
  },
  "@media (max-width: 768px)": {
    gridTemplateColumns: "repeat(1,1fr)",
    ".department": {
      gridColumn: "1",
    },
  },
});

const CssFormControl = styled(FormControl)({
  "label.Mui-focused": {
    color: "#203e9e",
  },
  width: "100%",
  marginBottom: "1rem",
  ".MuiInputLabel-root": {
    color: "#203e9e",
  },
  ".MuiFilledInput-root": {
    backgroundColor: "#d5d5d5",
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    width: 300,
  },
  ".MuiFilledInput-root.Mui-focused": {
    backgroundColor: "#d5d5d5",
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  ".MuiFilledInput-underline:after": {
    borderBottom: "3px solid #203b9b",
  },
  "&.MuiFormControl-root": {
    display: "flex",
    alignItems: "center",
    width: "300px",
  },
  "@media (max-width: 375px)": {
    ".MuiFilledInput-root": {
      backgroundColor: "#d5d5d5",
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      width: 280,
    },
    ".MuiInputLabel-root": {
      marginLeft: "10px",
    },
  },
});

const CssSelect = styled(Select)({
  ".MuiSelect-select": {
    backgroundColor: "#d5d5d5",
    color: "#202020",
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  ".MuiSelect-filled": {
    backgroundColor: "#d5d5d5",
  },
});

const Input = styled("input")({
  display: "none",
});

const RequiredCssTextField = styled(TextField)({
  ".MuiFilledInput-root": {
    backgroundColor: "#d5d5d5",
    transitionDuration: "0ms",
    borderTopRightRadius: 5,
  },
  ".MuiFilledInput-root.Mui-focused": {
    backgroundColor: "#d5d5d5",
  },
  ".MuiInputLabel-root": {
    color: "#203b9b",
  },
  "label.Mui-focused": {
    color: "#203b9b",
  },
  ".MuiFilledInput-underline:after": {
    borderBottom: "3px solid #203b9b",
  },
  ".MuiFilledInput-underline:before": {
    borderBottomColor: "#202020",
  },
  ".MuiFilledInput-root:hover": {
    backgroundColor: "#e3e3e3",
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
  },
  "p.MuiFormHelperText-root": {
    background: "transparent",
    fontSize: "15px",
    color: "#e3e3e3",
  },
  width: 300,
  "@media (max-width: 1370px)": {
    width: 270,
  },
  "@media (max-width: 375px)": {
    width: "270px",
  },
});
const CssTextField = styled(TextField)({
  ".MuiFilledInput-root": {
    backgroundColor: "#d5d5d5",
    transitionDuration: "0ms",
    borderTopRightRadius: 5,
  },
  ".MuiFilledInput-root.Mui-focused": {
    backgroundColor: "#d5d5d5",
  },
  ".MuiInputLabel-root": {
    color: "  #404040",
  },
  "label.Mui-focused": {
    color: "#303030",
  },
  ".MuiFilledInput-underline:after": {
    borderBottom: "3px solid #203b9b",
  },
  ".MuiFilledInput-underline:before": {
    borderBottomColor: "#202020",
  },
  ".MuiFilledInput-root:hover": {
    backgroundColor: "#e3e3e3",
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
  },
  "p.MuiFormHelperText-root": {
    background: "transparent",
    fontSize: "15px",
    color: "#e3e3e3",
  },
  width: 300,
  "@media (max-width: 1370px)": {
    width: 270,
  },
  "@media (max-width: 375px)": {
    width: "270px",
  },
});

const AddSection = styled("div")({
  width: "100%",
});

const Section = styled("div")({
  display: "flex",
  padding: "2rem 3rem",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",

  "@media (max-width: 600px)": {
    padding: "2rem 1rem",
    h1: {
      fontSize: 32,
    },
    h3: {
      fontSize: 24,
    },
  },
});

const BackGround = styled("div")`
  min-height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${imgSrc});
  background-repeat: no-repeat;
  background-size: cover;
  color: var(--white);
`;
