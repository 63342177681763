import React, { useEffect, useState } from "react";
import { styled } from "@mui/material";
import { Nav } from "../components/Nav";
import imgSrc from "../image/bg.jpg";
import personImg from "../image/person.jpg";
import { examplePlaceData } from "../Db";
import { Link, useNavigate, useParams } from "react-router-dom";
import StarRateIcon from "@mui/icons-material/StarRate";
import axios from "axios";
import place from "../image/simulated place.jpg";
import StarIcon from "@mui/icons-material/Star";
import { border, display, fontSize, textAlign } from "@mui/system";

export const DepartmentDetail = () => {
  const [data, setData] = useState(null);
  const [favData, setFavData] = useState([]);
  const { id } = useParams();
  const [favor, setFavor] = useState(false);
  const baseURL = process.env.REACT_APP_URL;
  let user = JSON.parse(localStorage.getItem("user"));
  const [personId, setPersonId] = useState("");
  let navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState(false);

  const addFavorite = () => {
    axios
      .post(
        baseURL + `/favorite/${user.user_id}/department`,
        {
          department: data.name_th,
        },
        {
          headers: {
            Authorization: `bearer ${user.token}`,
          },
          //withCredentials: true,
        }
      )
      .then((res) => {
        // console.log(res)
      });
    setFavor(!favor);
  };

  const removeFavorite = () => {
    axios
      .patch(
        baseURL + `/favorite/${user.user_id}/department`,
        {
          department_id: data._id,
        },
        {
          headers: {
            Authorization: `bearer ${user.token}`,
          },
          //withCredentials: true,
        }
      )
      .then((res) => {
        // console.log(res)
      });
    setFavor(!favor);
  };

  useEffect(() => {
    let isLogIn = JSON.parse(localStorage.getItem("user"));
    if (isLogIn.role == "admin") {
      setIsAdmin(true);
    }
    if (isLogIn == null) {
      navigate(`/`);
    } else {
      axios
        .get(baseURL + "/personal/user/" + user.user_id, {
          headers: {
            Authorization: `bearer ${user.token}`,
          },
          //withCredentials: true,
        })
        .then((res2) => {
          setPersonId(res2.data._id);
          axios
            .get(baseURL + "/department/" + id, {
              headers: {
                Authorization: `bearer ${user.token}`,
              },
              //withCredentials: true,
            })
            .then((res1) => {
              setData(res1.data);
              //console.log(res1.data)
              axios
                .get(baseURL + "/favorite/" + user.user_id, {
                  headers: {
                    Authorization: `bearer ${user.token}`,
                  },
                  //withCredentials: true,
                })
                .then((res) => {
                  if (res.data) {
                    for (let i = 0; i < res.data.department_id.length; i++) {
                      if (
                        res1.data.name_th === res.data.department_id[i].name_th
                      ) {
                        setFavor(!favor);
                      }
                    }
                  }
                });
            });
        });
    }
  }, []);

  if (!data) {
    return null;
  }

  return (
    <BackGround>
      <Nav isLoggedIn={true} />
      <Section>
        <PhotoWrapper>
          <div>
            {data.primary_picture ? (
              <img src={data.primary_picture} alt="" />
            ) : (
              <img src={place} alt="" />
            )}
            {favor === false ? (
              <StarIcon
                onClick={addFavorite}
                sx={{ cursor: "pointer", color: "gray" }}
              />
            ) : (
              <StarIcon
                color="yellow"
                onClick={removeFavorite}
                sx={{ cursor: "pointer" }}
              />
            )}
          </div>
          <ContactWrapper>
            <a className="call" href={`tel:${data.primary_telephone}`}>
              โทรออก
            </a>
            <a className="email" href={`mailto:${data.primary_email}`}>
              ส่งอีเมล
            </a>
          </ContactWrapper>
        </PhotoWrapper>
        <HeadDesc>
          <h3>{data.name_th}</h3>
          <p>
            ที่อยู่ : {data.address} {data.subdistrict} {data.district}{" "}
            {data.province} {data.zipcode}
          </p>
          <p>เบอร์ติดต่อ 1 : {data.primary_telephone}</p>
          <p>เบอร์ติดต่อ 2 : {data.secondary_telephone}</p>
          <p>อีเมล : {data.primary_email}</p>
          <p>อีเมล 2 : {data.secondary_email}</p>
          <p>เว็บไซต์ : {data.url}</p>
          <p>สังกัด : {data.minister}</p>
          <p>แฟกซ์ : {data.fax}</p>
          <p>เบอร์ติดต่อภายใน : {data.internal_contact_number}</p>
          <p>วันเวลา เปิด-ปิด : {data.open_time}</p>
        </HeadDesc>

        {/* <DateTime>
          <h3>วัน-เวลาทำการ</h3>
          <OperationHeader>
            <HeadSubject>วัน</HeadSubject>
            <HeadSubject>เวลา</HeadSubject>
          </OperationHeader>
          <OperationHeader>
            <Head>จันทร์</Head>
            <Head>{data.Monday}</Head>
          </OperationHeader>
          <OperationHeader>
            <Head>อังคาร</Head>
            <Head>{data.Tuesday}</Head>
          </OperationHeader>
          <OperationHeader>
            <Head>พุธ</Head>
            <Head>{data.Wednesday}</Head>
          </OperationHeader>
          <OperationHeader>
            <Head>พฤหัสบดี</Head>
            <Head>{data.Thursday}</Head>
          </OperationHeader>
          <OperationHeader>
            <Head>ศุกร์</Head>
            <Head>{data.Friday}</Head>
          </OperationHeader>
          <OperationHeader>
            <Head>เสาร์</Head>
            <Head>{data.Saturday}</Head>
          </OperationHeader>
          <OperationHeader>
            <Head>อาทิตย์</Head>
            <Head>{data.Sunday}</Head>
          </OperationHeader>
        </DateTime> */}
        <Desc>
          <h2>คำอธิบาย</h2>
          <p>{data.full_description}</p>
        </Desc>
        <EditDetail>
          <p className="edit">
            วันที่แก้ไขล่าสุด :{" "}
            {new Date(data.updated_at).toLocaleString("th-TH", {
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              second: "numeric",
              timeZone: "GMT",
            })}
          </p>
          {isAdmin ? (
            <p className="edit">ผู้แก้ไข : {data.updated_by} </p>
          ) : null}
        </EditDetail>
      </Section>
    </BackGround>
  );
};

const EditDetail = styled("div")({
  display: "flex",
  flexDirection: "column",
  gridColumn: "5",

  ".edit": {
    fontSize: 16,
  },
  "@media (max-width: 1000px)": {
    gridColumn: "2",
    p: {
      fontSize: "1rem",
    },
  },
  "@media (max-width: 650px)": {
    gridColumn: "1",
    textAlign: "center",
  },
});

const OperationHeader = styled("div")({
  display: "flex",
  flexDirection: "row",
  width: "100%",
});
const HeadSubject = styled("div")({
  border: "1px solid white",
  fontSize: 20,
  display: "inline-block",
  flex: 1,
  width: "50%",
  color: "#202020",
  background: "rgba(248,248,248,0.9)",
  padding: ".5rem",
  "@media (max-width: 450px)": {
    flex: 1,
    width: "130px",
    padding: "8px 0px",
    fontSize: 16,
  },
});

const Head = styled("div")({
  border: "1px solid white",
  fontSize: 20,
  display: "inline-block",
  flex: 1,
  width: "50%",
  background: "rgba(20,20,20,0.8)",

  padding: ".5rem",
  "@media (max-width: 450px)": {
    flex: 1,
    width: "130px",
    padding: "8px 0px",
    fontSize: 16,
  },
});

const DateTime = styled("div")({
  gridColumn: "1/3",
  display: "flex",
  flexDirection: "column",
  textAlign: "center",
  h3: {
    marginBottom: ".5rem",
  },
  "@media (max-width: 1000px)": {
    gridColumn: "1",
  },
  "@media (max-width: 750px)": {
    h3: {
      marginTop: "1rem",
    },
  },
});

const PhotoWrapper = styled("div")({
  gridColumn: "1/3",
  display: "flex",
  flexDirection: "column",
  justifySelf: "flex-end",
  position: "relative",
  height: "400px",
  div: {
    height: "350px",
    width: "350px",
  },
  img: {
    borderRadius: "5px 5px 0px 0px",
    width: "100%",
    height: "350px",
    objectFit: "fill",
  },
  ".MuiSvgIcon-root": {
    fontSize: 48,
    position: "absolute",
    left: "10px",
    top: "10px",
  },
  "@media (max-width: 1000px)": {
    gridColumn: "1",
  },
  "@media(max-width:800px)": {
    height: "350px",
    div: {
      height: "300px",
    },
    img: {
      height: "300px",
    },
  },
  "@media(max-width:650px)": {
    justifySelf: "center",
    height: "300px",
    div: {
      height: "250px",
      width: "250px",
    },
    img: {
      height: "250px",
      width: "250px",
    },
  },
});

const ContactWrapper = styled("div")({
  display: "flex",
  ".email": {
    background: "#203B9B",
    borderRadius: "0px 0px 5px 0px",
  },
  ".call": {
    background: "#20B22F",
    borderRadius: "0px 0px 0px 5px",
  },
  a: {
    width: "100%",
    textAlign: "center",
    height: "50px",
    zIndex: "3",
    paddingTop: "10px",
    color: "var(--white)",
    textDecoration: "none",
  },
  "@media(max-width:400px)": {
    a: {
      height: "50px",
    },
  },
});

const HeadDesc = styled("div")({
  gridColumn: "3/6",
  lineHeight: "200%",
  display: "flex",
  flexDirection: "column",
  height: "400px",
  justifyContent: "space-between",
  p: {
    fontSize: 20,
  },
  h3: {
    marginBottom: 8,
  },
  "@media (max-width: 1000px)": {
    gridColumn: "2",
  },
  "@media (max-width: 800px)": {
    height: "350px",
    lineHeight: "200%",
    h3: {
      fontSize: "1.5rem",
      marginBottom: "0rem",
    },
    p: {
      fontSize: "1rem",
    },
  },
  "@media (max-width: 650px)": {
    gridColumn: "1",
    textAlign: "center",
  },
});

const Desc = styled("div")({
  gridColumn: "1/6",
  padding: "0",
  minHeight: "18rem",
  h2: {
    marginBottom: ".5rem",
    textAlign: "center",
  },
  p: {
    fontSize: 16,
  },
  "@media (max-width: 1000px)": {
    gridColumn: "2",
  },
  "@media (max-width: 800px)": {
    p: {
      fontSize: "1rem",
    },
  },
  "@media (max-width: 650px)": {
    marginTop: "2rem",
    gridColumn: "1",
    textAlign: "center",
  },
});

const Section = styled("div")({
  padding: "3rem 3rem",
  display: "grid",
  gridTemplateColumns: "repeat(5,1fr)",
  gridTemplateRows: "repeat(2,max-content)",
  columnGap: "2rem",
  rowGap: "1rem",
  "@media (max-width: 1000px)": {
    gridTemplateColumns: "repeat(2,1fr)",
    padding: "3rem 1rem",
  },
  "@media (max-width: 800px)": {
    padding: "3rem 1rem",
  },
  "@media (max-width: 750px)": {
    gridTemplateColumns: "repeat(1,1fr)",
    rowGap: "1rem",
  },
});

const BackGround = styled("div")`
  min-height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${imgSrc});
  background-repeat: no-repeat;
  background-size: cover;
  color: var(--white);
`;
