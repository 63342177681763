import React, { useEffect, useState } from "react";
import { styled, Button } from "@mui/material";
import imgSrc from "../image/bg.jpg";
import { Nav } from "../components/Nav";
import { StaffEditData } from "./StaffEditData";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";

export const StaffDataMenu = () => {
  let navigate = useNavigate();
  // const baseURL = 'process.env.REACT_APP_URL';
  const baseURL = process.env.REACT_APP_URL;
  const user = JSON.parse(localStorage.getItem("user"));
  const [feature, setFeature] = useState({
    import: false,
    delete: false,
    update: false,
    export: false,
    assign: false,
  });
  const [depLastestUpdated, setDepLastestUpdated] = useState("");
  const [perLastestUpdated, setPerLastestUpdated] = useState("");
  const [perUpdatedBy, setPerUpdatedBy] = useState("");
  const [depUpdatedBy, setDepUpdatedBy] = useState("");

  const importFeature = () => {
    axios
      .get(baseURL + "/feature/" + user.user_id, {
        headers: {
          Authorization: `bearer ${user.token}`,
        },
        //withCredentials: true,
      })
      .then((res) => {
        setFeature({
          import: res.data.import,
          delete: res.data.delete,
          update: res.data.update,
          export: res.data.export,
          assign: res.data.assign,
        });
        if (res.data.import == false) {
          window.location.reload();
        } else {
          navigate("/select-type");
        }
      });
  };
  const deleteFeature = () => {
    axios
      .get(baseURL + "/feature/" + user.user_id, {
        headers: {
          Authorization: `bearer ${user.token}`,
        },
        //withCredentials: true,
      })
      .then((res) => {
        setFeature({
          import: res.data.import,
          delete: res.data.delete,
          update: res.data.update,
          export: res.data.export,
          assign: res.data.assign,
        });
        if (res.data.delete == false) {
          window.location.reload();
        } else {
          navigate("/delete-by-upload");
        }
      });
  };

  useEffect(() => {
    let isLogIn = JSON.parse(localStorage.getItem("user"));
    if (isLogIn == null) {
      navigate(`/`);
    } else if (isLogIn.role !== "admin") {
    } else {
      axios
        .get(baseURL + "/feature/" + user.user_id, {
          headers: {
            Authorization: `bearer ${user.token}`,
          },
          //withCredentials: true,
        })
        .then((res) => {
          setFeature({
            import: res.data.import,
            delete: res.data.delete,
            update: res.data.update,
            export: res.data.export,
            assign: res.data.assign,
          });
          axios
            .get(baseURL + "/department/lastestUpdate", {
              headers: {
                Authorization: `bearer ${user.token}`,
              },
              //withCredentials: true,
            })
            .then((res) => {
              if (res.data[0]) {
                let date = new Date(res.data[0].updated_at).toLocaleString(
                  "th-TH",
                  {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                    timeZone: "GMT",
                  }
                );
                setDepLastestUpdated(date);
                setDepUpdatedBy(res.data[0].updated_by);
              }
              axios
                .get(baseURL + "/personal/lastestUpdate", {
                  headers: {
                    Authorization: `bearer ${user.token}`,
                  },
                  //withCredentials: true,
                })
                .then((res) => {
                  if (res.data[0]) {
                    let date = new Date(res.data[0].updated_at).toLocaleString(
                      "th-TH",
                      {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        second: "numeric",
                        timeZone: "GMT",
                      }
                    );
                    setPerLastestUpdated(date);
                    console.log(res.data[0]);
                    setPerUpdatedBy(res.data[0].updated_by);
                  }
                });
            });
        });
    }
  }, []);

  return (
    <BackGround>
      <Nav isLoggedIn={true} isStaff={true} />
      <Section>
        <h2>เลือกประเภทการจัดการข้อมูล</h2>
        <ButtonWrapper>
          <Button
            onClick={() => navigate("/staff-edit-data")}
            sx={{
              width: "300px",
              "@media (max-width:450px)": { fontSize: 16, width: "280px" },
            }}
            variant="contained"
            color="w"
          >
            ค้นหา,แก้ไขและลบข้อมูล
          </Button>
          {feature.import ? (
            <>
              <Button
                onClick={() => {
                  importFeature();
                }}
                sx={{
                  width: "300px",
                  "@media (max-width:450px)": { fontSize: 16, width: "280px" },
                }}
                variant="contained"
                color="w"
              >
                เพิ่มข้อมูล
              </Button>
            </>
          ) : null}
          {feature.delete ? (
            <>
              <Button
                onClick={() => deleteFeature()}
                sx={{
                  width: "300px",
                  "@media (max-width:450px)": { fontSize: 16, width: "280px" },
                }}
                variant="contained"
                color="w"
              >
                ลบข้อมูลโดยอัปโหลดไฟล์
              </Button>
            </>
          ) : null}
        </ButtonWrapper>
        <UpdateWrapper>
          <p>การแก้ไขข้อมูลล่าสุด</p>
          <p>
            หน่วยงาน : วันที่ {depLastestUpdated} <br className="br" />
            โดย {depUpdatedBy}
          </p>
          <p>
            ข้อมูลบุคคล : วันที่ {perLastestUpdated} <br className="br" />
            โดย {perUpdatedBy}
          </p>
        </UpdateWrapper>
        <Link
          style={{ color: "#e3e3e3", fontSize: 18, marginTop: 16 }}
          to="/adminmanual.pdf"
          target="_blank"
        >
          คู่มือสำหรับแอดมิน
        </Link>
      </Section>
    </BackGround>
  );
};

const UpdateWrapper = styled("div")`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  .br {
    display: none;
  }
  @media (max-width: 800px) {
    .br {
      display: block;
    }
  }
`;

const ButtonWrapper = styled("div")`
  padding-top: 3rem;
  display: flex;
  justify-content: space-evenly;
  column-gap: 2rem;
  width: 80%;
  @media (max-width: 800px) {
    flex-direction: column;
    row-gap: 2rem;
    justify-content: center;
    align-items: center;
    button {
      font-size: 1.5rem;
    }
  }
  @media (max-width: 600px) {
    button {
      font-size: 1rem;
    }
  }
`;

const Section = styled("div")`
  padding: 8rem 3rem;
  color: var(--white);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 800px) {
    padding: 2rem 1rem;
    h2 {
      font-size: 1.5rem;
    }
    p {
      font-size: 1rem;
    }
  }
  @media (max-width: 450px) {
    h2 {
      font-size: 1.5rem;
    }
  }
`;

const BackGround = styled("div")`
  min-height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${imgSrc});
  background-repeat: no-repeat;
  background-size: cover;
`;
