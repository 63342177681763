import React from "react";
import { styled, Button } from "@mui/material";
import imgSrc from "../image/bg.jpg";
import { Nav } from "../components/Nav";
import { StaffEditData } from "./StaffEditData";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export const SelectType = () => {
  let navigate = useNavigate();

  useEffect( () => {
    let isLogIn = JSON.parse(localStorage.getItem('user'))
    if( isLogIn == null){
      navigate(`/`)
    } else if( isLogIn.role !== 'admin'){
      navigate('/home')
    }
  },[])

  return (
    <BackGround>
      <Nav isStaff={true} isLoggedIn={true} />
      <Section>
        <h2>เลือกประเภทข้อมูล</h2>
        <ButtonWrapper>
          <Button
            onClick={() => navigate("/add-department")}
            sx={{
              width: "50%",
              "@media (max-width:450px)": { fontSize: 16 },
            }}
            variant="contained"
            color="w"
          >
            หน่วยงานและสถานที่
          </Button>
          <Button
            onClick={() => navigate("/add-person")}
            sx={{
              width: "50%",
              "@media (max-width:450px)": { fontSize: 16 },
            }}
            variant="contained"
            color="w"
          >
            บุคลากร
          </Button>
        </ButtonWrapper>
      </Section>
    </BackGround>
  );
};

const ButtonWrapper = styled("div")`
  padding-top: 3rem;
  display: flex;
  justify-content: space-evenly;
  column-gap: 2rem;
  width: 80%;
`;

const Section = styled("div")`
  padding: 8rem 3rem;
  color: var(--white);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 450px) {
    padding: 8rem 1rem;
    h2 {
      font-size: 2rem;
    }
  }
`;

const BackGround = styled("div")`
  min-height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${imgSrc});
  background-repeat: no-repeat;
  background-size: cover;
`;
