import React from "react";
import { useState } from "react";
import { CSVLink } from "react-csv";

export const ExportCSV = (props) => {
  const data = props.data;



  return (
    <>
    <CSVLink data={data} filename={"exportData"} target="_blank">
      <p style={{ fontSize: 16}}>
        ดาวน์โหลดข้อมูล
      </p>
    </CSVLink>
    </>
  );
};
