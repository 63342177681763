import React, { useState, useEffect } from "react";
import { styled } from "@mui/material";
import imgSrc from "../image/bg.jpg";
import { Nav } from "../components/Nav";
import { SearchBar } from "../components/SearchBar";
import PropTypes from "prop-types";
import { examplePlaceData } from "../Db";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ExportCSV } from "../components/ExportCSV";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
const DeleteDialog = (props) => {
  const { onClose, selectedValue, open, deleteData, type } = props;

  return (
    <Dialog open={open}>
      <DialogTitle>ยืนยันการลบข้อมูล</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {type == "department" ? (
            <p style={{ fontSize: "1rem" }}>{selectedValue.name_th}</p>
          ) : (
            <p style={{ fontSize: "1rem" }}>
              {selectedValue.first_name_th} {selectedValue.last_name_th}
            </p>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          sx={{ color: "#202020", fontSize: "1rem" }}
          variant="contained"
          color="w"
        >
          ไม่
        </Button>
        <Button
          onClick={() => {
            deleteData(selectedValue._id, selectedValue.user_id);
          }}
          sx={{ color: "#e3e3e3", fontSize: "1rem", width: "70%" }}
          variant="contained"
          color="red"
        >
          ยืนยัน
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DeleteDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.object.isRequired,
  deleteData: PropTypes.func.isRequired,
};

export const StaffEditData = () => {
  const [type, setType] = useState("");
  const [data, setData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [headers, setHeaders] = useState();
  const navigate = useNavigate();
  // const baseURL = 'process.env.REACT_APP_URL';
  const baseURL = process.env.REACT_APP_URL;
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState({});
  const user = JSON.parse(localStorage.getItem("user"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const truncateText = (text, length) => {
    if (text && text.length > length) {
      text = text.substr(0, length) + "...";
    }
    return text;
  };

  const handleClose = () => {
    setOpen(false);
  };

  const childToParent = (childdata) => {
    childdata.map((o) => {
      delete o["primary_picture"];
    });
    setData(childdata);
  };

  const getData = () => {
    axios
      .get(baseURL + `/${type}?search=${searchValue}`, {
        headers: {
          Authorization: `bearer ${user.token}`,
        },
        //withCredentials: true,
      })
      .then((res) => {
        setData(res.data);
      });
  };

  const deleteData = (id, user_id) => {
    // console.log(user_id);
    if (user_id) {
      axios
        .delete(baseURL + "/user/delete/" + user_id, {
          headers: {
            Authorization: `bearer ${user.token}`,
          },
          //withCredentials: true,
        })
        .then((res) => {
          //console.log(res)
        });
    }
    axios
      .delete(baseURL + `/${type}/` + id, {
        headers: {
          Authorization: `bearer ${user.token}`,
        },
        //withCredentials: true,
      })
      .then(() => {
        axios
          .get(baseURL + `/${type}?search=${searchValue}`, {
            headers: {
              Authorization: `bearer ${user.token}`,
            },
            //withCredentials: true,
          })
          .then((res) => {
            setData(res.data);
          });
      });
    setOpen(false);
  };

  const [feature, setFeature] = useState({
    import: false,
    delete: false,
    update: false,
    export: false,
    assign: false,
  });

  const downloadFeature = () => {
    axios
      .get(baseURL + "/feature/" + user.user_id, {
        headers: {
          Authorization: `bearer ${user.token}`,
        },
        //withCredentials: true,
      })
      .then((res) => {
        setFeature({
          import: res.data.import,
          delete: res.data.delete,
          update: res.data.update,
          export: res.data.export,
          assign: res.data.assign,
        });
        // if(res.data.export == false){
        //   window.location.reload()
        // }
        console.log(res.data.export);
        return res.data.export;
      });
  };

  const deleteFeature = (place) => {
    console.log(place);
    axios
      .get(baseURL + "/feature/" + user.user_id, {
        headers: {
          Authorization: `bearer ${user.token}`,
        },
        //withCredentials: true,
      })
      .then((res) => {
        setFeature({
          import: res.data.import,
          delete: res.data.delete,
          update: res.data.update,
          export: res.data.export,
          assign: res.data.assign,
        });
        if (res.data.delete == false) {
          window.location.reload();
        } else {
          handleClickOpen();
          setSelectedValue(place);
        }
      });
  };

  const editFeature = (place) => {
    axios
      .get(baseURL + "/feature/" + user.user_id, {
        headers: {
          Authorization: `bearer ${user.token}`,
        },
        //withCredentials: true,
      })
      .then((res) => {
        setFeature({
          import: res.data.import,
          delete: res.data.delete,
          update: res.data.update,
          export: res.data.export,
          assign: res.data.assign,
        });
        if (res.data.update == false) {
          window.location.reload();
        } else {
          if (type === "department") {
            window.open(`/edit-department-detail/${place._id}`);
          } else {
            window.open(`/edit-personal-data/${place._id}`);
          }
        }
      });
  };

  const exportExcel = async () => {
    let response = await axios.post(baseURL + "/personal/export", data, {
      //withCredentials: true,
      responseType: "arraybuffer",
    });
    const type = response.headers["content-type"];
    const blob = new Blob([response.data], { type: type, encoding: "UTF-8" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "export.xlsx";
    link.click();
  };

  useEffect(() => {
    let isLogIn = JSON.parse(localStorage.getItem("user"));
    if (isLogIn == null) {
      navigate(`/`);
    } else if (isLogIn.role !== "admin") {
      navigate("/home");
    } else {
      axios
        .get(baseURL + "/feature/" + user.user_id, {
          headers: {
            Authorization: `bearer ${user.token}`,
          },
          //withCredentials: true,
        })
        .then((res) => {
          console.log(res);
          setFeature({
            import: res.data.import,
            delete: res.data.delete,
            update: res.data.update,
            export: res.data.export,
            assign: res.data.assign,
          });
        });
    }
  }, []);

  return (
    <>
      <BackGround>
        <Nav isStaff={true} isLoggedIn={true} />
        <SearchBar
          name={"แก้ไขข้อมูล"}
          isStaff={true}
          childToParent={childToParent}
          setType={setType}
          setSearchValue={setSearchValue}
          type={"department"}
        />
        <TableSearch>
          <div className="result">
            <p>ผลการค้นหา ({data.length})</p>
            {feature.export ? (
              <>
                <p onClick={exportExcel}>download</p>
                <ExportCSV type={type} data={data} />
              </>
            ) : null}
          </div>
          <Headers>
            <Header className="count">
              <p>ลำดับ</p>
            </Header>
            <Header className="name">
              <p>ชื่อ</p>
            </Header>
            <Header className="data">
              <p>ข้อมูล</p>
            </Header>
            <Header className="action">
              <p>จัดการ</p>
            </Header>
          </Headers>
          {data.map((place, index) => (
            <Headers key={index}>
              <Header className="count">
                <p>{index + 1}</p>
              </Header>
              <Header className="name">
                {type === "department" ? (
                  <p>{truncateText(place.name_th, 35)}</p>
                ) : (
                  <p>
                    {place.first_name_th} {place.last_name_th}
                  </p>
                )}
              </Header>
              <Header className="data">
                <p>เบอร์โทรศัพท์ : {place.primary_telephone}</p>
                {type == "personal" ? (
                  <p>
                    สถานะ :{" "}
                    {place.user_id ? "เปิดใช้งานแล้ว" : "ยังไม่เปิดใช้งาน"}
                  </p>
                ) : (
                  <p>อีเมล์ : {place.primary_email}</p>
                )}
              </Header>
              <PermissionCol>
                <Button
                  variant="contained"
                  color="w"
                  styled={{ fontSize: 16 }}
                  onClick={() => {
                    window.open(`${type}/detail/${place._id}`);
                  }}
                >
                  ข้อมูล
                </Button>
                {feature.update ? (
                  <>
                    <Button
                      variant="contained"
                      color="w"
                      onClick={() => {
                        editFeature(place);
                      }}
                    >
                      แก้ไข
                    </Button>
                  </>
                ) : null}
                {feature.delete ? (
                  <>
                    <Button
                      variant="contained"
                      color="red"
                      onClick={() => {
                        deleteFeature(place);
                      }}
                    >
                      ลบ
                    </Button>
                  </>
                ) : null}
              </PermissionCol>
            </Headers>
          ))}
          <DeleteDialog
            open={open}
            selectedValue={selectedValue}
            onClose={handleClose}
            deleteData={deleteData}
            type={type}
          />
        </TableSearch>
      </BackGround>
    </>
  );
};

const PermissionCol = styled("div")({
  display: "flex",
  gap: "0.5rem",
  border: "1px solid var(--white)",
  padding: ".5rem",
  display: "flex",
  flex: 1.5,
  justifyContent: "center",
  alignItems: "center",
  flexWrap: "wrap",
  Button: {
    fontSize: 16,
  },
  "@media (max-width: 470px)": {
    padding: "1rem 0.5rem",
  },
});

const TableSearch = styled("div")`
  padding: 0rem 3rem 3rem 3rem;
  color: var(--white);
  a {
    color: var(--white);
    display: flex;
    justify-content: flex-end;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .result {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 0.5rem;
  }
  @media (max-width: 800px) {
    padding: 0 1rem;
  }
`;

const Headers = styled("div")`
  display: flex;
  background-color: rgba(20, 20, 20, 0.5);
  .data {
    flex: 2;
  }
  .name {
    flex: 2;
  }
  .count {
    flex: 0.3;
    padding: 3px;
    min-width: 55px;
  }
  .action {
    flex: 1.5;
  }
  @media (max-width: 500px) {
    .data {
      display: none;
    }
  }
`;

const Header = styled("div")`
  text-align: center;
  border: 1px solid var(--white);
  padding: 0.5rem;
  background-color: rgba(20, 20, 20, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 800px) {
    p {
      font-size: 1rem;
    }
  }
  @media (max-width: 470px) {
    padding: 1rem 0.5rem;
  }
`;

const BackGround = styled("div")`
  min-height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${imgSrc});
  background-repeat: no-repeat;
  background-size: cover;
`;
