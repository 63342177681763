import React, { useEffect, useState } from "react";
import { styled } from "@mui/material";
import { Nav } from "../components/Nav";
import imgSrc from "../image/bg.jpg";
import personImg from "../image/person.jpg";
import { examplePlaceData } from "../Db";
import { Link, useNavigate, useParams } from "react-router-dom";
import StarIcon from "@mui/icons-material/Star";
import axios from "axios";
import person from "../image/no-image.jpg";
import { width } from "@mui/system";

export const PersonalDetail = () => {
  const [data, setData] = useState(null);
  const baseURL = process.env.REACT_APP_URL;
  const { id } = useParams();
  const [favor, setFavor] = useState(false);
  let user = JSON.parse(localStorage.getItem("user"));
  const [personId, setPersonId] = useState("");
  let navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState(false);

  const addFavorite = () => {
    axios
      .post(
        baseURL + `/favorite/${user.user_id}/personal`,
        {
          personal: data.primary_telephone,
        },
        {
          headers: {
            Authorization: `bearer ${user.token}`,
          },
          //withCredentials: true,
        }
      )
      .then((res) => {
        // console.log(res)
      });
    setFavor(!favor);
  };

  const removeFavorite = () => {
    axios
      .patch(
        baseURL + `/favorite/${user.user_id}/personal`,
        {
          personal_id: data._id,
        },
        {
          headers: {
            Authorization: `bearer ${user.token}`,
          },
          //withCredentials: true,
        }
      )
      .then((res) => {
        // console.log(res)
      });
    setFavor(!favor);
  };

  useEffect(() => {
    let isLogIn = JSON.parse(localStorage.getItem("user"));
    //console.log(user.user_id)

    if (isLogIn.role == "admin") {
      setIsAdmin(true);
    }
    if (isLogIn == null) {
      navigate(`/`);
    } else {
      // axios
      //   .get(baseURL + "/personal/user/" + id, {
      //     headers: {
      //       Authorization: `bearer ${user.token}`,
      //     },
      //     //withCredentials: true,
      //   })
      //   .then((res2) => {
      //     console.log("res2", res2);
      //     setPersonId(res2.data._id);
      axios
        .get(baseURL + "/personal/" + id, {
          headers: {
            Authorization: `bearer ${user.token}`,
          },
          //withCredentials: true,
        })
        .then((res1) => {
          console.log("res1", res1);

          setData(res1.data);
          axios
            .get(baseURL + "/favorite/" + user.user_id, {
              headers: {
                Authorization: `bearer ${user.token}`,
              },
              //withCredentials: true,
            })
            .then((res) => {
              if (res.data) {
                for (let i = 0; i < res.data.personal_id.length; i++) {
                  if (res1.data._id === res.data.personal_id[i]._id) {
                    setFavor(!favor);
                  }
                }
              }
            });
          // });
        });
    }
  }, []);

  if (!data) {
    return null;
  }

  return (
    <BackGround>
      <Nav isLoggedIn={true} />
      <Section>
        <PhotoWrapper>
          <div>
            {data.profile_picture ? (
              <img src={data.profile_picture} alt="" />
            ) : (
              <img src={person} alt="" />
            )}
            {favor === false ? (
              <StarIcon
                onClick={() => addFavorite()}
                sx={{ cursor: "pointer", color: "gray" }}
              />
            ) : (
              <StarIcon
                color="yellow"
                onClick={() => removeFavorite()}
                sx={{ cursor: "pointer" }}
              />
            )}
          </div>
          <ContactWrapper>
            <a className="call" href={`tel:${data.primary_telephone}`}>
              โทรออก
            </a>
            <a className="email" href={`mailto:${data.primary_email}`}>
              ส่งอีเมล
            </a>
          </ContactWrapper>
        </PhotoWrapper>
        <HeadDesc>
          <h2>ข้อมูลพื้นฐาน</h2>
          <p className="focus">
            ชื่อ : {data.prefix_th} {data.first_name_th} {data.last_name_th}
          </p>
          <p className="focus">เบอร์โทรศัพท์ : {data.primary_telephone}</p>
          <p>เบอร์โทรศัพท์ 2 : {data.secondary_telephone}</p>
          <p>อีเมล : {data.primary_email}</p>
          <p>อีเมล 2 : {data.secondary_email}</p>
        </HeadDesc>
        <DepartmentDetail>
          <h2>ข้อมูลหน่วยงาน</h2>
          <p>สำนัก/กอง/จังหวัด : {data.department}</p>
          <p>กลุ่มงาน/ฝ่าย/อำเภอ : {data.minister}</p>
          <p>ระดับ : {data.level}</p>
          <p>ตำแหน่ง : {data.position}</p>
          <p>เบอร์ติดต่อภายใน : {data.internal_contact_number}</p>
        </DepartmentDetail>
        <EditDetail>
          <p className="edit">
            วันที่แก้ไขล่าสุด :{" "}
            {new Date(data.updated_at).toLocaleString("th-TH", {
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              second: "numeric",
              timeZone: "GMT",
            })}
          </p>
          {isAdmin ? (
            <p className="edit">ผู้แก้ไข : {data.updated_by} </p>
          ) : null}
        </EditDetail>
      </Section>
    </BackGround>
  );
};

const PhotoWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifySelf: "flex-end",
  position: "relative",
  height: "300px",
  div: {
    height: "300px",
    width: "300px",
  },
  img: {
    borderRadius: "5px 5px 0px 0px",
    width: "100%",
    height: "300px",
    objectFit: "fill",
  },
  ".MuiSvgIcon-root": {
    fontSize: 48,
    position: "absolute",
    left: "10px",
    top: "10px",
  },
  "@media (max-width: 1000px)": {
    gridColumn: "1",
  },
  "@media(max-width:800px)": {
    height: "350px",
    div: {
      height: "300px",
    },
    img: {
      height: "300px",
    },
  },
  "@media(max-width:650px)": {
    justifySelf: "center",
    height: "300px",
    div: {
      height: "250px",
      width: "250px",
    },
    img: {
      height: "250px",
      width: "250px",
    },
  },
});
const ContactWrapper = styled("div")({
  display: "flex",
  ".email": {
    background: "#203B9B",
    borderRadius: "0px 0px 5px 0px",
  },
  ".call": {
    background: "#20B22F",
    borderRadius: "0px 0px 0px 5px",
  },
  a: {
    width: "100%",
    textAlign: "center",
    height: "50px",
    zIndex: "3",
    paddingTop: "10px",
    color: "var(--white)",
    textDecoration: "none",
  },
  "@media(max-width:400px)": {
    a: {
      height: "50px",
    },
  },
});
const DepartmentDetail = styled("div")({
  lineHeight: "300%",
  display: "flex",
  flexDirection: "column",
  gridColumn: "2",
  h2: {
    letterSpacing: 4,
  },
  "@media (max-width: 800px)": {
    height: "350px",
    h3: {
      fontSize: "1.5rem",
      marginBottom: "0rem",
    },
    p: {
      fontSize: "1rem",
    },
  },
  "@media (max-width: 650px)": {
    gridColumn: "1",
    textAlign: "center",
  },
});

const EditDetail = styled("div")({
  display: "flex",
  flexDirection: "column",
  gridColumn: "2",
  ".edit": {
    fontSize: 16,
  },
  "@media (max-width: 800px)": {
    height: "350px",
    h3: {
      fontSize: "1.5rem",
      marginBottom: "0rem",
    },
    p: {
      fontSize: "1rem",
    },
  },
  "@media (max-width: 650px)": {
    gridColumn: "1",
    textAlign: "center",
  },
});

const HeadDesc = styled("div")({
  lineHeight: "300%",
  display: "flex",
  flexDirection: "column",
  h2: {
    letterSpacing: 4,
  },
  ".focus": {
    fontSize: 24,
  },
  "@media (max-width: 800px)": {
    height: "350px",
    h3: {
      fontSize: "1.5rem",
      marginBottom: "0rem",
    },
    p: {
      fontSize: "1rem",
    },
  },
  "@media (max-width: 650px)": {
    gridColumn: "1",
    textAlign: "center",
  },
});

const Desc = styled("div")({});

const Section = styled("div")({
  padding: "3rem 3rem",
  display: "grid",
  gridTemplateColumns: "repeat(2,1fr)",
  columnGap: "6rem",
  rowGap: "1rem",
  "@media (max-width: 1000px)": {
    gridTemplateColumns: "repeat(2,1fr)",
  },
  "@media (max-width: 850px)": {
    padding: "3rem 1rem",
  },
  "@media (max-width: 650px)": {
    gridTemplateColumns: "repeat(1,1fr)",
    rowGap: "1rem",
  },
});

const BackGround = styled("div")`
  min-height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${imgSrc});
  background-repeat: no-repeat;
  background-size: cover;
  color: var(--white);
`;
