import { Route, Routes, useParams } from "react-router-dom";
import "./App.css";
import React from "react";
import GlobalStyle from "./components/GlobalStyle";
import { Login } from "./pages/Login";
import { Register } from "./pages/Register";
import { RegisterInfo } from "./pages/RegisterInfo";
import { Home } from "./pages/Home";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Favorite } from "./pages/Favorite";
import { PersonalData } from "./pages/PersonalData";
import { EditPersonalData } from "./pages/EditPersonalData";
import { DepartmentDetail } from "./pages/DepartmentDetail";
import { StaffEditData } from "./pages/StaffEditData";
import { StaffPermissions } from "./pages/StaffPermissions";
import { StaffDataMenu } from "./pages/StaffDataMenu";
import { StaffDeleteData } from "./pages/StaffDeleteData";
import { PersonPermissions } from "./pages/PersonPermissions";
import { EditDepartmentDetail } from "./pages/EditDepartmentDetail";
import { SelectType } from "./pages/SelectType";
import { AddPerson } from "./pages/AddPerson";
import { AddDepartment } from "./pages/AddDepartment";
import { Search } from "./pages/Search";
import { PersonalDetail } from "./pages/PersonalDetail";
import { DeleteByUpload } from "./pages/DeleteByUpload";
import { SelfEdit } from "./pages/SelfEdit";
import { ResetPassword } from "./pages/ResetPassword";
import { ChangePassword } from "./pages/ChangePassword";
import { useEffect, useState } from "react";
import axios from "axios";
import { AuthProvider } from "./context/AuthProvider";

export const theme = createTheme({
  palette: {
    b: {
      main: "#203b9b",
      dark: "#001171",
      contrastText: "#e3e3e3",
    },
    yellow: {
      main: "#E99F10",
    },
    w: {
      main: "#E3E3E3",
      dark: "#808080",
      contrastText: "#202020",
    },
    red: {
      main: "#B92121",
      dark: "#A41D1D",
    },
    gray: {
      main: "#202020",
      dark: "#161616",
    },
    lg: {
      main: "#424242",
      dark: "#202020",
    },
  },
  typography: {
    fontFamily: "Mitr",
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "1.5rem",
        },
      },
    },
  },
});

const App = () => {
  const baseURL = process.env.REACT_APP_URL;
  const user = process.env.REACT_APP_USERNAME_FRONT;
  const pwd = process.env.REACT_APP_PASSWORD_FRONT;
  const [token, setToken] = useState("");

  // useEffect(() => {
  //   axios
  //     .post(
  //       baseURL + "/user/login/frontend",
  //       {
  //         apple_: user,
  //         almond_: pwd,
  //       },
  //       { withCredentials: true }
  //     )
  //     .then((res) => {
  //       setToken(res.data);
  //     });
  // }, []);

  return (
    <AuthProvider>
      <div className="App">
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <Routes>
            <Route path="/" element={<Login token={token} />} />
            <Route path="/register" element={<Register token={token} />} />
            <Route
              path="/register-info/:userId"
              element={<RegisterInfo token={token} />}
            />
            <Route path="/home" element={<Home token={token} />} />
            <Route path="/search" element={<Search token={token} />} />
            <Route path="/favorite" element={<Favorite token={token} />} />
            <Route
              path="/personal-data"
              element={<PersonalData token={token} />}
            />
            <Route
              path="/edit-personal-data/:id"
              element={<EditPersonalData token={token} />}
            />
            <Route
              path="/edit-my-detail/:id"
              element={<SelfEdit token={token} />}
            />
            <Route
              path="/department/detail/:id"
              element={<DepartmentDetail token={token} />}
            />
            <Route
              path="/personal/detail/:id"
              element={<PersonalDetail token={token} />}
            />
            <Route
              path="/staff-data-menu"
              element={<StaffDataMenu token={token} />}
            />
            <Route
              path="/staff-edit-data"
              element={<StaffEditData token={token} />}
            />
            <Route path="/select-type" element={<SelectType token={token} />} />
            <Route
              path="/person-permissons/:id"
              element={<PersonPermissions token={token} />}
            />
            <Route path="/add-person" element={<AddPerson token={token} />} />
            <Route
              path="/add-department"
              element={<AddDepartment token={token} />}
            />
            <Route
              path="/staff-manage-permissons"
              element={<StaffPermissions token={token} />}
            />
            <Route
              path="/edit-department-detail/:id"
              element={<EditDepartmentDetail token={token} />}
            />
            <Route
              path="/delete-by-upload"
              element={<DeleteByUpload token={token} />}
            />
            <Route
              path="/reset-password"
              element={<ResetPassword token={token} />}
            />
            <Route
              path="/change-password/:userId"
              element={<ChangePassword token={token} />}
            />
          </Routes>
        </ThemeProvider>
      </div>
    </AuthProvider>
  );
};

export default App;
