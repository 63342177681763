import React, { useState } from "react";
import styled from "styled-components";
import imgSrc from "../image/bg.jpg";
import { Link, useNavigate } from "react-router-dom";
import { Button, TextField } from "@mui/material";
import axios from "axios";
import { DocumentScanner } from "@mui/icons-material";

export const Register = () => {
  let navigate = useNavigate();
  // const baseURL = 'process.env.REACT_APP_URL';
  const baseURL = process.env.REACT_APP_URL;
  const [tel, setTel] = useState("");
  const [otp, setOtp] = useState("");
  const [isOtpError, setIsOtpError] = useState(false);
  const [isTelError, setIsTelError] = useState(false);
  const [data, setData] = useState(null);
  const [btnText, setBtnText] = useState("ขอ OTP");
  const [disable, setDisable] = useState(false);
  const [sec, setSec] = useState(null);
  const [invalidTel, setInvalidTel] = useState(false);
  const [exist, setExist] = useState(false);
  const [error, setError] = useState(false);
  const [isOver, setIsOver] = useState(false);
  const [credit, setCredit] = useState(false);
  const [userId, setUserId] = useState("");

  const handleTelInput = (e) => {
    setTel(e.target.value);
  };

  const handleOtpInput = (e) => {
    setOtp(e.target.value);
  };

  const otpWait = () => {
    setSec(60);
    let i = 60;
    let countDown = setInterval(() => {
      i--;
      setSec(i);
    }, 1000);

    setTimeout(() => {
      setDisable(false);
      setBtnText("ขอ OTP");
      setSec(null);
      clearInterval(countDown);
    }, 60000);
  };

  const reqOtp = () => {
    setIsTelError(false);
    setInvalidTel(false);
    setExist(false);
    setError(false);
    if (tel.length !== 10) {
      setInvalidTel(true);
      return;
    } else {
      axios
        .post(baseURL + "/user/checktelephone", { username: tel })
        .then((res) => {
          if (res.data.message === "Not Found") {
            setIsTelError(true);
          } else if (res.data.message === "Exist") {
            setExist(true);
          } else {
            axios
              .post(baseURL + "/user/OTP", { telephone: tel })
              .then((res) => {
                console.log(res);
                if (res.data && res.data.startsWith("STATUS=504")) {
                  setCredit(true);
                  return;
                } else {
                  setBtnText("ส่งแล้ว");
                  setDisable(true);
                  otpWait();
                }
                //console.log(res);
              })
              .catch((err) => {
                if (err.response.data.startsWith("STATUS=504")) {
                  setCredit(true);
                } else if (err.response.data.startsWith("คำขอ")) {
                  setIsOver(true);
                } else {
                  setError(true);
                }
              });
          }
        });
    }
  };

  const verifyOtp = () => {
    axios
      .post(baseURL + "/user/verify", { telephone: tel, otp: otp })
      .then((res) => {
        let token = { token: res.data.access };
        localStorage.setItem("user", JSON.stringify(token));
        navigate(`/register-info/${res.data.existedMember._id}`);
      })
      .catch((err) => {
        setIsOtpError(true);
      });
  };

  return (
    <RegisterStyle>
      <LogoText>
        <Dopa>DOPA</Dopa>
        <br />
        DIRECTORY
      </LogoText>
      <FormWrapperStyle>
        <TextStyle>
          <h2>
            สมัคร
            <br className="br" />
            สมาชิก
          </h2>
          <Link to="/">
            <LinkText>
              เป็นสมาชิกแล้ว? <br /> <b>เข้าสู่ระบบ</b>
            </LinkText>
          </Link>
        </TextStyle>
        <OtpWrapper>
          <div>
            <CssTextField
              label="เบอร์โทรศัพท์"
              variant="filled"
              color="w"
              type="text"
              onChange={handleTelInput}
              error={isTelError}
              helperText={
                isTelError
                  ? "ขออภัยข้อมูลของท่านยังไม่ได้รับการปรับปรุงหรืออนุญาตให้สามารถเข้าใช้บริการของ DOPA DIRECTORY หากมีข้อสงสัยในการใช้งานหรือต้องการข้อมูลเงื่อนไขการให้บริการเพิ่มเติมโปรดติดต่อ กองการสื่อสาร กรมการปกครอง หมายเลข Call center 0639042999, 0639043000 email  Dopalte.official@gmail.com"
                  : invalidTel
                  ? "กรุณากรอกเลขหมายให้ถูกต้อง"
                  : exist
                  ? "เลขหมายนี้เป็นสมาชิกอยู่แล้ว"
                  : error
                  ? "กรุณาลองใหม่อีกครั้ง"
                  : isOver
                  ? "คำขอ OTP ของคุณถูกส่งมาเกิน 5 ครั้ง กรุณาลองใหม่อีกครั้ง 24 ชั่วโมงถัดไป"
                  : credit
                  ? "Error 999 โปรดติดต่อ กองการสื่อสาร กรมการปกครอง หมายเลข Call center 0639042999,0639043000 email  Dopalte.official@gmail.com"
                  : null
              }
              inputProps={{ maxLength: 10 }}
            />
            <Button
              color="lg"
              variant="contained"
              sx={{
                fontSize: 16,
                borderRadius: "0px 5px 5px 0px",
                border: "0px solid #202020",
                height: "3.5rem",
                width: "30%",
                "&.Mui-disabled": {
                  color: "rgba(256,256,256,0.5)",
                  background: "rgba(0,0,0,0.5)",
                },
                "@media(max-width: 390px)": {
                  width: "40%",
                },
              }}
              disabled={disable}
              onClick={reqOtp}
            >
              {btnText} {sec !== null ? `(${sec})` : null}
            </Button>
          </div>
        </OtpWrapper>
        <CssTextField
          label="OTP"
          variant="filled"
          color="w"
          type="text"
          onChange={handleOtpInput}
          error={isOtpError}
          helperText={isOtpError ? "Invalid OTP" : null}
          inputProps={{ maxLength: 6 }}
        />
        <Button variant="contained" color="b" onClick={() => verifyOtp()}>
          สมัครสมาชิก
        </Button>
      </FormWrapperStyle>
    </RegisterStyle>
  );
};

const OtpWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    width: 100%;
    display: flex;
  }
`;
const CssTextField = styled(TextField)({
  ".MuiFilledInput-root": {
    backgroundColor: "#e0e0e0",
    transitionDuration: "0ms",
    borderTopRightRadius: 0,
  },
  ".MuiFilledInput-root.Mui-focused": {
    backgroundColor: "#e0e0e0",
  },
  ".MuiInputLabel-root": {
    color: "#203b9b",
  },
  "label.Mui-focused": {
    color: "#203b9b",
  },
  ".MuiFilledInput-underline:after": {
    borderBottom: "3px solid #203b9b",
  },
  ".MuiFilledInput-underline:before": {
    borderBottomColor: "#202020",
  },
  ".MuiFilledInput-root:hover": {
    backgroundColor: "#e3e3e3",
    borderTopRightRadius: 0,
    borderTopLeftRadius: 5,
  },
  "p.MuiFormHelperText-root": {
    background: "transparent",
    fontSize: "15px",
    color: "#e3e3e3 ",
  },
});

const LinkText = styled.p`
  &:hover {
    text-decoration: underline;
  }
  @media (max-width: 1350px) {
    & {
      font-size: 1rem;
    }
  }
  @media (max-width: 380px) {
    & {
      font-size: 0.8rem;
    }
  }
`;

const TextStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 2rem;
  .br {
    display: none;
  }
  a {
    text-align: right;
    color: #e3e3e3;
    text-decoration: none;
  }
  @media (max-width: 380px) {
    .br {
    }
  }
`;
const FormWrapperStyle = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0rem 1rem;
  row-gap: 1.5rem;
  @media (max-width: 1250px) {
    h2 {
      font-size: 1.5rem;
    }
  }
  @media (max-width: 850px) {
    align-self: start;
  }
  @media (max-width: 500px) {
    padding: 0rem 0rem;
  }
`;

const RegisterStyle = styled.div`
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${imgSrc});
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0 5rem;
  color: white;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  @media (max-width: 1250px) {
    padding: 0 3rem;
  }
  @media (max-width: 950px) {
    padding: 0 1rem;
  }
  @media (max-width: 850px) {
    grid-template-columns: 1fr;
  }
`;

const LogoText = styled.h1`
  font-size: 5rem;
  font-family: "Cormorant Garamond", sans-serif;
  font-weight: 500;
  text-shadow: 0px 0px 10px black;
  text-align: center;
  letter-spacing: 1rem;
  @media (max-width: 1250px) {
    font-size: 2.5rem;
  }
  @media (max-width: 400px) {
    font-size: 1.5rem;
  }
`;

const Dopa = styled.span`
  font-size: 10rem;
  font-family: "Cormorant Garamond", sans-serif;
  font-weight: 500;
  text-shadow: 0px 0px 10px black;
  @media (max-width: 1250px) {
    font-size: 5rem;
  }
  @media (max-width: 400px) {
    font-size: 3rem;
  }
`;
