import React, { useState, useContext } from "react";
import { styled } from "@mui/material";
import { Link } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import AuthContext from "../context/AuthProvider";

export const Nav = (props) => {
  // const isLoggedIn = props.isLoggedIn;
  const { auth } = useContext(AuthContext);
  const isStaff = props.isStaff;
  const isRegister = props.isRegister;
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));

  const signOut = () => {
    localStorage.clear();
    setIsLoggedIn(false);
    navigate("/");
  };

  useEffect(() => {
    if (user) {
      setIsLoggedIn(true);
      if (user.role === "admin") {
        setIsAdmin(true);
      }
    }
  }, []);

  return (
    <>
      {isLoggedIn ? (
        <>
          <NavBar>
            <Logo
              onClick={() => {
                if (isStaff) {
                  navigate("/staff-data-menu");
                } else {
                  navigate("/home");
                }
              }}
            >
              DOPA <br />
              DIRECTORY
            </Logo>
            <NavLinkMobile>
              <MenuIcon onClick={() => setIsOpen(!isOpen)} />
            </NavLinkMobile>
            {isOpen && isStaff ? (
              <>
                <Backdrop className="backdrop"></Backdrop>
                <MobileMenu>
                  <div onClick={() => navigate("/staff-data-menu")}>
                    จัดการข้อมูล
                  </div>
                  <div onClick={() => navigate("/staff-manage-permissons")}>
                    จัดการสิทธิ์แอดมิน
                  </div>
                  <div onClick={() => navigate("/home")}>
                    เปลี่ยนเป็นผู้ใช้งาน
                  </div>
                  <div onClick={signOut}>ออกจากระบบ</div>
                </MobileMenu>
              </>
            ) : isOpen && !isStaff ? (
              <div>
                <Backdrop className="backdrop"></Backdrop>
                <MobileMenu>
                  <div onClick={() => navigate("/home")}>ค้นหา</div>
                  {/* <div>ข้อมูลทั้งหมด</div> */}
                  <div onClick={() => navigate("/favorite")}>รายการโปรด</div>
                  <div onClick={() => navigate("/personal-data")}>
                    ข้อมูลส่วนตัว
                  </div>
                  {isAdmin ? (
                    <div
                      onClick={() => {
                        navigate("/staff-data-menu");
                      }}
                    >
                      เปลี่ยนเป็นแอดมิน
                    </div>
                  ) : null}
                  <div onClick={signOut}>ออกจากระบบ</div>
                </MobileMenu>
              </div>
            ) : (
              <></>
            )}
            <NavLink>
              {isStaff ? (
                <>
                  <Link to="/staff-data-menu" className="a">
                    <li>จัดการข้อมูล</li>
                  </Link>
                  <Link to="/staff-manage-permissons" className="a">
                    <li>จัดการสิทธิ์แอดมิน</li>
                  </Link>
                </>
              ) : (
                isRegister != true && (
                  <>
                    <Link to="/home" className="a">
                      <li>ค้นหา</li>
                    </Link>

                    <Link to="/favorite" className="a">
                      <li>รายการโปรด</li>
                    </Link>
                  </>
                )
              )}
              {isRegister != true && (
                <StyledIconButton className="acc">
                  <AccountCircleIcon sx={{ fontSize: 48 }} />
                  <ArrowDropDownIcon sx={{ fontSize: 48 }} />
                  {isAdmin && isStaff ? (
                    <AccountMenu
                      style={{ width: "19rem" }}
                      className="account-menu"
                    >
                      <div onClick={() => navigate("/personal-data")}>
                        <div>ข้อมูลส่วนตัว</div>
                      </div>
                      {isAdmin && isStaff ? (
                        <div
                          onClick={() => {
                            navigate("/home");
                          }}
                        >
                          <div>เปลี่ยนเป็นผู้ใช้งาน</div>
                        </div>
                      ) : null}
                      <div
                        onClick={() => {
                          signOut();
                          navigate("/");
                        }}
                      >
                        <div>ออกจากระบบ</div>
                      </div>
                    </AccountMenu>
                  ) : (
                    <AccountMenu className="account-menu">
                      <div onClick={() => navigate("/personal-data")}>
                        <div>ข้อมูลส่วนตัว</div>
                      </div>
                      {isAdmin ? (
                        <div
                          onClick={() => {
                            navigate("/staff-data-menu");
                          }}
                        >
                          <div>เปลี่ยนเป็นแอดมิน</div>
                        </div>
                      ) : null}
                      <div
                        onClick={() => {
                          signOut();
                          navigate("/");
                        }}
                      >
                        <div>ออกจากระบบ</div>
                      </div>
                    </AccountMenu>
                  )}
                </StyledIconButton>
              )}
            </NavLink>
          </NavBar>
        </>
      ) : (
        <NavBar
          onClick={() => {
            if (isRegister) {
              navigate("/");
            }
          }}
        >
          <Logo>DOPA DIRECTORY</Logo>
        </NavBar>
      )}
    </>
  );
};

const MobileMenu = styled("div")({
  zIndex: 5,
  position: "absolute",
  display: "none",
  top: "10vh",
  left: 0,
  width: "100%",
  height: "90vh",
  fontSize: "2rem",
  div: {
    backgroundColor: "var(--white)",
    textAlign: "center",
    padding: "2rem",
    color: "var(--black)",
    fontSize: "1.5rem",
    "&:hover": {
      backgroundColor: "var(--black)",
      color: "var(--white)",
    },
  },

  "@media (max-width: 800px)": {
    display: "flex",
    flexDirection: "column",
  },
});

const Backdrop = styled("div")({
  position: "fixed",
  background: "rgba(0,0,0,0.4)",
  top: "10vh",
  left: 0,
  width: "100%",
  height: "90vh",
});

const StyledIconButton = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 20%;
  &:hover {
    color: var(--black);
    background-color: var(--white);
  }
  &:hover .account-menu {
    display: block;
  }
  a {
    color: var(--black);
    text-decoration: none;
  }
`;

const AccountMenu = styled("div")`
  top: 10vh;
  right: 0rem;
  width: 304px;
  position: absolute;
  color: var(--black);
  text-align: center;
  background-color: var(--white);
  font-size: 1.5rem;
  display: flex;
  flex-direction: column;
  border-radius: 0px 0px 3px 3px;
  border-top: solid var(--black) 1px;
  display: none;
  z-index: 2;
  div {
    padding: 0.5rem;
    cursor: pointer;
    width: 100%;
    height: 100%;
  }
  div:hover {
    background-color: var(--black);
    color: var(--white);
  }
  @media (max-width: 900px) {
    right: 0rem;
  }
`;

const NavLinkMobile = styled("div")({
  display: "none",
  ".MuiSvgIcon-root": {
    fontSize: 48,
    color: "var(--white)",
  },
  "@media (max-width: 800px)": {
    display: "flex",
  },
  paddingRight: "2rem",
});

const NavLink = styled("ul")`
  display: flex;
  font-size: 1.5rem;
  align-items: center;
  flex: 3;
  justify-content: space-evenly;
  color: var(--white);
  height: 100%;
  li {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    list-style: none;
    height: 10vh;
    cursor: pointer;
    z-index: 3;
  }
  .a {
    cursor: pointer;
    text-align: center;
    color: var(--white);
    justify-self: center;
    align-self: center;
    text-decoration: none;
    flex: 1;
    width: 100%;
  }
  li:hover {
    background-color: var(--white);
    color: var(--black);
  }
  li:hover .a {
    background-color: var(--white);
    color: var(--black);
  }
  .a:hover {
    color: var(--black);
  }
  @media (max-width: 1000px) {
    li {
      font-size: 1rem;
    }
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
const NavBar = styled("div")`
  height: 10vh;
  background: transparent;
  padding: 0 0rem 0 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  br {
    display: none;
  }
  @media (max-width: 600px) {
    br {
      display: block;
    }
    padding: 0 1rem;
  }
  @media (max-width: 900px) {
    br {
      display: block;
    }
    padding: 0 0rem 0 1rem;
  }
`;

const Logo = styled("h1")`
  font-size: 2rem;
  font-family: "Cormorant Garamond", sans-serif;
  font-weight: bold;
  text-shadow: 0px 0px 2px white;
  flex: 2;
  letter-spacing: 0.5rem;
  color: var(--white);
  cursor: pointer;
  @media (max-width: 500px) {
    font-size: 1rem;
  }
`;
