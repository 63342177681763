import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import imgSrc from "../image/bg.jpg";
import { Button, TextField } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import AuthContext from "../context/AuthProvider";

export const Login = ({ token }) => {
  let navigate = useNavigate();
  const { auth, setAuth } = useContext(AuthContext);
  // const baseURL = 'process.env.REACT_APP_URL';
  const baseURL = process.env.REACT_APP_URL;
  const [error, setError] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [body, setBody] = useState({
    username: "",
    password: "",
  });
  const [invalidTel, setInvalidTel] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));

  const handleInput = (e) => {
    setBody({ ...body, [e.target.name]: e.target.value });
  };

  const login = (event) => {
    setInvalidTel(false);
    setError(false);
    event.preventDefault();
    if (body.username.length !== 10) {
      setInvalidTel(true);
      return;
    }
    let formData = new FormData(event.currentTarget);
    let user = formData.get("username").toString();
    let pwd = formData.get("password").toString();
    axios
      .post(
        baseURL + "/user/login",
        { username: user, password: pwd },
        {
          //withCredentials: true,
        }
      )
      .then((res) => {
        localStorage.setItem("user", JSON.stringify(res.data));
        // const accessToken = res.data.token;
        // const user_id = res.data.user_id;
        // setAuth({ accessToken, user_id });
        navigate("/home");
      })
      .catch((err) => {
        try {
          let mes = err.response.data.error;
          setErrMessage(mes);
          setError(true);
        } catch (errors) {
          let mes = "ไม่สามารถเชื่อมต่อเซิฟเวอร์ได้";
          setErrMessage(mes);
          setError(true);
        }
      });
  };

  useEffect(() => {
    console.log(auth);
  }, []);

  return (
    <LoginStyle>
      <LogoText>
        <Dopa>DOPA</Dopa>
        <br />
        DIRECTORY
        <BtnWrapper>
          <Link
            to="/Dopa Directory.apk"
            target="_blank"
            download="DOPA Directory.apk"
          >
            <DownloadButton>Download for Android</DownloadButton>
          </Link>
          {/* <Link
            to="/DOPA-Application.apk"
            target="_blank"
            download="DOPA-Application"
          >
            <DownloadButton>Download for iOS</DownloadButton>
          </Link> */}
        </BtnWrapper>
      </LogoText>

      <form onSubmit={login}>
        <FormWrapperStyle>
          <TextStyle>
            <h2>เข้าสู่ระบบ</h2>
            <Link to="/register">
              <LinkText>
                ยังไม่เป็นสมาชิก? <br /> <b>สมัครสมาชิก</b>
              </LinkText>
            </Link>
          </TextStyle>
          <CssTextField
            label="เบอร์โทรศัพท์"
            variant="filled"
            color="w"
            type="text"
            name="username"
            error={invalidTel}
            helperText={invalidTel ? "กรุณากรอกเลขหมายให้ถูกต้อง" : null}
            onChange={handleInput}
            inputProps={{ maxLength: 10 }}
          />
          <CssTextField
            label="รหัสผ่าน"
            variant="filled"
            color="w"
            onChange={handleInput}
            error={error}
            helperText={error ? errMessage : null}
            name="password"
            type="password"
          />
          <Link className="forgot" to="/reset-password">
            ลืมรหัสผ่าน
          </Link>
          <Button type="submit" color="b" variant="contained">
            เข้าสู่ระบบ
          </Button>
          <ManualWrapper>
            <Link
              style={{ color: "#e3e3e3", fontSize: 14 }}
              to="/usermanual.pdf"
              target="_blank"
            >
              คู่มือสำหรับผู้ใช้
            </Link>
            <p style={{ fontSize: 12 }}>Version 1.0.3</p>
          </ManualWrapper>
          <BtnWrapperMobile>
            <Link
              className="download-mb"
              to="/Dopa Directory.apk"
              target="_blank"
              download="Dopa Directory.apk"
            >
              Download for Android
            </Link>
          </BtnWrapperMobile>
        </FormWrapperStyle>
      </form>
    </LoginStyle>
  );
};

const ManualWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  row-gap: 1rem;
`;

const BtnWrapper = styled.div`
  width: 100%;
  margin-top: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 2rem;
  @media (max-width: 800px) {
    display: none;
  }
`;

const BtnWrapperMobile = styled.div`
  width: 100%;
  margin-top: 2rem;
  display: none;
  justify-content: center;
  .download-mb {
    background-color: #202020;
    height: 50px;
    width: 230px;
    border-radius: 4px;
    color: #e3e3e3;
    text-decoration: none;
    text-align: center;
    padding-top: 10px;
    font-weight: normal;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
  }
  @media (max-width: 800px) {
    display: flex;
  }
`;

const DownloadButton = styled.button`
  background: #202020;
  width: 200px;
  height: 50px;
  padding: 0.5rem;
  color: #e3e3e3;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.7);
`;

const CssTextField = styled(TextField)({
  ".MuiFilledInput-root": {
    backgroundColor: "#e0e0e0",
    transitionDuration: "0ms",
    borderTopRightRadius: 5,
  },
  ".MuiFilledInput-root.Mui-focused": {
    backgroundColor: "#e0e0e0",
  },
  ".MuiInputLabel-root": {
    color: "#203b9b",
  },
  "label.Mui-focused": {
    color: "#203b9b",
  },
  ".MuiFilledInput-underline:after": {
    borderBottom: "3px solid #203b9b",
  },
  ".MuiFilledInput-underline:before": {
    borderBottomColor: "#202020",
  },
  ".MuiFilledInput-root:hover": {
    backgroundColor: "#e3e3e3",
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
  },
  "p.MuiFormHelperText-root": {
    background: "transparent",
    fontSize: "15px",
    color: "#e3e3e3",
  },
});

// const CssTextField = styled(TextField)({
//   "& label.Mui-focused": {
//     color: "white",
//   },
//   "& .MuiInput-underline:after": {
//     borderBottomColor: "white",
//   },
//   "& .MuiOutlinedInput-root": {
//     "& fieldset": {
//       borderColor: "white",
//     },
//     "&:hover fieldset": {
//       borderColor: "white",
//     },
//     "&.Mui-focused fieldset": {
//       borderColor: "white",
//     },
//     input: {
//       color: "#e3e3e3",
//     },
//   },
// });

const LinkText = styled.p`
  &:hover {
    text-decoration: underline;
  }
  @media (max-width: 1350px) {
    & {
      font-size: 1rem;
    }
  }
`;

const TextStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 2rem;
  a {
    text-align: right;
    text-decoration: none;
    color: #e3e3e3;
  }
`;
const FormWrapperStyle = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0rem 5rem;
  row-gap: 1.5rem;
  .forgot {
    font-size: 1rem;
    color: var(--white);
    text-decoration: none;
    width: 80px;
    align-self: flex-end;
    cursor: pointer;
  }
  @media (max-width: 1250px) {
    h2 {
      font-size: 1.5rem;
    }
    padding: 0rem 2rem;
  }
  @media (max-width: 850px) {
    align-self: start;
    padding: 0rem 5rem;
  }
  @media (max-width: 500px) {
    padding: 0rem 0rem;
  }
`;

const LoginStyle = styled.div`
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${imgSrc});
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0 5rem;
  color: white;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  @media (max-width: 1250px) {
    padding: 0 3rem;
  }
  @media (max-width: 950px) {
    padding: 0 1rem;
  }
  @media (max-width: 850px) {
    grid-template-columns: 1fr;
  }
`;

const LogoText = styled.h1`
  font-size: 5rem;
  font-family: "Cormorant Garamond", sans-serif;
  font-weight: 500;
  text-shadow: 0px 0px 10px black;
  text-align: center;
  letter-spacing: 1rem;
  @media (max-width: 1250px) {
    font-size: 2.5rem;
  }
  @media (max-width: 400px) {
    font-size: 1.5rem;
  }
`;

const Dopa = styled.span`
  font-size: 10rem;
  font-family: "Cormorant Garamond", sans-serif;
  font-weight: 500;
  text-shadow: 0px 0px 10px black;
  @media (max-width: 1250px) {
    font-size: 5rem;
  }
  @media (max-width: 400px) {
    font-size: 3rem;
  }
`;
