import React from "react";
import { styled } from "@mui/material";
import imgSrc from "../image/bg.jpg";
import { SearchBar } from "../components/SearchBar";
import { Nav } from "../components/Nav";

export const StaffDeleteData = () => {
  return (
    <>
      <BackGround>
        <Nav isStaff={true} isLoggedIn={true} />
        <SearchBar name={"ลบข้อมูล"} isStaff={true}/>
      </BackGround>
    </>
  );
};

const BackGround = styled("div")`
  min-height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${imgSrc});
  background-repeat: no-repeat;
  background-size: cover;
  color: var(--white);
`;
