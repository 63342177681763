import React, { useState, useEffect } from "react";
import { styled, FormGroup, Checkbox, Button } from "@mui/material";
import imgSrc from "../image/bg.jpg";
import { Nav } from "../components/Nav";
import { examplePersonData } from "../Db";
import { useParams } from "react-router-dom";
import axios from "axios";
import FormControlLabel from "@mui/material/FormControlLabel";
import noImage from "../image/no-image.jpg";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { useNavigate } from "react-router-dom";

const ConfirmDialog = (props) => {
  const { onClose, dialogValue, open, updateData } = props;
  const navigate = useNavigate();

  return (
    <Dialog open={open} maxWidth={"xs"} fullWidth={true}>
      <DialogTitle sx={{ textAlign: "center", fontSize: 24 }}>
        {dialogValue}
      </DialogTitle>
      <DialogContent>
        <DialogContentText></DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          onClick={() => {
            window.location.reload();
          }}
          sx={{ color: "#e3e3e3", fontSize: "1rem", width: "70%" }}
          variant="contained"
          color="b"
        >
          ต่อไป
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export const PersonPermissions = () => {
  const baseURL = process.env.REACT_APP_URL;
  let { id } = useParams();
  const [value, setValue] = useState("");
  const [data, setData] = useState(null);
  const [feature, setFeature] = useState({
    user: "",
    import: false,
    delete: false,
    update: false,
    export: false,
    assign: false,
  });
  const [open, setOpen] = useState(false);
  const [dialogValue, setDialogValue] = useState("");
  const user = JSON.parse(localStorage.getItem("user"));
  let navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    let isLogIn = JSON.parse(localStorage.getItem("user"));
    if (isLogIn == null) {
      navigate(`/`);
    } else if (isLogIn.role !== "admin") {
      navigate("/home");
    } else {
      axios
        .get(baseURL + "/personal/" + id, {
          headers: {
            Authorization: `bearer ${user.token}`,
          },
          //withCredentials: true,
        })
        .then((res1) => {
          setData(res1.data);
          axios
            .get(baseURL + "/feature/" + res1.data.user_id, {
              headers: {
                Authorization: `bearer ${user.token}`,
              },
              //withCredentials: true,
            })
            .then((res) => {
              setFeature({
                user: res1.data.user_id,
                import: res.data.import,
                delete: res.data.delete,
                update: res.data.update,
                export: res.data.export,
                assign: res.data.assign,
              });
            });
        });
    }
  }, []);

  const handleCheck = (e) => {
    setFeature({ ...feature, [e.target.name]: e.target.checked });
    //console.log(e.target.checked)
  };

  const onSubmit = () => {
    if (
      feature.import == false &&
      feature.delete == false &&
      feature.update == false &&
      feature.export == false &&
      feature.assign == false
    ) {
      axios
        .patch(
          baseURL + "/user/update",
          { user_id: data.user_id, role: "user" },
          {
            headers: {
              Authorization: `bearer ${user.token}`,
            },
            //withCredentials: true,
          }
        )
        .then((res) => {
          if ((res.status = "200")) {
            axios
              .patch(baseURL + "/feature/" + data.user_id, feature, {
                headers: {
                  Authorization: `bearer ${user.token}`,
                },
                //withCredentials: true,
              })
              .then((res) => {
                if ((res.status = "200")) {
                  setOpen(true);
                  setDialogValue("แก้ไขสำเร็จ");
                } else {
                  setOpen(true);
                  setDialogValue("แก้ไขไม่สำเร็จ โปรดลองอีกครั้ง");
                }
              });
          } else {
            setOpen(true);
            setDialogValue("แก้ไขไม่สำเร็จ โปรดลองอีกครั้ง");
          }
        });
    }
    if (
      feature.import == true ||
      feature.delete == true ||
      feature.update == true ||
      feature.export == true ||
      feature.assign == true
    ) {
      axios
        .patch(
          baseURL + "/user/update",
          { user_id: data.user_id, role: "admin" },
          {
            headers: {
              Authorization: `bearer ${user.token}`,
            },
            //withCredentials: true,
          }
        )
        .then((res) => {
          //console.log(res)
          axios
            .patch(baseURL + "/feature/" + data.user_id, feature, {
              headers: {
                Authorization: `bearer ${user.token}`,
              },
              //withCredentials: true,
            })
            .then((res) => {
              if ((res.status = "200")) {
                setOpen(true);
                setDialogValue("แก้ไขสำเร็จ");
              } else {
                setOpen(true);
                setDialogValue("แก้ไขไม่สำเร็จ โปรดลองอีกครั้ง");
              }
            });
        });
    }
  };

  return (
    <>
      <BackGround>
        <Nav isStaff={true} isLoggedIn={true} />
        <Section>
          <StaffData>
            <h2 style={{ marginBottom: 16 }}>ข้อมูลแอดมิน</h2>
            {data ? (
              <>
                {data.profile_picture ? (
                  <img src={data.profile_picture} alt="" />
                ) : (
                  <img src={noImage} alt="" />
                )}
                <h3>
                  {data.first_name_th} {data.last_name_th}
                </h3>
                <p>เบอร์โทรศัพท์ : {data.primary_telephone}</p>
                <p>อีเมล์ : {data.primary_email}</p>
                <p>สังกัด : {data.department}</p>
                <p>ตำแหน่ง : {data.position}</p>
                <p>ระดับ : {data.level}</p>
              </>
            ) : (
              <></>
            )}
          </StaffData>
          <Permissions>
            <h2>สิทธิ์</h2>
            <FormGroup
              sx={{
                "@media(max-width:500px)": {
                  display: "grid",
                  gridTemplateColumns: "repeat(2,1fr)",
                  justifyContent: "center",
                  alignItems: "center",
                },
              }}
            >
              <FormControlLabel
                sx={{
                  ".MuiFormControlLabel-label": { fontSize: 24 },
                  "@media(max-width: 800px)": {
                    ".MuiFormControlLabel-label": { fontSize: 16 },
                  },
                }}
                control={
                  <Checkbox
                    sx={{ color: "#e3e3e3" }}
                    checked={feature.import}
                    onChange={handleCheck}
                    name="import"
                    color="w"
                    size="large"
                  />
                }
                label="เพิ่มข้อมูล"
              />
              <FormControlLabel
                sx={{
                  ".MuiFormControlLabel-label": { fontSize: 24 },
                  "@media(max-width: 800px)": {
                    ".MuiFormControlLabel-label": { fontSize: 16, margin: 0 },
                  },
                }}
                control={
                  <Checkbox
                    sx={{ color: "#e3e3e3" }}
                    checked={feature.delete}
                    onChange={handleCheck}
                    name="delete"
                    color="w"
                    size="large"
                  />
                }
                label="ลบข้อมูล"
              />
              <FormControlLabel
                sx={{
                  ".MuiFormControlLabel-label": { fontSize: 24 },
                  "@media(max-width: 800px)": {
                    ".MuiFormControlLabel-label": { fontSize: 16 },
                  },
                }}
                control={
                  <Checkbox
                    sx={{ color: "#e3e3e3" }}
                    checked={feature.update}
                    onChange={handleCheck}
                    name="update"
                    color="w"
                    size="large"
                  />
                }
                label="แก้ไขข้อมูล"
              />

              <FormControlLabel
                sx={{
                  ".MuiFormControlLabel-label": { fontSize: 24 },
                  "@media(max-width: 800px)": {
                    ".MuiFormControlLabel-label": { fontSize: 16 },
                  },
                }}
                control={
                  <Checkbox
                    sx={{ color: "#e3e3e3" }}
                    checked={feature.export}
                    onChange={handleCheck}
                    name="export"
                    color="w"
                    size="large"
                  />
                }
                label="ส่งออกข้อมูล"
              />
              <FormControlLabel
                sx={{
                  ".MuiFormControlLabel-label": {
                    fontSize: 24,
                  },
                  "@media(max-width: 800px)": {
                    ".MuiFormControlLabel-label": { fontSize: 16 },
                  },
                }}
                control={
                  <Checkbox
                    sx={{ color: "#e3e3e3" }}
                    checked={feature.assign}
                    onChange={handleCheck}
                    name="assign"
                    color="w"
                    size="large"
                  />
                }
                label="กำหนดสิทธิ์แอดมิน"
              />
            </FormGroup>
            <Button variant="contained" color="b" onClick={onSubmit}>
              ยืนยัน
            </Button>
          </Permissions>
          <ConfirmDialog
            open={open}
            dialogValue={dialogValue}
            onClose={handleClose}
          />
        </Section>
      </BackGround>
    </>
  );
};
const Permissions = styled("div")({
  gridColumn: "4/6",
  ".MuiFormControlLabel-root": {
    marginBottom: "2rem",
  },
  Button: {
    width: "50%",
    marginTop: "0rem",
  },
  h2: {
    marginBottom: "2rem",
  },
  "@media (max-width: 800px)": {
    ".MuiFormControlLabel-root": {
      marginBottom: "1rem",
    },
  },
  "@media (max-width: 600px)": {
    Button: {
      width: "100%",
    },
  },
  "@media (max-width: 500px)": {
    ".MuiFormControlLabel-root": {
      marginBottom: "0rem",
    },
    gridColumn: "1",
    textAlign: "center",
    h2: {
      margin: "1rem 0rem",
    },
  },
});

const StaffData = styled("div")({
  gridColumn: "1/4",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  img: {
    height: "200px",
    width: "200px",
    objectFit: "cover",
    borderRadius: "50%",
    marginBottom: "1rem",
  },
  h3: {
    marginBottom: "1rem",
  },
  h2: {
    marginBottom: "rem",
  },
  p: {
    marginBottom: "1rem",
  },
  "@media (max-width: 600px)": {
    img: {
      height: "100px",
      width: "100px",
    },
    justifyContent: "space-between",
  },
  "@media (max-width: 500px)": {
    gridColumn: "1",
  },
});

const Section = styled("div")({
  display: "grid",
  gridTemplateColumns: "repeat(5,1fr)",
  padding: "3rem",
  minHeight: "70vh",
  "@media (max-width: 800px)": {
    h2: {
      fontSize: "1.5rem",
    },
    h3: {
      fontSize: "1.5rem",
    },
    p: {
      fontSize: "1rem",
    },
    padding: "3rem 1rem",
  },
  "@media (max-width: 500px)": {
    display: "grid",
    gridTemplateColumns: "repeat(1,1fr)",
  },
});

const BackGround = styled("div")`
  min-height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${imgSrc});
  background-repeat: no-repeat;
  background-size: cover;
  color: var(--white);
`;
