import React, { useState, useEffect } from "react";
import { styled } from "@mui/material";
import { Nav } from "../components/Nav";
import imgSrc from "../image/bg.jpg";
import personImg from "../image/no-image.jpg";
import { examplePersonData } from "../Db";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Link from "@mui/material";

export const PersonalData = () => {
  let navigate = useNavigate();
  const data = examplePersonData[0];
  const [fetchData, setFetchData] = useState({});
  const gender = examplePersonData[0].gender === 0 ? "ชาย" : "หญิง";
  // const baseURL = 'process.env.REACT_APP_URL';
  const baseURL = process.env.REACT_APP_URL;
  const user = JSON.parse(localStorage.getItem("user"));
  const [id, setId] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    let isLogIn = JSON.parse(localStorage.getItem("user"));
    // console.log(isLogIn)
    if (isLogIn.role == "admin") {
      setIsAdmin(true);
    }
    if (isLogIn == null) {
      navigate(`/`);
    } else {
      axios
        .get(baseURL + "/personal/user/" + user.user_id, {
          headers: {
            Authorization: `bearer ${user.token}`,
          },
          //withCredentials: true,
        })
        .then((res) => {
          //console.log(res.data._id);
          setFetchData(res.data);
          setId(res.data._id);
        });
    }
  }, []);

  const updatePerson = () => {
    axios
      .patch(
        baseURL + "/personal/" + id,
        {
          ...fetchData,
          complain_status: true,
        },
        {
          headers: {
            Authorization: `bearer ${user.token}`,
          },
          //withCredentials: true,
        }
      )
      .then((res) => {
        //console.log(res)
      });
  };

  return (
    <div>
      <BackGround>
        <Nav isLoggedIn={true} isStaff={false} />

        <Section>
          <h2 style={{ fontSize: 48 }}>ข้อมูล</h2>
          <>
            {fetchData.profile_picture ? (
              <img src={fetchData.profile_picture} alt="" />
            ) : (
              <img src={personImg} alt="" />
            )}
            <a
              className="edit"
              href="mailto:Dopalte.official@gmail.com?subject=แก้ไข้ขอมูลส่วนตัว Dopa-Directory"
              onClick={updatePerson}
            >
              แก้ไขข้อมูลส่วนตัว
            </a>
            <a
              className="report"
              href="mailto:Dopalte.official@gmail.com?subject=แนะนำติชมการเปิดเผยข้อมูลส่วนตัว Dopa-Directory"
            >
              แนะนำติชม
            </a>
            <div
              className="reset-pwd"
              onClick={() => {
                localStorage.setItem("APOD", btoa(fetchData._id));
                setTimeout(() => {
                  localStorage.removeItem("APOD");
                }, 420000);
                navigate(`/change-password/${fetchData._id}`);
              }}
            >
              เปลี่ยนรหัสผ่าน
            </div>
          </>
          <Col>
            <h3>ข้อมูลพื้นฐาน</h3>
            <p>
              {fetchData.prefix_th} {fetchData.first_name_th}{" "}
              {fetchData.last_name_th}
            </p>
            <p>เบอร์โทรศัพท์ 1 : {fetchData.primary_telephone}</p>
            <p>เบอร์โทรศัพท์ 2 : {fetchData.secondary_telephone}</p>
            <p>อีเมล 1 : {fetchData.primary_email}</p>
            <p>อีเมล 2 : {fetchData.secondary_email}</p>
          </Col>
          <Col2>
            <DataWrapper>
              <h3>ข้อมูลหน่วยงาน</h3>
              <p>สำนัก/กอง/จังหวัด : {fetchData.department}</p>
              <p>กลุ่มงาน/ฝ่าย/อำเภอ : {fetchData.minister}</p>
              <p>ตำแหน่ง : {fetchData.position}</p>
              <p>ระดับ : {fetchData.level}</p>
              <p>เบอร์ติดต่อภายใน : {fetchData.internal_contact_number}</p>
            </DataWrapper>
            <div>
              <p style={{ fontSize: 16 }}>
                วันที่แก้ไขล่าสุด :{" "}
                {new Date(fetchData.updated_at).toLocaleString("th-TH", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  second: "numeric",
                  timeZone: "GMT",
                })}
              </p>
              {isAdmin ? (
                <p style={{ fontSize: 16 }}>
                  แก้ไขโดย : {fetchData.updated_by}
                </p>
              ) : null}
            </div>
          </Col2>
        </Section>
      </BackGround>
    </div>
  );
};
const DataWrapper = styled("div")`
  grid-row: 1;
`;
const Col = styled("div")`
  text-align: center;
  justify-self: center;
  @media (max-width: 870px) {
    justify-self: center;
  }
`;
const Col2 = styled("div")`
  text-align: center;
  display: grid;
  justify-self: center;
  @media (max-width: 870px) {
    justify-self: center;
  }
`;

const Section = styled("div")`
  padding: 2rem 1rem;
  color: var(--white);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  background-color: rgba(20, 20, 20, 0.6);
  position: relative;
  column-gap: 1rem;
  row-gap: 1rem;
  min-height: 90vh;
  img {
    width: 300px;
    height: 300px;
    object-fit: cover;
    overflow: hidden;
    justify-self: center;
  }
  h2 {
    grid-column: 1/4;
    text-align: center;
  }
  h3 {
    margin-bottom: 2rem;
  }
  p {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    font-weight: normal;
  }
  .edit {
    color: var(--white);
    font-size: 1rem;
    position: absolute;
    top: 3rem;
    right: 2rem;
    margin-right: 5rem;
    text-decoration: underline;
    cursor: pointer;
  }
  .report {
    color: var(--white);
    font-size: 1rem;
    position: absolute;
    top: 5rem;
    right: 2rem;
    margin-right: 5rem;
    text-decoration: underline;
    cursor: pointer;
  }
  .reset-pwd {
    color: var(--white);
    font-size: 1rem;
    position: absolute;
    top: 7rem;
    right: 2rem;
    margin-right: 5rem;
    text-decoration: underline;
    cursor: pointer;
  }
  @media (max-width: 1150px) {
    padding: 2rem 1rem;
    grid-template-columns: repeat(1, 1fr);
    img {
      grid-column: 1;
    }
    h2 {
      grid-column: 1;
      font-size: 2rem;
    }
    .edit,
    .report {
      margin-right: 0;
      position: static;
      text-align: center;
    }
    .reset-pwd {
      margin-right: 0;
      position: static;
      text-align: center;
    }
  }

  @media (max-width: 600px) {
    p {
      font-size: 1rem;
    }
    img {
      width: 280px;
      height: 280px;
    }
    h2 {
      display: none;
    }
  }
`;
const BackGround = styled("div")`
  min-height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${imgSrc});
  background-repeat: no-repeat;
  background-size: cover;
`;
