import React, { useEffect, useState } from "react";
import { Nav } from "../components/Nav";
import { useParams } from "react-router-dom";
import { styled, Button, TextField } from "@mui/material";
import imgSrc from "../image/bg.jpg";
import { examplePlaceData } from "../Db";
import FileBase64 from "react-file-base64";
import axios from "axios";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { useNavigate } from "react-router-dom";
import noImage from "../image/simulated place.jpg";

const ConfirmDialog = (props) => {
  const { onClose, dialogValue, open, updateData } = props;
  const navigate = useNavigate();

  return (
    <Dialog open={open} fullWidth={true} maxWidth={"xs"}>
      <DialogTitle sx={{ textAlign: "center", fontSize: 24 }}>
        {dialogValue}
      </DialogTitle>
      <DialogContent>
        <DialogContentText></DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          onClick={() => {
            onClose();
            window.location.reload();
          }}
          sx={{ color: "#e3e3e3", fontSize: "1rem" }}
          variant="contained"
          color="b"
        >
          ต่อไป
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const EditDepartmentDetail = (props) => {
  const baseURL = process.env.REACT_APP_URL;
  let { id } = useParams();
  const user = JSON.parse(localStorage.getItem("user"));

  const startState = {
    name_th: "",
    name_en: "",
    primary_telephone: "",
    secondary_telephone: "",
    primary_email: "",
    secondary_email: "",
    address: "",
    subdistrict: "",
    district: "",
    town: "",
    province: "",
    zipcode: "",
    url: "",
    fax: "",
    primary_picture: "",
    internal_contact_number: "",
    open_time: "",
    full_description: "",
    minister: "",
    primary_picture: "",
    updated_by: userName,
    updated_at: "",
  };
  const [userName, setUserName] = useState(null);
  const [state, setState] = useState(startState);
  const [image, setImage] = useState(null);
  const [open, setOpen] = useState(false);
  const [dialogValue, setDialogValue] = useState("");
  let navigate = useNavigate();

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [nameErr, setNameErr] = useState(false);
  const [telErr, setTelErr] = useState(false);

  const updateData = (e) => {
    if (state.name_th == "" || state.primary_telephone == "") {
      if (state.name_th == "") {
        setNameErr(true);
      }
      if (state.primary_telephone == "") {
        setTelErr(true);
      }
      return;
    }
    e.preventDefault();
    //console.log(state)
    axios
      .patch(
        baseURL + "/department/" + id,
        { ...state, updated_by: userName },
        {
          headers: {
            Authorization: `bearer ${user.token}`,
          },
          //withCredentials: true,
        }
      )
      .then((res) => {
        if (res.status == "200") {
          setOpen(true);
          setDialogValue("แก้ไขสำเร็จ");
        } else {
          setOpen(true);
          setDialogValue("แก้ไขไม่สำเร็จ โปรดลองอีกครั้ง");
        }
      });
  };

  useEffect(() => {
    let isLogIn = JSON.parse(localStorage.getItem("user"));
    if (isLogIn == null) {
      navigate(`/`);
    } else if (isLogIn.role !== "admin") {
      navigate("/home");
    } else {
      axios
        .get(baseURL + "/personal/user/" + user.user_id, {
          headers: {
            Authorization: `bearer ${user.token}`,
          },
          //withCredentials: true,
        })
        .then((res) => {
          //console.log(res.data.first_name_th + " " + res.data.last_name_th);
          setUserName(res.data.first_name_th + " " + res.data.last_name_th);
          axios
            .get(baseURL + "/department/" + id, {
              headers: {
                Authorization: `bearer ${user.token}`,
              },
              //withCredentials: true,
            })
            .then((res) => {
              setImage(res.data.primary_picture);
              setState(res.data);
            });
        });
    }
  }, []);

  return (
    <>
      <BackGround>
        <Nav isLoggedIn={true} isStaff={true} />
        <Section>
          <AddSection>
            <form onSubmit={updateData}>
              <DataWrapper>
                <PhotoUserWrapper>
                  <h3>รูปภาพ</h3>
                  {image == null ? (
                    <img src={noImage}></img>
                  ) : (
                    <img src={image}></img>
                  )}
                  <FileBase64
                    onChange={handleChange}
                    multiple={false}
                    onDone={({ base64 }) => {
                      setState({ ...state, primary_picture: base64 });
                      setImage(base64);
                    }}
                  />
                </PhotoUserWrapper>
                <Column>
                  <h3>ข้อมูลหน่วยงาน</h3>
                  <RequiredCssTextField
                    label="ชื่อหน่วยงาน"
                    variant="filled"
                    onChange={handleChange}
                    color="w"
                    name="name_th"
                    value={state.name_th}
                    type="text"
                    error={nameErr}
                    helperText={nameErr ? "กรุณากรอกข้อมูล" : null}
                  />
                  <RequiredCssTextField
                    name="primary_telephone"
                    value={state.primary_telephone}
                    label="เบอร์โทรศัพท์ 1"
                    variant="filled"
                    onChange={handleChange}
                    color="w"
                    type="text"
                    error={telErr}
                    helperText={telErr ? "กรุณากรอกข้อมูล" : null}
                  />
                  <CssTextField
                    name="secondary_telephone"
                    value={state.secondary_telephone}
                    label="เบอร์โทรศัพท์ 2"
                    variant="filled"
                    onChange={handleChange}
                    color="w"
                    type="text"
                  />
                  <CssTextField
                    name="url"
                    value={state.url}
                    label="เว็บไซต์"
                    variant="filled"
                    onChange={handleChange}
                    color="w"
                    type="text"
                  />
                  <CssTextField
                    name="primary_email"
                    value={state.primary_email}
                    label="อีเมล 1"
                    variant="filled"
                    onChange={handleChange}
                    color="w"
                    type="text"
                  />
                  <CssTextField
                    name="secondary_email"
                    value={state.secondary_email}
                    label="อีเมล 2"
                    variant="filled"
                    onChange={handleChange}
                    color="w"
                    type="text"
                  />
                  <CssTextField
                    name="minister"
                    value={state.minister}
                    label="สังกัด"
                    variant="filled"
                    onChange={handleChange}
                    color="w"
                    type="text"
                  />
                  <CssTextField
                    name="internal_contact_number"
                    value={state.internal_contact_number}
                    label="เบอร์ติดต่อภายใน"
                    variant="filled"
                    onChange={handleChange}
                    color="w"
                    type="text"
                  />
                  <CssTextField
                    name="address"
                    value={state.address}
                    label="ที่อยู่"
                    variant="filled"
                    onChange={handleChange}
                    color="w"
                    type="text"
                  />
                  <CssTextField
                    name="subdistrict"
                    value={state.subdistrict}
                    label="ตำบล"
                    variant="filled"
                    onChange={handleChange}
                    color="w"
                    type="text"
                  />
                  <CssTextField
                    name="district"
                    value={state.district}
                    label="อำเภอ"
                    variant="filled"
                    onChange={handleChange}
                    color="w"
                    type="text"
                  />
                  <CssTextField
                    name="province"
                    value={state.province}
                    label="จังหวัด"
                    variant="filled"
                    onChange={handleChange}
                    color="w"
                    type="text"
                  />
                  <CssTextField
                    name="zipcode"
                    value={state.zipcode}
                    label="รหัสไปรษณีย์"
                    variant="filled"
                    onChange={handleChange}
                    color="w"
                    type="text"
                  />
                  <CssTextField
                    name="fax"
                    value={state.fax}
                    label="แฟกซ์"
                    variant="filled"
                    onChange={handleChange}
                    color="w"
                    type="text"
                  />
                  <CssTextField
                    name="open_time"
                    value={state.open_time}
                    label="วันเวลา เปิด-ปิด"
                    variant="filled"
                    onChange={handleChange}
                    color="w"
                    type="text"
                  />
                  <CssTextField
                    name="full_description"
                    value={state.full_description}
                    className="desc"
                    label="คำอธิบาย"
                    variant="filled"
                    onChange={handleChange}
                    color="w"
                    type="text"
                  />
                  <div>
                    <p className="edit">
                      วันที่แก้ไขล่าสุด :{" "}
                      {new Date(state.updated_at).toLocaleString("th-TH", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        second: "numeric",
                        timeZone: "GMT",
                      })}
                    </p>
                    <p className="edit">ผู้แก้ไข : {state.updated_by} </p>
                  </div>
                  <Button
                    variant="contained"
                    color="b"
                    type="submit"
                    sx={{
                      width: 300,
                      "@media (max-width: 375px)": {
                        width: "280px",
                      },
                    }}
                  >
                    ยืนยัน
                  </Button>
                </Column>
                <ConfirmDialog
                  open={open}
                  dialogValue={dialogValue}
                  onClose={handleClose}
                />
              </DataWrapper>
            </form>
          </AddSection>
        </Section>
      </BackGround>
    </>
  );
};

const Column = styled("div")({
  gridColumn: "2/4",
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  rowGap: "1.5rem",
  alignItems: "center",
  justifyItems: "center",
  h3: {
    gridColumn: "1/3",
    textAlign: "center",
  },
});
const Section = styled("div")({
  display: "flex",
  padding: "2rem 3rem",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  "@media (max-width: 600px)": {
    padding: "2rem 1rem",
  },
});

const PhotoUserWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  marginBottom: "2rem",
  img: {
    height: "300px",
    width: "300px",
    objectFit: "cover",
    marginBottom: "1.5rem",
    marginTop: "1.5rem",
  },
  ".pre-person-img": {
    height: "200px",
    width: "200px",
    marginBottom: "1.5rem",
    borderRadius: "50%",
    backgroundColor: "lightGray",
  },
  ".user": {
    marginBottom: "1rem",
  },
  "@media (max-width: 600px)": {
    h2: {
      fontSize: "1.5rem",
    },
  },
  "@media (max-width: 375px)": {
    img: {
      width: "280px",
      height: "280px",
    },
    input: {
      width: "280px",
    },
  },
});

const DataWrapper = styled("div")({
  display: "grid",
  gridTemplateColumns: "repeat(3,1fr)",
  columnGap: "1rem",
  justifyContent: "center",
  ".edit": {
    fontSize: 15,
  },
  "@media (max-width: 1000px)": {
    gridTemplateColumns: "repeat(2,1fr)",
    ".department": {
      gridColumn: "2",
    },
  },
  "@media (max-width: 768px)": {
    gridTemplateColumns: "repeat(1,1fr)",
    ".department": {
      gridColumn: "1",
    },
  },
});

const Input = styled("input")({
  display: "none",
});
const RequiredCssTextField = styled(TextField)({
  ".MuiFilledInput-root": {
    backgroundColor: "#d5d5d5",
    transitionDuration: "0ms",
    borderTopRightRadius: 5,
  },
  ".MuiFilledInput-root.Mui-focused": {
    backgroundColor: "#d5d5d5",
  },
  ".MuiInputLabel-root": {
    color: "#203b9b",
  },
  "label.Mui-focused": {
    color: "#203b9b",
  },
  ".MuiFilledInput-underline:after": {
    borderBottom: "3px solid #203b9b",
  },
  ".MuiFilledInput-underline:before": {
    borderBottomColor: "#202020",
  },
  ".MuiFilledInput-root:hover": {
    backgroundColor: "#e3e3e3",
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
  },
  "p.MuiFormHelperText-root": {
    background: "transparent",
    fontSize: "15px",
    color: "#e3e3e3",
  },
  width: 300,
  "@media (max-width: 1370px)": {
    width: 270,
  },
  "@media (max-width: 375px)": {
    width: "270px",
  },
});
const CssTextField = styled(TextField)({
  ".MuiFilledInput-root": {
    backgroundColor: "#d5d5d5",
    transitionDuration: "0ms",
    borderTopRightRadius: 5,
  },
  ".MuiFilledInput-root.Mui-focused": {
    backgroundColor: "#d5d5d5",
  },
  ".MuiInputLabel-root": {
    color: "  #404040",
  },
  "label.Mui-focused": {
    color: "#303030",
  },
  ".MuiFilledInput-underline:after": {
    borderBottom: "3px solid #203b9b",
  },
  ".MuiFilledInput-underline:before": {
    borderBottomColor: "#202020",
  },
  ".MuiFilledInput-root:hover": {
    backgroundColor: "#e3e3e3",
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
  },
  "p.MuiFormHelperText-root": {
    background: "transparent",
    fontSize: "15px",
    color: "#e3e3e3",
  },
  width: 300,
  "@media (max-width: 1370px)": {
    width: 270,
  },
  "@media (max-width: 375px)": {
    width: "270px",
  },
});

const AddSection = styled("div")({
  width: "100%",
});

const BackGround = styled("div")`
  min-height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${imgSrc});
  background-repeat: no-repeat;
  background-size: cover;
  color: var(--white);
`;
