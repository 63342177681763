import React, { useState, useEffect } from "react";
import { styled } from "@mui/material";
import imgSrc from "../image/bg.jpg";
import { SearchBar } from "../components/SearchBar";
import { Nav } from "../components/Nav";
import { examplePersonData } from "../Db";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { Button } from "@mui/material";
import axios from "axios";
import personImage from "../image/no-image.jpg";

export const StaffPermissions = () => {
  const place = examplePersonData[0];
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const baseURL = process.env.REACT_APP_URL;
  const [data, setData] = useState("");
  const [input, setInput] = useState("");
  const user = JSON.parse(localStorage.getItem("user"));

  const getData = (e) => {
    e.preventDefault();
    axios
      .get(baseURL + `/personal?search=${input}`, {
        headers: {
          Authorization: `bearer ${user.token}`,
        },
        //withCredentials: true,
      })
      .then((res) => {
        setData(res.data);
      });
  };

  const [feature, setFeature] = useState({
    import: false,
    delete: false,
    update: false,
    export: false,
    assign: false,
  });

  useEffect(() => {
    let isLogIn = JSON.parse(localStorage.getItem("user"));
    if (isLogIn == null) {
      navigate(`/`);
    } else if (isLogIn.role !== "admin") {
      navigate("/home");
    } else {
      axios
        .get(baseURL + "/feature/" + user.user_id, {
          headers: {
            Authorization: `bearer ${user.token}`,
          },
          //withCredentials: true,
        })
        .then((res) => {
          //console.log(res.data)
          setFeature({
            import: res.data.import,
            delete: res.data.delete,
            update: res.data.update,
            export: res.data.export,
            assign: res.data.assign,
          });
        });
    }
  }, []);

  return (
    <>
      <BackGround>
        <Nav isStaff={true} isLoggedIn={true} />
        {feature.assign ? (
          <>
            <Search>
              <h2>ค้นหาชื่อบุคลากร</h2>
              <SearchWrapper onSubmit={getData}>
                <InputStyle
                  minLength={3}
                  type="text"
                  placeholder="ค้นหา"
                  value={input}
                  onInput={(e) => setInput(e.target.value)}
                />
                <Button
                  sx={{
                    borderRadius: "0px 5px 5px 0px",
                    width: "30%",
                    height: 55,
                    "@media (max-width: 600px)": { fontSize: 16 },
                  }}
                  variant="contained"
                  color="b"
                  type="submit"
                  endIcon={<SearchIcon size="large" />}
                >
                  ค้นหา
                </Button>
              </SearchWrapper>
            </Search>
            <p className="result">ผลการค้นหา ({data.length})</p>
            <Section>
              {data &&
                data.map((data) => (
                  <Card
                    onClick={() =>
                      window.open(`/person-permissons/${data._id}`)
                    }
                  >
                    {data.profile_picture ? (
                      <img src={data.profile_picture} alt="" />
                    ) : (
                      <img className="person-image" src={personImage} alt="" />
                    )}
                    <p className="name">
                      {data.first_name_th} {data.last_name_th}
                    </p>
                    <p>เบอร์โทรศัพท์ : {data.primary_telephone}</p>
                    <p>
                      สถานะ :{" "}
                      {data.user_id ? "เปิดใช้งานแล้ว" : "ยังไม่เปิดใช้งาน"}
                    </p>
                  </Card>
                ))}
            </Section>
          </>
        ) : (
          <div className="pms">
            <h2>คุณไม่มีสิทธิ์ในการจัดการสิทธิ์แอดมิน</h2>
            <h2>กรุณาติดต่อแอดมิน</h2>
          </div>
        )}
      </BackGround>
    </>
  );
};

const Search = styled("div")`
  padding: 2rem 5rem;
  color: var(--white);
  display: flex;
  justify-content: space-between;
  align-items: center;
  h3 {
    width: 40%;
  }
  @media (max-width: 1250px) {
    padding: 2rem 2rem;
  }
  @media (max-width: 800px) {
    flex-direction: column;
    h3 {
      text-align: center;
      width: 100%;
    }
  }
  @media (max-width: 600px) {
    padding: 2rem 1rem;
  }
`;

const SearchWrapper = styled("form")`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 500px;

  @media (max-width: 600px) {
    width: 300px;
  }
`;

const InputStyle = styled("input")`
  height: 3.4rem;
  width: 70%;
  background: #e3e3e3;
  padding: 0 1rem;
  font-size: 1.5rem;
  outline: none;
  border: none;
  border-radius: 5px 0px 0px 5px;
  @media (max-width: 600px) {
    font-size: 1rem;
  }
`;

const Section = styled("div")`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 1rem;
  justify-items: center;
  row-gap: 3rem;
  column-gap: 1rem;
  @media (max-width: 1500px) {
    grid-template-columns: repeat(3, 1fr);
    padding: 3rem 1rem;
  }
  @media (max-width: 1120px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 750px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media (max-width: 380px) {
    grid-template-columns: repeat(1, 1fr);
    padding: 1rem 0rem;
  }
`;
const Card = styled("div")`
  min-height: 300px;
  width: 350px;
  border: 1px solid var(--white);
  border-radius: 5px;
  background-color: rgba(20, 20, 20, 0.8);
  color: var(--white);
  text-align: center;
  cursor: pointer;
  img {
    object-fit: fill;
    width: 100%;
    height: 350px;
  }
  p {
    font-size: 1rem;
  }
  p,
  h3 {
    margin-bottom: 1rem;
    padding: 0 0.5rem;
  }
  &:hover {
    background-color: var(--white);
    color: var(--black);
  }
  @media (max-width: 600px) {
    width: 335px;
    img {
      height: 335px;
    }
  }
  @media (max-width: 380px) {
    width: 280px;
    img {
      height: 280px;
    }
  }
`;

const BackGround = styled("div")`
  min-height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${imgSrc});
  background-repeat: no-repeat;
  background-size: cover;
  .result {
    color: var(--white);
    padding-left: 5rem;
  }
  .pms {
    color: var(--white);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80vh;
  }
  h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  @media (max-width: 1250px) {
    .result {
      padding-left: 2rem;
    }
  }
  @media (max-width: 800px) {
    .result {
      text-align: center;
    }
    .pms h2 {
      font-size: 1.5rem;
    }
    .pms {
      height: 60vh;
    }
  }
  @media (max-width: 450px) {
    .pms h2 {
      font-size: 1.2rem;
    }
    .pms {
      height: 40vh;
    }
  }
`;
