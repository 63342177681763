import React from "react";
import { CSVLink } from "react-csv";

export const PersonalCSV = () => {
  const headers = [
    { label: "prefix_th", key: "prefix_th" },
    { label: "first_name_th", key: "first_name_th" },
    { label: "last_name_th", key: "last_name_th" },
    { label: "primary_telephone", key: "primary_telephone" },
    { label: "secondary_telephone", key: "secondary_telephone" },
    { label: "primary_email", key: "primary_email" },
    { label: "secondary_email", key: "secondary_email" },
    { label: "department", key: "department" },
    { label: "minister", key: "minister" },
    { label: "position", key: "position" },
    { label: "level", key: "level" },
    { label: "internal_contact_number", key: "internal_contact_number" },
  ];

  return (
    <CSVLink
      headers={headers}
      data={[]}
      filename={"personalForm"}
      target="_blank"
    >
      ดาวน์โหลดแบบฟอร์ม
    </CSVLink>
  );
};
