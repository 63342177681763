import React, { useState, useContext } from "react";
import { styled } from "@mui/material";
import imgSrc from "../image/bg.jpg";
import { Nav } from "../components/Nav";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import SearchIcon from "@mui/icons-material/Search";
import { Button } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import AuthContext from "../context/AuthProvider";

export const Home = () => {
  let navigate = useNavigate();
  // const baseURL = 'process.env.REACT_APP_URL';
  const baseURL = process.env.REACT_APP_URL;
  const user = JSON.parse(localStorage.getItem("user"));

  const handleSubmit = (event) => {
    event.preventDefault();
    let formData = new FormData(event.currentTarget);
    let s = formData.get("searchValue").toString();
    let t = formData.get("type").toString();
    if (!s) return;
    axios
      .get(baseURL + `/${t}?search=${s}`, {
        headers: {
          Authorization: `bearer ${user.token}`,
        },
        //withCredentials: true,
      })
      .then((res) => {
        navigate(`/search`, { state: { data: res.data, type: t } });
      });
  };

  useEffect(() => {
    let isLogIn = JSON.parse(localStorage.getItem("user"));
    if (isLogIn == null) {
      navigate(`/`);
    }
  }, []);

  return (
    <BackGround>
      <Nav isLoggedIn={true} />
      <HomeSection>
        <h1 style={textStyle}>
          ค้นหาเบอร์ติดต่อ
          <br />
          กรมการปกครอง
        </h1>
        <h2 style={textStyle}>กรุณากรอกข้อมูลที่ต้องการค้นหา</h2>
        <form onSubmit={handleSubmit}>
          <FormControl
            sx={{
              marginTop: 2,
              ".MuiFormGroup-row": { justifyContent: "center" },
            }}
          >
            <RadioGroup name="type" defaultValue="department" row>
              <FormControlLabel
                control={
                  <Radio
                    sx={{
                      ".css-hyxlzm": { color: "#e3e3e3" },
                      ".MuiSvgIcon-root": { fontSize: 32 },
                      "@media (max-width : 500px)": {
                        ".MuiSvgIcon-root": { fontSize: 32 },
                      },
                    }}
                  />
                }
                value="department"
                label="หน่วยงาน"
              />
              <FormControlLabel
                control={
                  <Radio
                    sx={{
                      ".css-hyxlzm": { color: "#e3e3e3" },
                      ".MuiSvgIcon-root": { fontSize: 32 },
                      "@media (max-width : 500px)": {
                        ".MuiSvgIcon-root": { fontSize: 32 },
                      },
                    }}
                  />
                }
                value="personal"
                label="บุคคลากร"
              />
            </RadioGroup>
            <SearchWrapper>
              <InputStyle
                minLength={1}
                name="searchValue"
                type="text"
                placeholder="ค้นหา"
              />
              <Button
                sx={{
                  borderRadius: "0px 5px 5px 0px",
                  height: "55px",
                  "@media (max-width: 800px)": {
                    fontSize: 16,
                    height: "55px",
                    width: "30%",
                  },
                }}
                variant="contained"
                color="b"
                endIcon={<SearchIcon />}
                type="submit"
              >
                ค้นหา
              </Button>
            </SearchWrapper>
          </FormControl>
        </form>
      </HomeSection>
    </BackGround>
  );
};

const textStyle = {
  marginBottom: ".5rem",
};

const CssFormControl = styled("FormControl")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "90%",
  ".MuiFormGroup-row": {
    alignSelf: "center",
    justifyContent: "center",
    paddingBottom: ".5rem",
  },
  "@media (max-width: 800px)": {
    width: "100%",
  },
});

const SearchWrapper = styled("div")`
  display: flex;
  align-items: center;
`;

const InputStyle = styled("input")`
  height: 3.4rem;
  width: 100%;
  background: #e3e3e3;
  padding: 0 1rem;
  font-size: 1.5rem;
  outline: none;
  border: none;
  border-radius: 5px 0px 0px 5px;
  @media (max-width: 650px) {
    width: 70%;
    font-size: 1rem;
  }
`;

const HomeSection = styled("div")`
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #e3e3e3;
  text-align: center;
  padding: 0 1rem;
  br {
    display: none;
  }
  @media (max-width: 1000px) {
    h1 {
      font-size: 2rem;
    }
  }
  @media (max-width: 500px) {
    h1 {
      font-size: 1.5rem;
    }
    h2 {
      font-size: 1rem;
    }
  }
  @media (max-width: 364px) {
    h1 br {
      display: block;
    }
  }
`;

const BackGround = styled("div")`
  min-height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${imgSrc});
  background-repeat: no-repeat;
  background-size: cover;
`;
