import React, { useState, useEffect } from "react";
import styled from "styled-components";
import imgSrc from "../image/bg.jpg";
import { Button, TextField } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";

export const ResetPassword = () => {
  let navigate = useNavigate();
  const baseURL = process.env.REACT_APP_URL;
  const [errMessage, setErrMessage] = useState("");
  const [body, setBody] = useState({
    username: "",
    password: "",
  });
  const [isOtpError, setIsOtpError] = useState(false);
  const [isTelError, setIsTelError] = useState(false);
  const [tel, setTel] = useState("");
  const [otp, setOtp] = useState("");
  const [checkOtp, setCheckOtp] = useState(false);
  const [btnText, setBtnText] = useState("ขอ OTP");
  const [disable, setDisable] = useState(false);
  const [sec, setSec] = useState(null);
  const [invalidTel, setInvalidTel] = useState(false);
  const [error, setError] = useState(false);
  const [isOver, setIsOver] = useState(false);
  const [credit, setCredit] = useState(false);
  const [userId, setUserId] = useState("");
  const user = JSON.parse(localStorage.getItem("user"));

  const otpWait = () => {
    setSec(60);
    let i = 60;
    let countDown = setInterval(() => {
      i--;
      setSec(i);
    }, 1000);

    setTimeout(() => {
      setDisable(false);
      setBtnText("ขอ OTP");
      setSec(null);
      clearInterval(countDown);
    }, 60000);
  };

  const handleOtpInput = (e) => {
    setOtp(e.target.value);
  };

  const handleTelInput = (e) => {
    setTel(e.target.value);
  };

  const handleInput = (e) => {
    setBody({ ...body, [e.target.name]: e.target.value });
  };

  const requestOtp = () => {
    setIsTelError(false);
    setInvalidTel(false);
    if (tel.length !== 10) {
      setInvalidTel(true);
      return;
    } else {
      axios
        .post(baseURL + "/user/checktelephone", { username: tel })
        .then((res) => {
          if (res.data.message === "Not Found") {
            setIsTelError(true);
            return;
          } else if (res.data.message === "Exist") {
            setUserId(atob(res.data.message2));
            axios
              .post(baseURL + "/user/otp", {
                telephone: tel,
              })
              .then((res2) => {
                if (res2.data.startsWith("STATUS=504")) {
                  setCredit(true);
                  return;
                } else {
                  setBtnText("ส่งแล้ว");
                  setDisable(true);
                  otpWait();
                }
              })
              .catch((err) => {
                if (err.response.data.startsWith("STATUS=504")) {
                  setCredit(true);
                } else if (err.response.data.startsWith("คำขอ")) {
                  setIsOver(true);
                } else {
                  setError(true);
                }
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const verifyOtp = (event) => {
    event.preventDefault();
    axios
      .post(baseURL + "/user/verify", { telephone: tel, otp: otp })
      .then((res) => {
        let token = { token: res.data.access };
        localStorage.setItem("user", JSON.stringify(token));
        localStorage.setItem("APOD", btoa(userId));
        setTimeout(() => {
          localStorage.removeItem("APOD");
        }, 420000);
        navigate(`/change-password/${userId}`);
      })
      .catch((err) => {
        if (err.response.status !== 200) {
          setIsOtpError(true);
        }
      });
  };

  return (
    <LoginStyle>
      <LogoText>
        <Dopa>DOPA</Dopa>
        <br />
        DIRECTORY
      </LogoText>
      <form onSubmit={verifyOtp}>
        <FormWrapperStyle>
          <TextStyle>
            <h2>เปลี่ยนรหัสผ่าน</h2>
            <Link to="/">
              <LinkText>เข้าสู่ระบบ</LinkText>
            </Link>
          </TextStyle>
          <OtpWrapper>
            <div>
              <CssTextField
                label="เบอร์โทรศัพท์"
                variant="filled"
                color="w"
                type="text"
                error={isTelError || invalidTel}
                helperText={
                  isTelError
                    ? "เบอร์โทรศัพท์นี้ยังไม่ถูกลงทะเบียน"
                    : invalidTel
                    ? "กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง"
                    : error
                    ? "กรุณาลองใหม่อีกครั้ง"
                    : isOver
                    ? "คำขอ OTP ของคุณถูกส่งมาเกิน 5 ครั้ง กรุณาลองใหม่อีกครั้ง 24 ชั่วโมงถัดไป"
                    : credit
                    ? "Error 999 โปรดติดต่อ กองการสื่อสาร กรมการปกครอง หมายเลข Call center 0639042999,0639043000 email  Dopalte.official@gmail.com"
                    : null
                }
                onChange={handleTelInput}
                inputProps={{ maxLength: 10 }}
              />
              <Button
                color="lg"
                variant="contained"
                disabled={disable}
                sx={{
                  fontSize: 16,
                  borderRadius: "0px 5px 5px 0px",
                  border: "0px solid #202020",
                  height: "3.5rem",
                  width: "20%",
                  "&.Mui-disabled": {
                    color: "rgba(256,256,256,0.5)",
                    background: "rgba(0,0,0,0.5)",
                  },
                }}
                onClick={requestOtp}
              >
                {btnText} {sec !== null ? `(${sec})` : null}
              </Button>
            </div>
          </OtpWrapper>
          <CssTextField
            label="OTP"
            variant="filled"
            color="w"
            type="text"
            onChange={handleOtpInput}
            error={isOtpError}
            helperText={isOtpError ? "Invalid OTP" : null}
            inputProps={{ maxLength: 6 }}
          />
          <Button type="submit" color="b" variant="contained">
            ยืนยัน
          </Button>
        </FormWrapperStyle>
      </form>
    </LoginStyle>
  );
};

const OtpWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    width: 100%;
    display: flex;
  }
`;

const CssTextField = styled(TextField)({
  ".MuiFilledInput-root": {
    backgroundColor: "#e0e0e0",
    transitionDuration: "0ms",
    borderTopRightRadius: 0,
  },
  ".MuiFilledInput-root.Mui-focused": {
    backgroundColor: "#e0e0e0",
  },
  ".MuiInputLabel-root": {
    color: "#203b9b",
  },
  "label.Mui-focused": {
    color: "#203b9b",
  },
  ".MuiFilledInput-underline:after": {
    borderBottom: "3px solid #203b9b",
  },
  ".MuiFilledInput-underline:before": {
    borderBottomColor: "#202020",
  },
  ".MuiFilledInput-root:hover": {
    backgroundColor: "#e3e3e3",
    borderTopRightRadius: 0,
    borderTopLeftRadius: 5,
  },
  "p.MuiFormHelperText-root": {
    background: "transparent",
    fontSize: "15px",
    color: "#e3e3e3",
  },
});

const LinkText = styled.p`
  &:hover {
    text-decoration: underline;
  }
  @media (max-width: 1350px) {
    & {
      font-size: 1rem;
    }
  }
`;

const TextStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 2rem;
  a {
    text-align: right;
    text-decoration: none;
    color: #e3e3e3;
  }
`;
const FormWrapperStyle = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0rem 5rem;
  row-gap: 1.5rem;
  .forgot {
    color: var(--white);
    text-decoration: none;
    width: 80px;
    align-self: flex-end;
    cursor: pointer;
  }
  @media (max-width: 1250px) {
    h2 {
      font-size: 1.5rem;
    }
    padding: 0rem 2rem;
  }
  @media (max-width: 850px) {
    align-self: start;
    padding: 0rem 5rem;
  }
  @media (max-width: 500px) {
    padding: 0rem 0rem;
  }
`;

const LoginStyle = styled.div`
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${imgSrc});
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0 5rem;
  color: white;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  @media (max-width: 1250px) {
    padding: 0 3rem;
  }
  @media (max-width: 950px) {
    padding: 0 1rem;
  }
  @media (max-width: 850px) {
    grid-template-columns: 1fr;
  }
`;

const LogoText = styled.h1`
  font-size: 5rem;
  font-family: "Cormorant Garamond", sans-serif;
  font-weight: 500;
  text-shadow: 0px 0px 10px black;
  text-align: center;
  letter-spacing: 1rem;
  @media (max-width: 1250px) {
    font-size: 2.5rem;
  }
  @media (max-width: 400px) {
    font-size: 1.5rem;
  }
`;

const Dopa = styled.span`
  font-size: 10rem;
  font-family: "Cormorant Garamond", sans-serif;
  font-weight: 500;
  text-shadow: 0px 0px 10px black;
  @media (max-width: 1250px) {
    font-size: 5rem;
  }
  @media (max-width: 400px) {
    font-size: 3rem;
  }
`;
