import React from "react";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import SearchIcon from "@mui/icons-material/Search";
import { Button } from "@mui/material";
import { styled } from "@mui/material";
import { useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";

export const SearchBar = (props) => {
  const name = props.name;
  const isStaff = props.isStaff;
  const childToParent = props.childToParent;
  const setTypeParent = props.setType;
  const setSearchValue = props.setSearchValue;
  const onSubmitForm = props.onSubmitForm;
  const onEnter = props.onEnter;
  const [consent, setConsent] = useState(false);
  const [complain, setComplain] = useState(false);
  const [input, setInput] = useState("");
  const [type, setType] = useState("department");
  // const baseURL = 'process.env.REACT_APP_URL';
  const baseURL = process.env.REACT_APP_URL;
  let navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));

  const handleInput = (e) => {
    setInput(e.target.value);
  };

  const getData = (event) => {
    event.preventDefault();
    axios
      .get(baseURL + `/${type}?search=${input}`, {
        headers: { Authorization: `bearer ${user.token}` },
        //withCredentials: true,
      })
      .then((res) => {
        if (consent && !complain) {
          let arr = [];
          for (let i = 0; i < res.data.length; i++) {
            if (res.data[i].NDA_status == true) {
              arr.push(res.data[i]);
            }
          }
          setTypeParent(type);
          setSearchValue(input);
          return childToParent(arr);
        }
        if (complain && !consent) {
          let arr = [];
          for (let i = 0; i < res.data.length; i++) {
            if (res.data[i].complain_status == true) {
              arr.push(res.data[i]);
            }
          }
          setTypeParent(type);
          setSearchValue(input);
          return childToParent(arr);
        }
        if (consent && complain) {
          let arr = [];
          for (let i = 0; i < res.data.length; i++) {
            if (res.data[i].complain_status == true) {
              if (res.data[i].NDA_status == true) {
                arr.push(res.data[i]);
              }
            }
          }
          setTypeParent(type);
          setSearchValue(input);
          return childToParent(arr);
        }
        childToParent(res.data);
        setTypeParent(type);
        setSearchValue(input);
      });
  };

  return (
    <Search>
      <h2>{name}</h2>
      <form onSubmit={getData}>
        <FormControl
          sx={{
            flexDirection: "row",
            "@media(max-width: 900px)": { flexDirection: "column" },
          }}
        >
          <RadioGroup
            value={type}
            name="type"
            onChange={(e) => {
              setType(e.target.value);
            }}
            row
            sx={{
              flexWrap: "wrap",
              "&.MuiFormGroup-row": {
                alignSelf: "center",
                paddingBottom: ".5rem",
              },
              "@media (max-width: 1250px)": {
                justifyContent: "center",
              },
            }}
          >
            <FormControlLabel
              control={<Radio sx={{ ".css-hyxlzm": { color: "#e3e3e3" } }} />}
              value="department"
              label="หน่วยงาน"
            />
            <FormControlLabel
              control={<Radio sx={{ ".css-hyxlzm": { color: "#e3e3e3" } }} />}
              value="personal"
              size="large"
              label="บุคคลากร"
            />
            {isStaff && type === "personal" ? (
              <>
                <FormControlLabel
                  sx={{
                    ".MuiFormControlLabel-label": { fontSize: 16 },
                  }}
                  control={
                    <Checkbox
                      sx={{
                        color: "#e3e3e3",
                      }}
                      color="w"
                      name="consent"
                      checked={consent}
                      size="small"
                      onChange={() => {
                        setConsent(!consent);
                      }}
                    />
                  }
                  label="ยอมรับเงื่อนไขและข้อตกลง"
                />
                <FormControlLabel
                  sx={{
                    ".MuiFormControlLabel-label": { fontSize: 16 },
                  }}
                  control={
                    <Checkbox
                      sx={{
                        color: "#e3e3e3",
                      }}
                      color="w"
                      size="small"
                      name="complain"
                      checked={complain == true}
                      onChange={() => {
                        setComplain(!complain);
                      }}
                    />
                  }
                  label="แนะนำติชม"
                />
              </>
            ) : (
              <></>
            )}
          </RadioGroup>
          <SearchWrapper>
            <InputStyle
              minLength={3}
              // inputProps={{ minLength: 3 }}
              type="text"
              placeholder="ค้นหา"
              value={input}
              name="searchInput"
              onChange={handleInput}
            />
            <Button
              sx={{
                borderRadius: "0px 5px 5px 0px",
                width: "30%",
                height: 55,
                "@media (max-width: 600px)": { fontSize: 16 },
              }}
              variant="contained"
              color="b"
              type="submit"
              onClick={getData}
              endIcon={<SearchIcon size="large" />}
            >
              ค้นหา
            </Button>
          </SearchWrapper>
        </FormControl>
      </form>
    </Search>
  );
};

const Search = styled("div")`
  padding: 2rem 5rem;
  color: var(--white);
  display: flex;
  justify-content: space-between;
  align-items: center;
  h3 {
    width: 40%;
  }
  h2 {
    text-align: center;
  }
  @media (max-width: 1250px) {
    padding: 2rem 2rem;
  }
  @media (max-width: 800px) {
    flex-direction: column;
    h3 {
      text-align: center;
      width: 100%;
    }
  }
  @media (max-width: 600px) {
    padding: 2rem 1rem;
  }
`;

const SearchWrapper = styled("div")`
  display: flex;
  align-items: center;
`;

const InputStyle = styled("input")`
  height: 3.4rem;
  width: 70%;
  background: #e3e3e3;
  padding: 0 1rem;
  font-size: 1.5rem;
  outline: none;
  border: none;
  border-radius: 5px 0px 0px 5px;
  @media (max-width: 600px) {
    font-size: 1rem;
  }
`;
