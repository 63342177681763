import React, { useState, useEffect } from "react";
import styled from "styled-components";
import imgSrc from "../image/bg.jpg";
import {
  Button,
  TextField,
  RadioGroup,
  Radio,
  FormControl,
  Checkbox,
  FormGroup,
} from "@mui/material";
import { Nav } from "../components/Nav";
import FormControlLabel from "@mui/material/FormControlLabel";
import Select from "@mui/material/Select";
import { useNavigate, useParams, Link } from "react-router-dom";
import axios from "axios";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import FileBase64 from "react-file-base64";
import noImage from "../image/no-image.jpg";

const ConfirmDialog = (props) => {
  const { onClose, dialogValue, open, updateData, isSuccess } = props;
  const navigate = useNavigate();

  return (
    <Dialog open={open} fullWidth={true} maxWidth={"xs"}>
      <DialogTitle sx={{ textAlign: "center", fontSize: 24 }}>
        {dialogValue}
      </DialogTitle>
      <DialogContent>
        <DialogContentText></DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          onClick={() => {
            if (isSuccess) {
              navigate("/");
            } else {
              onClose();
            }
          }}
          sx={{ color: "#e3e3e3", fontSize: "1rem", width: "70%" }}
          variant="contained"
          color="b"
        >
          {isSuccess ? "เข้าสู่ระบบ" : "ปิด"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const RegisterInfo = (props) => {
  let navigate = useNavigate();
  let { userId } = useParams();
  const [g, setG] = useState("Male");
  const [consent, setConsent] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  const [open, setOpen] = useState(false);
  const [dialogValue, setDialogValue] = useState("");
  const [isPwdError, setIsPwdError] = useState(false);
  const [isExistPerson, setIsExistPerson] = useState(false);
  // const baseURL = 'process.env.REACT_APP_URL';
  const baseURL = process.env.REACT_APP_URL;
  const [isRead, setIsRead] = useState(false);
  const [disableBtn, setIsDisableBtn] = useState(false);
  const [btnText, setBtnText] = useState("สมัครสมาชิก");
  const [emptyPassword, setEmptyPassword] = useState(false);
  const [image, setImage] = useState(noImage);
  const [id, setId] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const startState = {
    prefix_th: "",
    first_name_th: "",
    last_name_th: "",
    prefix_en: "",
    first_name_en: "",
    last_name_en: "",
    gender: g,
    primary_telephone: "",
    secondary_telephone: "",
    primary_email: "",
    secondary_email: "",
    password: "",
    confirm_password: "",
    department: "",
    minister: "",
    position: "",
    level: "",
    internal_mobile_number: "",
    profile_picture: noImage,
    NDA_status: consent,
    complain_status: false,
  };
  const [isFN, setIsFN] = useState(false);
  const [isLN, setIsLN] = useState(false);
  const [isDep, setIsDep] = useState(false);
  const [passLength, setPassLength] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [register, setRegister] = useState(false);
  const [body, setBody] = useState(startState);
  const user = JSON.parse(localStorage.getItem("user"));

  const postUser = () => {
    setPasswordError(false);
    const uppercaseRegExp = /(?=.*?[A-Z])/;
    const lowercaseRegExp = /(?=.*?[a-z])/;
    const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
    const minLengthRegExp = /.{8,}/;

    const uppercasePassword = uppercaseRegExp.test(body.password);
    const lowercasePassword = lowercaseRegExp.test(body.password);
    const specialChar = specialCharRegExp.test(body.password);
    const minLengthPassword = minLengthRegExp.test(body.password);

    if (!uppercasePassword) {
      setErrMsg("รหัสผ่านต้องมีตัวหนังสือพิมพ์ใหญ่อย่างน้อย 1 ตัว");
      setPasswordError(true);
      return;
    } else if (!lowercasePassword) {
      setErrMsg("รหัสผ่านต้องมีตัวหนังสือพิมพ์เล็กอย่างน้อย 1 ตัว");
      setPasswordError(true);
      return;
    } else if (!specialChar) {
      setErrMsg("รหัสผ่านต้องมีตัวอักษรพิเศษอย่างน้อย 1 ตัว");
      setPasswordError(true);
      return;
    } else if (!minLengthPassword) {
      setErrMsg("รหัสผ่านต้องมีความยาวอย่างน้อย 8 ตัวอักษร");
      setPasswordError(true);
      return;
    } else if (body.password != body.confirm_password) {
      setErrMsg("รหัสผ่านไม่ตรงกัน");
      setPasswordError(true);
      return;
    }
    if (body.department == "") {
      setIsDep(true);
    }
    if (body.first_name_th == "") {
      setIsFN(true);
    }
    if (body.last_name_th == "") {
      setIsLN(true);
    }
    if (
      body.first_name_th == "" ||
      body.last_name_th == "" ||
      body.department == ""
    ) {
      return;
    } else {
      setIsDisableBtn(true);
      setBtnText("กรุณารอสักครู่");
      axios
        .post(
          baseURL + "/user/signup",
          {
            username: body.primary_telephone,
            password: body.password,
          },
          {
            headers: {
              Authorization: `bearer ${user.token}`,
            },
          }
        )
        .then((res) => {
          axios
            .patch(
              baseURL + "/personal/" + body._id,
              {
                ...body,
                user_id: res.data._id,
              },
              {
                headers: {
                  Authorization: `bearer ${user.token}`,
                },
                //withCredentials: true,
              }
            )
            .then((res) => {
              setOpen(true);
              setDialogValue("สมัครสมาชิกสำเร็จ");
              setIsSuccess(true);
            })
            .catch((err) => {
              setIsDisableBtn(false);
              setBtnText("สมัครสมาชิก");
              setOpen(true);
              setDialogValue("กรุณาลองใหม่อีกครั้ง");
              axios.delete(baseURL + "/user/delete/" + res.data._id, {
                headers: {
                  Authorization: `bearer ${user.token}`,
                },
              });
            });
        })
        .catch((err) => {
          setIsDisableBtn(false);
          setBtnText("สมัครสมาชิก");
          setOpen(true);
          setDialogValue("กรุณาลองใหม่อีกครั้ง");
          console.log(err);
        });
    }
  };

  const handleInput = (e) => {
    setBody({ ...body, [e.target.name]: e.target.value });
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setRegister(true);
    axios
      .post(
        baseURL + "/user/profile",
        { user_id: btoa(userId) },
        {
          headers: {
            Authorization: `bearer ${user.token}`,
          },
        }
      )
      .then((res) => {
        //console.log(res);
        if (res.data._id) {
          setId(res.data._id);
          setIsExistPerson(true);
          setBody(res.data);
        } else {
          setIsExistPerson(false);
          // navigate("/");
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  if (register == false) {
    return (
      <RegisterStyle>
        <Nav isLoggedIn={false} isRegister={true} />
        <h2>คุณไม่มีสิทธิ์ในการเข้าถึงหน้านี้</h2>
      </RegisterStyle>
    );
  }

  return (
    <>
      <RegisterStyle>
        <Nav isLoggedIn={false} isRegister={true} />
        <RegisterSection>
          <h2>กรุณากรอกข้อมูล</h2>
          <FormWrapper>
            <PersonWrapper>
              <h3 className="person">ข้อมูลพื้นฐาน</h3>
              <PhotoUserWrapper>
                <p style={{ marginBottom: "1rem" }}>รูปภาพ</p>
                <img src={image}></img>
                <FileBase64
                  onChange={handleInput}
                  multiple={false}
                  onDone={({ base64 }) => {
                    setBody({ ...body, profile_picture: base64 });
                    setImage(base64);
                  }}
                />
              </PhotoUserWrapper>
              <PersonalTextField>
                <CssTextField
                  label="คำนำหน้า"
                  required
                  variant="filled"
                  color="w"
                  type="text"
                  value={body.prefix_th}
                  name="prefix_th"
                  onChange={handleInput}
                />
                <RequiredCssTextField
                  label="ชื่อ"
                  variant="filled"
                  color="w"
                  required
                  type="text"
                  error={isFN}
                  helperText={isFN ? "กรุณากรอกข้อมูล" : null}
                  value={body.first_name_th}
                  name="first_name_th"
                  onChange={handleInput}
                />
                <RequiredCssTextField
                  label="นามสกุล"
                  required
                  variant="filled"
                  color="w"
                  type="text"
                  name="last_name_th"
                  error={isLN}
                  helperText={isLN ? "กรุณากรอกข้อมูล" : null}
                  value={body.last_name_th}
                  onChange={handleInput}
                />
                <RequiredCssTextField
                  label="เบอร์โทรศัพท์ 1 (ใช้ในการเข้าสู่ระบบ)"
                  required
                  variant="filled"
                  color="w"
                  type="number"
                  value={body.primary_telephone}
                />
                <RequiredCssTextField
                  label="รหัสผ่าน"
                  required
                  variant="filled"
                  color="w"
                  type="password"
                  name="password"
                  error={passwordError}
                  helperText={passwordError ? errMsg : null}
                  onChange={handleInput}
                  title="รหัสผ่านต้องมีตัวอักษรภาษอังกฤษตัวพิมพ์เล็กอย่างน้อย 1 ตัว,
                  ตัวพิมพ์ใหญ่อย่างน้อย 1 ตัว, ตัวอักษรพิเศษอย่างน้อย 1 ตัว
                  (#?!@$%^&*-) และมีความยาวรวมกันไม่ต่ำกว่า 8 ตัวอักษร"
                />
                <RequiredCssTextField
                  label="ยืนยันรหัสผ่าน"
                  required
                  variant="filled"
                  color="w"
                  type="password"
                  error={passwordError}
                  helperText={passwordError ? errMsg : null}
                  name="confirm_password"
                  onChange={handleInput}
                  title="รหัสผ่านต้องมีตัวอักษรภาษอังกฤษตัวพิมพ์เล็กอย่างน้อย 1 ตัว,
                  ตัวพิมพ์ใหญ่อย่างน้อย 1 ตัว, ตัวอักษรพิเศษอย่างน้อย 1 ตัว
                  (#?!@$%^&*-) และมีความยาวรวมกันไม่ต่ำกว่า 8 ตัวอักษร"
                />
                {/* <p style={{ fontSize: 14, fontWeight: 400, width: 270 }}>
                  รหัสผ่านต้องมีตัวอักษรภาษอังกฤษตัวพิมพ์เล็กอย่างน้อย 1 ตัว,
                  ตัวพิมพ์ใหญ่อย่างน้อย 1 ตัว, ตัวอักษรพิเศษอย่างน้อย 1 ตัว
                  (#?!@$%^&*-) และมีความยาวรวมกันไม่ต่ำกว่า 8 ตัวอักษร
                </p> */}
                <CssTextField
                  label="เบอร์โทรศัพท์ 2"
                  variant="filled"
                  color="w"
                  type="text"
                  name="secondary_telephone"
                  value={body.secondary_telephone}
                  onChange={handleInput}
                />
                <CssTextField
                  label="อีเมล 1"
                  variant="filled"
                  color="w"
                  type="email"
                  name="primary_email"
                  value={body.primary_email}
                  onChange={handleInput}
                />
                <CssTextField
                  label="อีเมล 2"
                  variant="filled"
                  color="w"
                  type="email"
                  name="secondary_email"
                  value={body.secondary_email}
                  onChange={handleInput}
                />
              </PersonalTextField>
            </PersonWrapper>
            <DepartmentWrapper>
              <h3>ข้อมูลหน่วยงาน</h3>
              <RequiredCssTextField
                label="สำนัก/กอง/จังหวัด"
                required
                variant="filled"
                color="w"
                type="text"
                name="department"
                error={isDep}
                helperText={isDep ? "กรุณากรอกข้อมูล" : null}
                value={body.department}
                onChange={handleInput}
              />
              <CssTextField
                label="กลุ่มงาน/ฝ่าย/อำเภอ"
                variant="filled"
                color="w"
                type="text"
                name="minister"
                value={body.minister}
                onChange={handleInput}
              />
              <CssTextField
                label="ตำแหน่ง"
                variant="filled"
                color="w"
                type="text"
                name="position"
                value={body.position}
                onChange={handleInput}
              />
              <CssTextField
                label="ระดับ"
                variant="filled"
                color="w"
                type="text"
                name="level"
                value={body.level}
                onChange={handleInput}
              />
              <CssTextField
                label="เบอร์ติดต่อภายใน"
                variant="filled"
                color="w"
                type="text"
                name="internal_mobile_number"
                value={body.internal_mobile_number}
                onChange={handleInput}
              />
              <FormGroup
                sx={{
                  "@media(max-width: 1000px)": {
                    gridColumn: 2,
                  },
                  "@media(max-width: 640px)": {
                    gridColumn: 1,
                  },
                  "@media(max-width: 450px)": {
                    gridColumn: 1,
                  },
                }}
              >
                <ConsentWrapper>
                  <FormControlLabel
                    sx={{
                      ".MuiFormControlLabel-label": { fontSize: 16 },
                    }}
                    control={
                      <Checkbox
                        sx={{
                          color: "#e3e3e3",
                        }}
                        color="w"
                        checked={consent}
                        onChange={(e) => {
                          if (isRead == false) {
                            setOpen(true);
                            setDialogValue("กรุณาอ่านเงื่อนไขและข้อตกลง");
                          } else {
                            setConsent(!consent);
                            setBody({
                              ...body,
                              NDA_status: e.target.checked,
                            });
                          }
                        }}
                      />
                    }
                    label="ยอมรับเงื่อนไขและข้อตกลง"
                  />
                  <Link
                    to="/Consent.PDF"
                    target="_blank"
                    style={{ color: "#e3e3e3" }}
                    onClick={() => setIsRead(true)}
                  >
                    ดูเงื่อนไขและข้อตกลง
                  </Link>
                  <p>
                    เมื่อท่านกดยอมรับเงื่อนไข
                    <br />
                    ข้อมูลของท่านจะสามารถถูกค้นหาได้
                    <br />
                    ในระบบ DOPA Directory
                  </p>
                </ConsentWrapper>
              </FormGroup>
              <Button
                sx={{
                  width: 300,
                  "&.Mui-disabled": {
                    color: "#e3e3e380",
                    backgroundColor: "#65656580",
                  },
                }}
                variant="contained"
                color="b"
                disabled={disableBtn}
                onClick={() => {
                  if (consent == false) {
                    setOpen(true);
                    setDialogValue("กรุณายอมรับเงื่อนไขและข้อตกลง");
                  } else {
                    postUser();
                  }
                }}
              >
                {btnText}
              </Button>
            </DepartmentWrapper>
          </FormWrapper>
        </RegisterSection>
      </RegisterStyle>
      <ConfirmDialog
        open={open}
        dialogValue={dialogValue}
        onClose={handleClose}
        isSuccess={isSuccess}
      />
    </>
  );
};

const PhotoUserWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  img: {
    height: "250px",
    width: "250px",
    objectFit: "cover",
    marginBottom: "1.5rem",
  },
  ".pre-person-img": {
    height: "200px",
    width: "200px",
    marginBottom: "1.5rem",
    borderRadius: "50%",
    backgroundColor: "lightGray",
  },
  ".user": {
    marginBottom: "1rem",
  },
  "@media (max-width: 600px)": {
    h2: {
      fontSize: "1.5rem",
    },
  },
  "@media (max-width: 375px)": {
    img: {
      width: "250px",
      height: "250px",
    },
    input: {
      width: "250px",
    },
  },
});
const PersonWrapper = styled("div")({
  display: "grid",
  gridTemplateColumns: "1fr 2fr",
  gridTemplateRows: "repeat(1,min-content)",
  justifyContent: "center",
  rowGap: "1rem",
  columnGap: "1rem",
  justifyItems: "center",
  h3: {
    textAlign: "center",
    gridColumn: "1/4",
    marginBottom: "2rem",
  },
  "@media (max-width: 1020px)": {
    gridTemplateColumns: "repeat(1,1fr)",
    h3: {
      gridColumn: "1",
    },
  },
  // display: "grid",
  // gridTemplateColumns: "repeat(3,1fr)",
  // gridTemplateRows: "repeat(1,56px)",
  // rowGap: "1.5rem",
  // alignItems: "flex-start",
  // justifyItems: "center",
  // columnGap: "1rem",
  // h3: {
  //   gridColumn: "1/4",
  //   textAlign: "center",
  // },
  // "@media (max-width: 1020px)": {
  //   gridTemplateColumns: "repeat(1,1fr)",
  //   h3: {
  //     gridColumn: "1",
  //   },
  // },
});
const PersonalTextField = styled("div")({
  display: "grid",
  height: "fit-content",
  gridTemplateColumns: "repeat(1,1fr)",
  justifyItems: "center",
  columnGap: "1.5rem",
  rowGap: "1rem",
  "@media (max-width: 650px)": {
    gridTemplateColumns: "repeat(1,1fr)",
  },
});

const DepartmentWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  rowGap: "1rem",
  alignItems: "center",
  justifyContent: "space-between",
  h3: {
    textAlign: "center",
    marginBottom: "2rem",
  },
  "@media (max-width: 1020px)": {
    justifyContent: "flex-start",
    h3: {
      gridColumn: "1",
    },
  },
  "@media (max-width: 650px)": {
    paddingBottom: "2rem",
    h3: {
      marginTop: "1rem",
    },
  },
});

const FormWrapper = styled("div")({
  display: "grid",
  gridTemplateColumns: "3fr 1fr",
  columnGap: "1rem",
  minHeight: "80vh",
  "@media (max-width: 1020px)": {
    gridTemplateColumns: "1fr",
  },
  "@media (max-width: 950px)": {
    gridTemplateColumns: "1fr",
  },
  "@media (max-width: 769px)": {
    gridTemplateColumns: "1fr",
  },
});

const RegisterSection = styled("div")({
  padding: "0rem 2rem 2rem 2rem",
  minHeight: "90vh",
  "@media(max-width: 470px)": {
    padding: "0rem 0rem",
  },
});

const TextWrapper = styled.div`
  margin-bottom: 2rem;
  h2 {
    margin-bottom: 2rem;
  }

  @media (max-width: 450px) {
    h2 {
      font-size: 1.5rem;
      margin: 1rem 0;
    }
    h3 {
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }
  }
`;

const CssFormControl = styled(FormControl)({
  "label.Mui-focused": {
    color: "#203e9e",
  },
  width: "100%",
  marginBottom: "1rem",
  ".MuiInputLabel-root": {
    color: "#203e9e",
  },
  ".MuiFilledInput-root": {
    backgroundColor: "#d5d5d5",
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    width: 300,
  },
  ".MuiFilledInput-root.Mui-focused": {
    backgroundColor: "#d5d5d5",
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  ".MuiFilledInput-underline:after": {
    borderBottom: "3px solid #203b9b",
  },
  "&.MuiFormControl-root": {
    display: "flex",
    alignItems: "center",
    width: "300px",
  },
  "@media (max-width: 1370px)": {
    ".MuiFilledInput-root": {
      backgroundColor: "#d5d5d5",
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      width: 270,
    },
    "&.MuiFormControl-root": {
      display: "flex",
      alignItems: "center",
      width: "270px",
    },
  },
  "@media (max-width: 375px)": {
    ".MuiFilledInput-root": {
      backgroundColor: "#d5d5d5",
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      width: 270,
    },
    ".MuiInputLabel-root": {
      marginLeft: "10px",
    },
  },
});

const ConsentWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  p: {
    marginTop: 8,
    fontSize: 15,
  },
});

const CssSelect = styled(Select)({
  ".MuiSelect-select": {
    backgroundColor: "#d5d5d5",
    color: "#202020",
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  ".MuiSelect-filled": {
    backgroundColor: "#d5d5d5",
  },
});
const RequiredCssTextField = styled(TextField)({
  ".MuiFilledInput-root": {
    backgroundColor: "#d5d5d5",
    transitionDuration: "0ms",
    borderTopRightRadius: 5,
  },
  ".MuiFilledInput-root.Mui-focused": {
    backgroundColor: "#d5d5d5",
  },
  ".MuiInputLabel-root": {
    color: "#203b9b",
  },
  "label.Mui-focused": {
    color: "#203b9b",
  },
  ".MuiFilledInput-underline:after": {
    borderBottom: "3px solid #203b9b",
  },
  ".MuiFilledInput-underline:before": {
    borderBottomColor: "#202020",
  },
  ".MuiFilledInput-root:hover": {
    backgroundColor: "#e3e3e3",
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
  },
  "p.MuiFormHelperText-root": {
    background: "transparent",
    fontSize: "15px",
    color: "#e3e3e3",
  },
  width: 300,
  "@media (max-width: 1370px)": {
    width: 270,
  },
  "@media (max-width: 375px)": {
    width: "270px",
  },
});
const CssTextField = styled(TextField)({
  ".MuiFilledInput-root": {
    backgroundColor: "#d5d5d5",
    transitionDuration: "0ms",
    borderTopRightRadius: 5,
  },
  ".MuiFilledInput-root.Mui-focused": {
    backgroundColor: "#d5d5d5",
  },
  ".MuiInputLabel-root": {
    color: "  #404040",
  },
  "label.Mui-focused": {
    color: "#303030",
  },
  ".MuiFilledInput-underline:after": {
    borderBottom: "3px solid #203b9b",
  },
  ".MuiFilledInput-underline:before": {
    borderBottomColor: "#202020",
  },
  ".MuiFilledInput-root:hover": {
    backgroundColor: "#e3e3e3",
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
  },
  "p.MuiFormHelperText-root": {
    background: "transparent",
    fontSize: "15px",
    color: "#e3e3e3",
  },
  width: 300,
  "@media (max-width: 1370px)": {
    width: 270,
  },
  "@media (max-width: 375px)": {
    width: "270px",
  },
});

const RegisterStyle = styled.div`
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${imgSrc});
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  h2 {
    text-align: center;
    margin-bottom: 2rem;
  }
  @media (max-width: 470px) {
    h2 {
      font-size: 2rem;
    }
  }
`;
