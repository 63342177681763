import React, { useState, useEffect } from "react";
import { Nav } from "../components/Nav";
import { styled, Button, TextField } from "@mui/material";
import imgSrc from "../image/bg.jpg";
import RadioGroup from "@mui/material/RadioGroup";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import placeImg from "../image/place.jpg";
import personImg from "../image/person.jpg";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import axios from "axios";
import FileBase64 from "react-file-base64";
import Papa from "papaparse";
import { useNavigate } from "react-router-dom";
import { PersonalCSV } from "../components/PersonalCSV";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { DepartmentCSV } from "../components/DepartmentCSV";

const ConfirmDialog = (props) => {
  const { onClose, dialogValue, open, updateData } = props;

  return (
    <Dialog open={open} fullWidth={true} maxWidth={"xs"}>
      <DialogTitle sx={{ textAlign: "center", fontSize: 24 }}>
        {dialogValue}
      </DialogTitle>
      <DialogContent>
        <DialogContentText></DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          onClick={() => {
            onClose();
            window.location.reload();
          }}
          sx={{ color: "#e3e3e3", fontSize: "1rem" }}
          variant="contained"
          color="b"
        >
          ยืนยัน
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const DeleteByUpload = () => {
  const [isUpload, setUpload] = useState(false);
  const [g, setG] = useState("Male");
  const baseURL = process.env.REACT_APP_URL;
  const [image, setImage] = useState(null);
  const [csv, setCsv] = useState([]);
  const [responseData, setResponseData] = useState([]);
  const [existedCsv, setExistedCsv] = useState([]);
  const [type, setType] = useState("personal");
  const [open, setOpen] = useState(false);
  const [dialogValue, setDialogValue] = useState("");
  let navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));

  const handleClose = () => {
    setOpen(false);
  };

  const handleFileUpload = (e) => {
    const files = e.target.files;
    let jsonData = [];
    if (files) {
      Papa.parse(files[0], {
        header: true,
        skipEmptyLines: true,
        complete: function(results) {
          jsonData = results.data;
          setCsv(jsonData);
        },
      });
    }
  };

  const csvCheck = () => {
    axios
      .post(baseURL + `/${type}/checkdelete`, csv, {
        headers: {
          Authorization: `bearer ${user.token}`,
        },
        //withCredentials: true,
      })
      .then((res) => {
        //console.log(res);
        setResponseData(res.data.message);
        for (let i = 0; i < res.data.ToDelete.length; i++) {
          existedCsv.push(res.data.ToDelete[i]);
          setExistedCsv(existedCsv);
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  const deleteCsv = () => {
    axios
      .post(
        baseURL + `/${type}/deletemany`,
        { ToDelete: existedCsv },
        {
          headers: {
            Authorization: `bearer ${user.token}`,
          },
          //withCredentials: true,
        }
      )
      .then((res) => {
        console.log(res);
        if (res.status == "201") {
          setOpen(true);
          setDialogValue("ลบสำเร็จ");
          setExistedCsv([]);
        } else {
          setOpen(true);
          setDialogValue("ลบไม่สำเร็จ โปรดลองอีกครั้ง");
          setExistedCsv([]);
        }
        //console.log(res)
      });
  };

  useEffect(() => {
    let isLogIn = JSON.parse(localStorage.getItem("user"));
    if (isLogIn == null) {
      navigate(`/`);
    } else if (isLogIn.role !== "admin") {
      navigate("/home");
    }
  }, []);

  return (
    <BackGround>
      <Nav isStaff={true} isLoggedIn={true} />
      <Section>
        <h2>ลบข้อมูลโดยการอัปโหลด</h2>
        <FormControl
          sx={{
            marginTop: 2,
            flexDirection: "row",
            ".MuiFormControlLabel-root": {
              margin: 0,
            },
          }}
        >
          <RadioGroup
            defaultValue="personal"
            row
            sx={{
              "&.MuiFormGroup-row": {
                alignSelf: "center",
                paddingBottom: ".5rem",
              },
            }}
          >
            <FormControlLabel
              onClick={() => setType("personal")}
              sx={{ ".css-iiio54-MuiTypography-root": { fontSize: 24 } }}
              control={<Radio sx={{ ".css-hyxlzm": { color: "#e3e3e3" } }} />}
              value="personal"
              label="บุคลากร"
            />
            <FormControlLabel
              onClick={() => setType("department")}
              sx={{ ".css-iiio54-MuiTypography-root": { fontSize: 24 } }}
              control={<Radio sx={{ ".css-hyxlzm": { color: "#e3e3e3" } }} />}
              value="department"
              label="หน่วยงาน"
            />
          </RadioGroup>
        </FormControl>
        <AddSection>
          <>
            <UploadSection>
              {type == "personal" ? <PersonalCSV /> : <DepartmentCSV />}
              <form>
                <input type="file" accept=".csv" onChange={handleFileUpload} />
                <Button variant="contained" color="w" onClick={csvCheck}>
                  ตรวจสอบ
                </Button>
              </form>
              <p style={{ margin: "1rem" }}>{responseData}</p>
              {responseData.length > 0 ? (
                <Button
                  variant="contained"
                  color="red"
                  onClick={() => deleteCsv()}
                >
                  ยืนยันการลบ
                </Button>
              ) : (
                <></>
              )}
            </UploadSection>
          </>
          <ConfirmDialog
            open={open}
            dialogValue={dialogValue}
            onClose={handleClose}
          />
        </AddSection>
      </Section>
    </BackGround>
  );
};

const Search = styled("div")`
  padding: 2rem 5rem;
  color: var(--white);
  display: flex;
  justify-content: space-between;
  align-items: center;
  h3 {
    width: 40%;
  }
  @media (max-width: 1250px) {
    padding: 2rem 2rem;
  }
  @media (max-width: 800px) {
    flex-direction: column;
    h3 {
      text-align: center;
      width: 100%;
    }
  }
  @media (max-width: 600px) {
    padding: 2rem 1rem;
  }
`;

const UploadSection = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "spaceEvenly",
  alignItems: "center",
  a: {
    color: "var(--white)",
  },
  form: {
    marginTop: "2rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    rowGap: "2rem",
    Button: {
      width: "10rem",
      fontSize: "1rem",
    },
  },
});

const AddSection = styled("div")({
  width: "100%",
  ".desc": {
    alignSelf: "end",
  },
});

const Section = styled("div")({
  display: "flex",
  padding: "2rem 3rem",
  justifyContent: "space-evenly",
  alignItems: "center",
  flexDirection: "column",
  height: "50vh",

  "@media (max-width: 600px)": {
    padding: "2rem 1rem",
    h1: {
      fontSize: 32,
    },
    h3: {
      fontSize: 24,
    },
  },
});

const BackGround = styled("div")`
  min-height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${imgSrc});
  background-repeat: no-repeat;
  background-size: cover;
  color: var(--white);
`;
