import React from "react";
import { CSVLink } from "react-csv";

export const DepartmentCSV = () => {
  const headers = [
    { label: "name_th", key: "name_th" },
    { label: "minister", key: "minister" },
    { label: "primary_telephone", key: "primary_telephone" },
    { label: "secondary_telephone", key: "secondary_telephone" },
    { label: "url", key: "url" },
    { label: "primary_email", key: "primary_email" },
    { label: "secondary_email", key: "secondary_email" },
    { label: "full_description", key: "full_description" },
    { label: "address", key: "address" },
    { label: "subdistrict", key: "subdistrict" },
    { label: "district", key: "district" },
    { label: "province", key: "province" },
    { label: "zipcode", key: "zipcode" },
    { label: "internal_contact_number", key: "internal_contact_number" },
    { label: "fax", key: "fax" },
    { label: "open_time", key: "open_time" },
  ];

  return (
    <CSVLink
      headers={headers}
      data={[]}
      filename={"departmentForm"}
      target="_blank"
    >
      ดาวน์โหลดแบบฟอร์ม
    </CSVLink>
  );
};
