import React, { useState, useEffect } from "react";
import styled from "styled-components";
import imgSrc from "../image/bg.jpg";
import { Button, TextField } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

const ConfirmDialog = (props) => {
  const { onClose, dialogValue, dialogText, open, updateData } = props;
  const navigate = useNavigate();

  return (
    <Dialog open={open} fullWidth={true} maxWidth={"xs"}>
      <DialogTitle sx={{ textAlign: "center", fontSize: 24 }}>
        {dialogValue}
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ textAlign: "center", fontSize: 20 }}>
          {dialogText}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          onClick={() => {
            navigate("/");
          }}
          sx={{ color: "#e3e3e3", fontSize: "1rem", width: "70%" }}
          variant="contained"
          color="b"
        >
          เข้าสู่ระบบ
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const ChangePassword = () => {
  let navigate = useNavigate();
  const baseURL = process.env.REACT_APP_URL;
  const [error, setError] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  let { userId } = useParams();
  const [pwd, setPwd] = useState("");
  const [cfpwd, setCfpwd] = useState("");
  const [user, setUser] = useState("");
  const [open, setOpen] = useState(false);
  const [dialogValue, setDialogValue] = useState("");
  const [dialogText, setDialogText] = useState("");
  const [request, setRequest] = useState(false);
  const userToken = JSON.parse(localStorage.getItem("user"));

  const handleClose = () => {
    setOpen(false);
  };

  const handlePwd = (e) => {
    setPwd(e.target.value);
  };

  const handleCfpwd = (e) => {
    setCfpwd(e.target.value);
  };

  const changePassword = (e) => {
    e.preventDefault();
    setErrMessage(false);
    const uppercaseRegExp = /(?=.*?[A-Z])/;
    const lowercaseRegExp = /(?=.*?[a-z])/;
    const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
    const minLengthRegExp = /.{8,}/;

    const uppercasePassword = uppercaseRegExp.test(pwd);
    const lowercasePassword = lowercaseRegExp.test(pwd);
    const specialChar = specialCharRegExp.test(pwd);
    const minLengthPassword = minLengthRegExp.test(pwd);

    if (!uppercasePassword) {
      setErrMessage("รหัสผ่านต้องมีตัวหนังสือพิมพ์ใหญ่อย่างน้อย 1 ตัว");
      setError(true);
      return;
    } else if (!lowercasePassword) {
      setErrMessage("รหัสผ่านต้องมีตัวหนังสือพิมพ์เล็กอย่างน้อย 1 ตัว");
      setError(true);
      return;
    } else if (!specialChar) {
      setErrMessage("รหัสผ่านต้องมีตัวอักษรพิเศษอย่างน้อย 1 ตัว");
      setError(true);
      return;
    } else if (!minLengthPassword) {
      setErrMessage("รหัสผ่านต้องมีความยาวอย่างน้อย 8 ตัวอักษร");
      setError(true);
      return;
    } else if (pwd != cfpwd) {
      setErrMessage("รหัสผ่านไม่ตรงกัน");
      setError(true);
      return;
    }
    axios
      .patch(
        baseURL + "/user/update",
        { user_id: user.user_id, password: pwd },
        {
          headers: {
            Authorization: `bearer ${userToken.token}`,
          },
          //withCredentials: true,
        }
      )
      .then((res) => {
        if (res.data.message == "successfully updated") {
          setOpen(true);
          setDialogValue("แก้ไขสำเร็จ");
          setDialogText("กรุณาเข้าสู่ระบบใหม่");
        } else {
          setOpen(true);
          setDialogValue("แก้ไขไม่สำเร็จ โปรดลองอีกครั้ง");
        }
      });
  };

  useEffect(() => {
    if (atob(localStorage.getItem("APOD")) !== userId) {
      setRequest(true);
    } else {
      setRequest(false);
      axios
        .get(baseURL + "/personal/" + userId, {
          headers: {
            Authorization: `bearer ${userToken.token}`,
          },
        })
        .then((res) => {
          console.log(res);
          setUser(res.data);
        });
    }
  }, []);

  if (request === true) {
    return (
      <LoginStyle>
        <h2 className="permission">คุณไม่มีสิทธิ์ในการเข้าถึงหน้านี้</h2>
      </LoginStyle>
    );
  } else {
    return (
      <LoginStyle>
        <LogoText>
          <Dopa>DOPA</Dopa>
          <br />
          DIRECTORY
        </LogoText>
        <form onSubmit={changePassword}>
          <FormWrapperStyle>
            <TextStyle>
              <h2>เปลี่ยนรหัสผ่าน</h2>
            </TextStyle>

            <CssTextField
              label="เบอร์โทรศัพท์"
              variant="filled"
              color="w"
              type="text"
              value={user && user.primary_telephone}
            />
            <CssTextField
              label="รหัสผ่านใหม่"
              variant="filled"
              color="w"
              type="password"
              onChange={handlePwd}
              error={error}
              helperText={errMessage}
            />
            <CssTextField
              label="ยืนยันรหัสผ่านใหม่"
              variant="filled"
              color="w"
              type="password"
              error={error}
              helperText={errMessage}
              onChange={handleCfpwd}
            />
            <p style={{ fontSize: 14, fontWeight: 400 }}>
              รหัสผ่านต้องมีตัวอักษรภาษอังกฤษตัวพิมพ์เล็กอย่างน้อย 1 ตัว,
              ตัวพิมพ์ใหญ่อย่างน้อย 1 ตัว, ตัวอักษรพิเศษอย่างน้อย 1 ตัว
              (#?!@$%^&*-) และมีความยาวรวมกันไม่ต่ำกว่า 8 ตัวอักษร
            </p>
            <Button type="submit" color="b" variant="contained">
              เปลี่ยนรหัสผ่าน
            </Button>
            <ConfirmDialog
              open={open}
              dialogValue={dialogValue}
              onClose={handleClose}
              dialogText={dialogText}
            />
          </FormWrapperStyle>
        </form>
      </LoginStyle>
    );
  }
};

const CssTextField = styled(TextField)({
  ".MuiFilledInput-root": {
    backgroundColor: "#e0e0e0",
    transitionDuration: "0ms",
    borderTopRightRadius: 5,
  },
  ".MuiFilledInput-root.Mui-focused": {
    backgroundColor: "#e0e0e0",
  },
  ".MuiInputLabel-root": {
    color: "#203b9b",
  },
  "label.Mui-focused": {
    color: "#203b9b",
  },
  ".MuiFilledInput-underline:after": {
    borderBottom: "3px solid #203b9b",
  },
  ".MuiFilledInput-underline:before": {
    borderBottomColor: "#202020",
  },
  ".MuiFilledInput-root:hover": {
    backgroundColor: "#e3e3e3",
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
  },
  "p.MuiFormHelperText-root": {
    background: "transparent",
    fontSize: "15px",
    color: "#e3e3e3",
  },
});

const LinkText = styled.p`
  &:hover {
    text-decoration: underline;
  }
  @media (max-width: 1350px) {
    & {
      font-size: 1rem;
    }
  }
`;

const TextStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 2rem;
  a {
    text-align: right;
    text-decoration: none;
    color: #e3e3e3;
  }
`;
const FormWrapperStyle = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0rem 5rem;
  row-gap: 1.5rem;
  .forgot {
    color: var(--white);
    text-decoration: none;
    width: 80px;
    align-self: flex-end;
    cursor: pointer;
  }
  @media (max-width: 1250px) {
    h2 {
      font-size: 1.5rem;
    }
    padding: 0rem 2rem;
  }
  @media (max-width: 850px) {
    align-self: start;
    padding: 0rem 5rem;
  }
  @media (max-width: 500px) {
    padding: 0rem 0rem;
  }
`;

const LoginStyle = styled.div`
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${imgSrc});
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0 5rem;
  color: white;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  h2.permission {
    text-align: center;
    grid-column: 1/3;
  }
  @media (max-width: 1250px) {
    padding: 0 3rem;
  }
  @media (max-width: 950px) {
    padding: 0 1rem;
  }
  @media (max-width: 850px) {
    grid-template-columns: 1fr;
  }
`;

const LogoText = styled.h1`
  font-size: 5rem;
  font-family: "Cormorant Garamond", sans-serif;
  font-weight: 500;
  text-shadow: 0px 0px 10px black;
  text-align: center;
  letter-spacing: 1rem;
  @media (max-width: 1250px) {
    font-size: 2.5rem;
  }
  @media (max-width: 400px) {
    font-size: 1.5rem;
  }
`;

const Dopa = styled.span`
  font-size: 10rem;
  font-family: "Cormorant Garamond", sans-serif;
  font-weight: 500;
  text-shadow: 0px 0px 10px black;
  @media (max-width: 1250px) {
    font-size: 5rem;
  }
  @media (max-width: 400px) {
    font-size: 3rem;
  }
`;
