import React, { useEffect, useState } from "react";
import { styled } from "@mui/material";
import { Nav } from "../components/Nav";
import imgSrc from "../image/bg.jpg";
import placeImg from "../image/place.jpg";
import { SearchBar } from "../components/SearchBar";
import { examplePlaceData } from "../Db";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import axios from "axios";
import image from "../image/simulated place.jpg";
import personImage from "../image/no-image.jpg";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";

export const Search = () => {
  const [data, setData] = useState([]);
  let navigate = useNavigate();
  const location = useLocation();
  let { typeParam, valueParam } = useParams();
  const [type, setType] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const baseURL = process.env.REACT_APP_URL;
  const [isLoading, setIsLoading] = useState(false);
  const [displayStyle, setDisplayStyle] = useState("table");
  const user = JSON.parse(localStorage.getItem("user"));

  const childToParent = async (childdata) => {
    setData(childdata);
  };

  useEffect(() => {
    let isLogIn = JSON.parse(localStorage.getItem("user"));
    if (isLogIn == null) {
      navigate(`/`);
    }
    if (location.state !== null) {
      setData(location.state.data);
      setType(location.state.type);
      delete location.state.data;
      delete location.state.type;
    } else {
      return;
    }
  }, []);

  const truncateText = (text, length) => {
    if (text.length > length) {
      text = text.substr(0, length) + "...";
    }
    return text;
  };

  const onEnter = () => {
    console.log("enter");
  };

  return (
    <>
      <BackGround>
        <Nav isLoggedIn={true} isStaff={false} typeParam={"department"} />
        <SearchBar
          name="หน่วยงานและบุคลากรทั้งหมด"
          childToParent={childToParent}
          setType={setType}
          setSearchValue={setSearchValue}
        />
        <DisplayStyle>
          <p className="result">ผลการค้นหา ({data.length.toLocaleString()})</p>
          <RadioGroup
            value={displayStyle}
            name="displayStyle"
            onChange={(e) => {
              setDisplayStyle(e.target.value);
            }}
            row
            sx={{
              flexWrap: "wrap",
              "&.MuiFormGroup-row": {
                alignSelf: "center",
                paddingBottom: ".5rem",
              },
              "@media (max-width: 1250px)": {
                justifyContent: "center",
              },
            }}
          >
            <FormControlLabel
              control={<Radio sx={{ ".css-hyxlzm": { color: "#e3e3e3" } }} />}
              value="table"
              label="ตาราง"
            />
            <FormControlLabel
              control={<Radio sx={{ ".css-hyxlzm": { color: "#e3e3e3" } }} />}
              value="card"
              size="large"
              label="การ์ด"
            />
          </RadioGroup>
        </DisplayStyle>
        {/* {data.length == 0 && isLoading == false ? (
            <>
            <h2
            style={{
              color: "#e3e3e3",
              textAlign: "center",
              gridColumn: "1 / 5",
            }}
            >
            ไม่พบข้อมูล
            </h2>
            </>
          ) : null} */}
        {displayStyle == "card" ? (
          <Section>
            {data.map((place, index) => (
              <Card
                key={index}
                onClick={() => {
                  window.open(`/${type}/detail/${place._id}`);
                }}
              >
                {place.primary_picture || place.profile_picture ? (
                  <img
                    src={place.primary_picture || place.profile_picture}
                    alt=""
                  />
                ) : type === "department" ? (
                  <img src={image} alt="" />
                ) : (
                  <img className="person-image" src={personImage} alt="" />
                )}
                {type === "department" ? (
                  <>
                    <p className="name">{truncateText(place.name_th, 30)}</p>
                    <p>เบอร์โทรศัพท์ : {place.primary_telephone}</p>
                    <p>อีเมล์ : {place.primary_email}</p>
                  </>
                ) : (
                  <>
                    <p className="name">
                      {place.first_name_th}{" "}
                      {place.last_name_th
                        ? truncateText(place.last_name_th, 20)
                        : null}
                    </p>
                    <p>เบอร์โทรศัพท์ : {place.primary_telephone}</p>
                    <p>อีเมล์ : {place.primary_email}</p>
                  </>
                )}
              </Card>
            ))}
          </Section>
        ) : null}
        {displayStyle == "table" ? (
          <Table>
            <Header>
              <div className="count">
                <p>ลำดับ</p>
              </div>
              <div>
                <p>{type == "department" ? "ชื่อหน่วยงาน" : "ชื่อ นามสกุล"}</p>
              </div>
              <div className="tel">
                <p>เบอร์โทรศัพท์</p>
              </div>
              <div className="col3">
                <p>{type == "department" ? "อีเมล" : "ตำแหน่ง/ระดับ"}</p>
              </div>
              <div className="col4">
                <p>{type == "department" ? "จังหวัด" : "สำนัก/กอง/จังหวัด"}</p>
              </div>
            </Header>
            {data.map((place, index) => (
              <Result
                onClick={() => {
                  window.open(`/${type}/detail/${place._id}`);
                }}
              >
                <div className="count">
                  <p>{index + 1}</p>
                </div>
                <div>
                  <p>
                    {type == "department"
                      ? truncateText(place.name_th, 40)
                      : truncateText(
                          place.first_name_th + " " + place.last_name_th,
                          40
                        )}
                  </p>
                </div>
                <div className="tel">
                  <p>{place.primary_telephone}</p>
                </div>
                <div className="col3">
                  <p>
                    {type == "department"
                      ? place.primary_email
                      : place.position && place.level
                      ? place.position + "/" + place.level
                      : null}
                  </p>
                </div>
                <div className="col4">
                  <p>
                    {type == "department" ? place.province : place.department}
                  </p>
                </div>
              </Result>
            ))}
          </Table>
        ) : null}
      </BackGround>
    </>
  );
};

const DisplayStyle = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #e3e3e3;
  column-gap: 1rem;
  .MuiFormGroup-root {
    padding: 0;
  }
`;

const Table = styled("div")`
  padding: 1rem 2rem;
  @media (max-width: 950px) {
    padding: 1rem;
  }
  @media (max-width: 550px) {
    p {
      font-size: 16px;
    }
  }
  @media (max-width: 405px) {
    padding: 1rem 0.5rem;
    p {
      font-size: 14px;
    }
  }
`;

const Header = styled("div")`
  display: flex;
  justify-content: space-between;
  width: 100%;
  div {
    background: #202020;
    flex: 1.5;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 1px solid #e3e3e3;
    padding: 4px;

    p {
      color: #e3e3e3;
    }
  }
  .tel {
    flex: 0.7;
    padding: 4px;
  }
  .count {
    flex: 0.3;
    min-width: 55px;
  }
  .col3,
  .col4 {
    flex: 1;
  }
  @media (max-width: 950px) {
    .col3,
    .col4 {
      display: none;
    }
  }
  @media (max-width: 550px) {
    .count {
      min-width: 45px;
    }
    .tel {
      flex: 0.9;
    }
  }
`;
const Result = styled("div")`
  display: flex;
  justify-content: space-between;
  width: 100%;
  div {
    flex: 1.5;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    overflow: hidden;
    border: 1px solid #e3e3e3;
    background: #20202090;
    cursor: pointer;
    padding: 4px;
    p {
      color: #e3e3e3;
    }
  }
  .tel {
    flex: 0.7;
    padding: 4px;
  }
  .count {
    flex: 0.3;
    min-width: 55px;
  }
  .col3,
  .col4 {
    flex: 1;
  }
  &:hover {
    div {
      background: #202020;
    }
    p {
      color: #e3e3e3;
    }
  }
  @media (max-width: 950px) {
    .col3,
    .col4 {
      display: none;
    }
  }
  @media (max-width: 550px) {
    .count {
      min-width: 45px;
    }
    .tel {
      flex: 0.9;
    }
  }
`;

const Section = styled("div")`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 3rem;
  justify-items: center;
  row-gap: 3rem;
  @media (max-width: 1600px) {
    grid-template-columns: repeat(3, 1fr);
    padding: 3rem 1rem;
  }
  @media (max-width: 1150px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 800px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media (max-width: 380px) {
    grid-template-columns: repeat(1, 1fr);
    padding: 1rem 0rem;
  }
`;
const Card = styled("div")`
  min-height: 300px;
  width: 350px;
  border: 1px solid var(--white);
  border-radius: 5px;
  background-color: rgba(20, 20, 20, 0.8);
  color: var(--white);
  text-align: center;
  cursor: pointer;
  img {
    object-fit: fill;
    width: 100%;
    height: 350px;
  }
  p {
    font-size: 1rem;
  }
  p,
  h3 {
    margin-bottom: 1rem;
    padding: 0 0.5rem;
  }
  &:hover {
    background-color: var(--white);
    color: var(--black);
  }
  @media (max-width: 600px) {
    width: 335px;
    img {
      height: 335px;
    }
  }
  @media (max-width: 380px) {
    width: 280px;
    img {
      height: 280px;
    }
  }
`;

const BackGround = styled("div")`
  min-height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${imgSrc});
  background-repeat: no-repeat;
  background-size: cover;
  .result {
    color: var(--white);
    padding-left: 5rem;
  }
  @media (max-width: 1250px) {
    .result {
      padding-left: 2rem;
    }
  }
  @media (max-width: 800px) {
    .result {
      text-align: center;
    }
  }
`;
