import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Mitr', sans-serif ;
        --white: #e3e3e3;
        --black: #202020;
    }
    h1{
    font-size: 3rem;
        }
    h2, li{
    font-size: 1.5rem;
    }
    h3{
        font-size: 2rem;
    }
    p,a{  
    font-size: 18px;
    }
`;

export default GlobalStyle;
